<template>

  <div v-for="product in products_data" :key="product.idx" class="flex-row flex-wrap" >
      <prd_item v-if="product.order == 'prd'"
                :img="product.it_img"
                :title="product.it_name"
                :desc="product.it_desc"
                :nor_price="product.it_n_price"
                :price="product.it_price"
                :idx="product.idx"
                :img2="product.it_img_2"
                :title2="product.it_name_2"
                :desc2="product.it_desc_2"
                :nor_price2="product.it_n_price_2"
                :price2="product.it_price_2"
                :idx2="product.idx_2"
                @addToCart="addCart_lf"

      />
      <highlight_item v-if="product.order == 'hilight'"
                      :img="product.it_img"
                      :title="product.it_name"
                      :desc="product.it_desc"
                      :nor_price="product.it_n_price"
                      :price="product.it_price"
                      :idx="product.idx"
                      @addToCart="addCart_lf"
      />
      <text_item v-if="product.order == 'text2'" :text="product.it_name"/>
      <youtube_item v-if="product.order == 'youtube'" :url="product.it_name"/>
      <img_item v-if="product.order == 'img'" :src="product.it_img"/>

    </div>
  <div v-if="isLoading" class="loading-div">
    <img class="loading_gif" src="@/assets/img/loading.gif" alt="로딩 중..." />
  </div>
  <infi_scrolls @show="loadMoreItems"/>
  <div style="height: 100px"></div>
  <div class="modal-overlay" v-if="isModalVisible">
    <div class="modal-content">
      <div class="modal-body"><div v-html="pophtml"></div></div>
      <div class="flex-row flex-wrap">
        <button class="col-6" style="padding-right:5px;" @click="closeModal"><div class="close-btn">설정하기</div></button>
        <button class="col-6" style="padding-left:5px;" @click="confirmAction"><div class="confirm-btn">닫기</div></button>
      </div>
    </div>
  </div>
</template>

<script>
import highlight_item from '@/components/item_ui/highlight_item.vue';
import prd_item from '@/components/item_ui/prd_item.vue';
import youtube_item from '@/components/item_ui/youtube_item.vue';
import img_item from '@/components/item_ui/img_item.vue';
import text_item from "@/components/item_ui/text_item";
import infi_scrolls from "@/components/infi_scrolls";

export default {
  name: 'Home_leaflet',
  components:{
    text_item,
    highlight_item,
    prd_item,
    youtube_item,
    img_item,
    infi_scrolls,
  },
  data() {
    return {
      products_data: [],
      isLoading:true,
      item_start: 0,
      item_end: 1,
      isModalVisible: false,
      pophtml:"",
      maid:"",
    }
  },
  created() {
    this.maid = this.$route.params.martid;
    console.log(this.maid);
    if (this.maid) {
      localStorage.setItem('martid', this.maid);
      this.$store.state.martid = this.maid;
    }
    this.$store.dispatch('Showheaderfooter', {
      Title: '오늘 행사',
      showBack: false,
      showAlarm: true,
      showcoupon: true,
      showCart: true,
      showsheader: true,
      showFooter: true,
      enableScrollHeader: true,
    });
  },
  mounted() {

    if (this.products_data.length === 0) {
      this.loadMoreItems();
    }
  },
  methods: {
    closeModal() {
      this.isModalVisible = false;
    },
    confirmAction() {
      // 수행할 액션을 정의합니다.
      this.isModalVisible = false;
    },
    loadMoreItems() {
      if (this.item_end <= this.item_start) {
        return;
      }
      this.isLoading = true;
      // 예를 들어 API 요청 등으로 데이터를 가져오는 비동기 작업을 수행
      let formData = new FormData();
      formData.append('mode', 'app_leaf_array');
      formData.append('martid', this.$store.state.martid);
      formData.append('token', this.$store.state.token);
      formData.append('lf', this.$store.state.lf);
      formData.append('item_start', this.item_start);
      this.$axios.post(this.$store.state.apiEndpoint, formData)
          .then(response => {
            if (this.item_start == 0){
              this.pophtml = response.data.pophtml;
              if (this.pophtml != ""){
                this.isModalVisible = true;
              }
            }
            this.products_data.push(...response.data.data);
            this.item_start = response.data.item_start;
            this.item_end = response.data.item_end;
            this.$store.state.lf = response.data.lf;
            this.$store.state.mart_name = response.data.mart_name;

          })
          .catch(error => {
            this.$modal.errorToast(error);
          }).finally(() => {
        this.isLoading = false; // 요청이 끝나면 로딩 상태를 false로 설정
      });
    },
    addCart_lf(idx,ct_out_price,ct_price,it_img1,it_name) {
      this.$addcart(idx,"leaf",ct_out_price,ct_price,it_img1,it_name);
    },
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  z-index: 30000;
  margin: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.modal-header {
  margin-bottom: 16px;
  font-weight: bold;
}

.modal-body {
  margin-bottom: 20px;
}




.close-btn {
  background-color: #EEEEEE;
  color: var(--prime-font-active);
  padding-top: 14px;
  padding-bottom: 14px;
  width: 100%;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Noto Sans CJK KR';
}

.confirm-btn {
  background-color: #FBE9DF;
  padding-top: 14px;
  padding-bottom: 14px;
  color: #E6592F;
  width: 100%;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Noto Sans CJK KR';
}

.close-btn:hover {
  background-color: #e8e8e8;
}

.confirm-btn:hover {
  background-color: #ff5c5c;
}
</style>
