<template>
  <svg width="16px" height="16px" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g id="SVGRepo_iconCarrier" transform="translate(-3 -3)">
    <path d="M12 4V20M18 6L6 18M20 12H4M18 18L6 6" stroke="#F45957" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
    </g>
  </svg>
</template>

<script>

</script>

<style scoped>

</style>