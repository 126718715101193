<template>
  <div v-if="isLoading" class="loading-div">
    <img class="loading_gif" src="@/assets/img/loading.gif" alt="로딩 중..." />
  </div>
  <div v-else>

    <div style="padding:10px 20px 20px 20px;">
      <ui_inputtext placeholder="검색어를 입력해주세요." :icon_1=true type="search" v-model="search_text" @searchkey="searchkey" @keyup.enter="searchkey"/>
    </div>
    <div class="flex-row flex-wrap" style="padding: 0px 20px 20px 20px">
      <div class="col-6 grow-left active-font-18-b">
        인기 검색어
      </div>
      <div class="col-6 grow-right inactive-font-14">
        {{times}} 업데이트
      </div>


      <div class="col-6 grow-left active-font-18 flex-row flex-wrap" style="padding-top: 10px" v-for="product in products" :key="product.idx">
        <div class="col-8" @click="search_result(product.name,'add')"><span class="active-font-14-b" :style="{ color: product.idx < 4 ? 'var(--second-color)' : '' }" style="padding-right: 10px" >{{ product.idx }}</span>{{product.name}}</div>
        <div class="active-font-14-b col-4 grow-right" style="padding-right: 20px">-</div>
      </div>
    </div>

    <div style="width: 100%;height: 10px;background-color: #F6F6F6;" class="v-margin">
    </div>

    <div class="flex-row flex-wrap" style="padding: 0px 20px 20px 20px">
      <div class="col-6 grow-left active-font-18-b">
        최근 검색어
      </div>
      <div class="col-6 grow-right active-font-14" @click="clearSearch">
        전체삭제
      </div>

      <div class="col-12 flex-row flex-wrap" style="padding-top: 20px" v-for="(searchItem,index) in local_search_key" :key="index">
        <div class="col-8 inactive-font-16" style="display: flex;align-items: center;" @click="search_result(searchItem.keyword)"><img src="@/assets/img/svg/clack.svg" style="padding-right: 10px"  />{{ searchItem.keyword }}</div>
        <div class="inactive-font-14 col-4 grow-right"> {{ new Date(searchItem.date).getMonth() + 1 }}. {{ new Date(searchItem.date).getDate() }}<img src="@/assets/img/svg/x.svg" style="padding-left: 10px" @click="deleteSearch(index)" /></div>
      </div>


    </div>

  </div>
</template>

<script>




export default {
  created() {
    this.fetchProducts();
    this.$store.dispatch('Showheaderfooter', {
      Title: '검색',
      showBack: false,
      showAlarm: false,
      showCart: false,
      showsheader: true,
      showFooter: true,
      enableScrollHeader: false,
    });
  },
  mounted() {
    this.loadSearchData();
  },
  data() {
    return {
      products: [],
      local_search_key: [],
      isLoading:true,
      search_text:"",
      times:"",
    }
  },
  methods: {
    search_result(keyword,add='none'){
      if (add == "add") {
        let searchData = localStorage.getItem('SearchData');


        // 데이터가 없거나 타입이 배열이 아닌 경우 빈 배열로 초기화
        if (!searchData || !Array.isArray(JSON.parse(searchData))) {
          searchData = [];
        } else {
          searchData = JSON.parse(searchData);
        }


        const currentDate = new Date().toISOString().substr(0, 10); // 날짜를 포함한 ISO 형식

        // 새로운 검색어 데이터 추가
        searchData.unshift({
          date: currentDate, // 날짜 정보 추가
          keyword: keyword, // 검색어
        });

        // 로컬 스토리지에 업데이트된 데이터 저장
        localStorage.setItem('SearchData', JSON.stringify(searchData));
      }
      this.$router.push({ name: 'search_result', params: { keyword: keyword }});
    },
    searchkey() {
      if (this.search_text == ""){
        return;
      }
      // 로컬 스토리지에서 이전 데이터 가져오기
      let searchData = localStorage.getItem('SearchData');


      // 데이터가 없거나 타입이 배열이 아닌 경우 빈 배열로 초기화
      if (!searchData || !Array.isArray(JSON.parse(searchData))) {
        searchData = [];
      } else {
        searchData = JSON.parse(searchData);
      }


      const currentDate = new Date().toISOString().substr(0, 10); // 날짜를 포함한 ISO 형식

      // 새로운 검색어 데이터 추가
      searchData.unshift({
        date: currentDate, // 날짜 정보 추가
        keyword: this.search_text, // 검색어
      });

      // 로컬 스토리지에 업데이트된 데이터 저장
      localStorage.setItem('SearchData', JSON.stringify(searchData));

      this.local_search_key = searchData;
      let papa = this.search_text;
      this.search_text = "";
      this.search_result(papa);
    },
    loadSearchData() {
      // 로컬 스토리지에서 이전 데이터 가져오기
      let searchData = localStorage.getItem('SearchData');

      // 데이터가 없거나 타입이 배열이 아닌 경우 빈 배열로 초기화
      if (!searchData || !Array.isArray(JSON.parse(searchData))) {
        searchData = [];
      } else {
        searchData = JSON.parse(searchData);
      }
      // 로컬 스토리지에서 가져온 데이터를 할당
      this.local_search_key = searchData.slice(0, 8);
    },

    clearSearch() {
      this.local_search_key = [];
      localStorage.setItem('SearchData', JSON.stringify([]));
    },
    deleteSearch(idx) {
      if (this.local_search_key.length >= idx) {
        this.local_search_key.splice(idx, 1); // 3번째 항목(인덱스 2)을 제거
      }
      localStorage.setItem('SearchData', JSON.stringify(this.local_search_key));
    },
    fetchProducts() {
      let formData = new FormData();
      formData.append('mode', 'get_search_keyword');
      this.$axios.post(this.$store.state.apiEndpoint, formData)
          .then(response => {
            this.products = response.data.data;
            this.times = response.data.time;
          })
          .catch(error => {
            this.$modal.errorToast(error);
          }).finally(() => {
        this.isLoading = false; // 요청이 끝나면 로딩 상태를 false로 설정
      });
    }
  }
}
</script>

<style scoped>

</style>
