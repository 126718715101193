<template>
  <svg v-if="!isActive" width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_iconCarrier">
      <g id="style=linear">
        <g id="check-box">
          <path id="vector" d="M8 12.1667L9.95956 14.1262C10.3501 14.5167 10.9832 14.5168 11.3738 14.1262L16 9.5" stroke="#000000" stroke-width="1.5" stroke-linecap="round"></path>
          <path id="vector_2" d="M2 8C2 4.68629 4.68629 2 8 2H16C19.3137 2 22 4.68629 22 8V16C22 19.3137 19.3137 22 16 22H8C4.68629 22 2 19.3137 2 16V8Z" stroke="#000000" stroke-width="1.5"></path>
        </g>
      </g>
    </g>
  </svg>

  <svg v-else width="24px" height="24px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000" stroke-width="0.00024000000000000003">
    <g id="SVGRepo_iconCarrier">
      <g id="style=fill">
        <g id="check-box">
          <path id="Subtract" fill-rule="evenodd" clip-rule="evenodd" d="M8 1.25C4.27208 1.25 1.25 4.27208 1.25 8L1.25 16C1.25 19.7279 4.27208 22.75 8 22.75L16 22.75C19.7279 22.75 22.75 19.7279 22.75 16L22.75 8C22.75 4.27208 19.7279 1.25 16 1.25L8 1.25ZM16.5303 10.0303C16.8232 9.73742 16.8232 9.26255 16.5303 8.96966C16.2374 8.67676 15.7626 8.67676 15.4697 8.96966L10.8434 13.5959C10.7458 13.6935 10.5875 13.6935 10.4899 13.5959L8.53033 11.6363C8.23744 11.3434 7.76256 11.3434 7.46967 11.6363C7.17678 11.9292 7.17678 12.4041 7.46967 12.697L9.42923 14.6565C10.1126 15.34 11.2207 15.34 11.9041 14.6565L16.5303 10.0303Z" fill="#2196F3">
          </path>
        </g>
      </g>
    </g>
  </svg>



</template>

<script>
export default {
  props: ['isActive'],
  computed: {
    currentColor() {
      return this.isActive ? 'var(--prime-color)' : 'var(--inactive-color)';
    },
  }
};
</script>
