<template>
  <div v-if="isLoading" class="loading-div">
    <img class="loading_gif" src="@/assets/img/loading.gif" alt="로딩 중..." />
  </div>
  <div v-else>


    <div style="width: 100%;height: 10px;background-color: #fff" class="v-margin">
    </div>
    <div v-for="(product, index) in products" :key="product.idx" class="flex-col" style="padding-top: 10px;">
      <div class="flex-row" @click="select_adr(product.idx,product.name,product.tel,product.addr1,product.addr2,product.memo)">
        <div class="col-2" style="padding: 16px 0px 16px 20px"><ui_adr_home v-if="product.subject =='기본배송지'"/><ui_adr_etc v-else/></div>
        <div class="col-10 flex-col grow-left">
          <div class="flex-row" style="width: 100%;padding-bottom: 5px">
              <div class="col-6 active-font-18-b grow-left">{{product.subject}}</div>
            <div v-if="product.default == 1" class="col-6 grow-right" style="padding-right: 20px"><span class="band-now active-font-12">현재 설정 주소</span></div>
          </div>
          <div class="grow-left" style="width: 100%"><span class="inactive-font-14" style="padding-right: 20px;">{{product.addr1}} {{product.addr2}}</span></div>
        </div>
      </div>
      <div style="padding:5px 20px 5px 20px">
        <div v-if="products.length-1 > index" style="width: 100%;height: 1px;background-color: #F6F6F6;" class="v-margin"></div>
      </div>
    </div>

    <div style="width: 100%;height: 10px;background-color: #F6F6F6" class="v-margin">
    </div>
    <div v-for="(product, index) in products_last" :key="product.idx" class="flex-col" style="padding-top: 10px;">
    <div class="flex-row" @click="select_adr(product.idx,product.name,product.tel,product.addr1,product.addr2,product.memo)">
      <div class="col-2" style="padding: 16px 0px 16px 20px"><img src="@/assets/img/svg/adr_last.svg" /></div>
      <div class="col-10 flex-col grow-left">
        <div class="flex-row" style="width: 100%;padding-bottom: 5px">
          <div class="col-6 active-font-18-b">최근 배송 주소</div>
          <div v-if="product.default == 1" class="col-6 grow-right" style="padding-right: 20px"><span class="band-now active-font-12">현재 설정 주소</span></div>
        </div>
        <div style="width: 100%;" ><span class="inactive-font-14" style="padding-right: 20px;">{{product.addr1}} {{product.addr2}}</span></div>
      </div>
    </div>
    <div style="padding:5px 20px 5px 20px">
      <div v-if="products.length-1 > index" style="width: 100%;height: 1px;background-color: #F6F6F6;" class="v-margin"></div>
    </div>
  </div>

  </div>
</template>

<script>


export default {
  data() {
    return {
      products: [],
      products_last: [],
      isLoading:true,
    }
  },
  created() {
    this.fetchProducts();
    this.$store.dispatch('Showheaderfooter', {
      Title: '내 배송 주소',
      showBack: true,
      showAlarm: false,
      showCart: false,
      showedit: true,
      showsheader: true,
      showFooter: false,
    });
  },
  methods: {
    select_adr(idx,name,tel,addr1,addr2,memo){
      this.$store.state.cart_addr_idx = idx;


      const orderData = localStorage.getItem('order_b_data');
      if (orderData) {
        const parsedData = JSON.parse(orderData);
        parsedData.bname = name;
        parsedData.btel = tel;
        parsedData.baddr1 = addr1;
        parsedData.baddr2 = addr2;
        parsedData.bmemo = memo;

        localStorage.setItem('order_b_data', JSON.stringify(parsedData));
      } else {
        const parsedData = {
          bname: name,
          btel: tel,
          baddr1: addr1,
          baddr2: addr2,
          bmemo: memo
        };

        localStorage.setItem('order_b_data', JSON.stringify(parsedData));
      }
      this.$router.replace({
        name: 'Order_Page'
      });
    },
    fetchProducts() {
      let formData = new FormData();
      formData.append('mode', 'get_useradr_list');
      formData.append('martid', this.$store.state.martid);
      formData.append('token', this.$store.state.token);
      this.$axios.post(this.$store.state.apiEndpoint, formData)
          .then(response => {
            this.products = response.data.data;
            this.products_last = response.data.last;
          })
          .catch(error => {
            this.$modal.errorToast(error);
          }).finally(() => {
        this.isLoading = false; // 요청이 끝나면 로딩 상태를 false로 설정
      });
    },
  }
}
</script>

<style scoped>

.input_box{
  border: 1px solid #E9E9E9;
  border-radius: 6px;
}
.transparent-input {
  background: transparent; /* 배경색을 투명하게 설정 */
  border: none; /* 테두리 제거 */
  outline: none; /* 입력 테두리 제거 (선택 시 나타나는 테두리) */
  width: 100%; /* 너비를 100%로 설정하여 부모 요소에 맞게 확장 */
  height: 40px; /* 원하는 높이로 설정 */
  padding: 0; /* 내부 여백 제거 */
  font-size: 14px; /* 원하는 글꼴 크기 설정 */
}
</style>
