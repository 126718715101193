export default {
    install(Vue) {
        Vue.config.globalProperties.$addcart = function (idx,w,ct_out_price,ct_price,it_img1,it_name, quantity = 1){
            ct_out_price = typeof ct_out_price === 'number' ? ct_out_price : parseFloat(ct_out_price.trim());
            ct_price = typeof ct_price === 'number' ? ct_price : parseFloat(ct_price.trim());
            if (this.$store.state.login_flag) {
                let formData = new FormData();
                formData.append('mode', 'addCart_prd_detail');
                formData.append('martid', this.$store.state.martid);
                formData.append('token', this.$store.state.token);
                formData.append('lf', this.$store.state.lf);
                formData.append('w', w);
                let idx_list = [{"org_idx":idx,"op_qty":quantity}];
                formData.append('idx_list', JSON.stringify(idx_list));
                this.$axios.post(this.$store.state.apiEndpoint, formData)
                    .then(response => {
                        if (response.data.status == "NO"){
                            this.$modal.errorToast(response.data.message);
                        }else {
                            this.$modal.cartToast();
                            this.$store.dispatch('header_refreash');
                            this.$store.state.Cart_total = response.data.total_price;
                        }
                    })
                    .catch(error => {
                        this.$modal.errorToast(error);
                    }).finally(() => {
                    this.isLoading = false; // 요청이 끝나면 로딩 상태를 false로 설정
                });

            }else{
                //localStorage.clear();
                let cartData = JSON.parse(localStorage.getItem('leaf_cart')) || [];

                const existingItemIndex  = cartData.findIndex(item => item.idx === idx);
                if (existingItemIndex !== -1) {
                    // 기존 아이템이 존재하는 경우
                    cartData[existingItemIndex].ct_qty += quantity;

                } else {
                    const newItem = {
                        w: w,
                        martid: this.$store.state.martid,
                        lf: this.$store.state.lf,
                        checked:true,
                        ct_out_price:ct_out_price,
                        ct_price:ct_price,
                        ct_qty: quantity,
                        idx: idx,
                        it_img1:it_img1,
                        it_name:it_name,
                    };
                    cartData.push(newItem);
                }
// 업데이트된 Cart_total 값을 계산하여 업데이트합니다.
                this.$store.state.Cart_total = cartData.reduce((total, item) => {
                    const ct_price = parseInt(item.ct_price);
                    const ct_qty = parseInt(item.ct_qty);
                    return total + (ct_price * ct_qty);
                }, 0);
                // 로컬 스토리지에 업데이트된 카트 데이터 저장

                localStorage.setItem('leaf_cart', JSON.stringify(cartData));

                this.$modal.cartToast();
                this.$store.dispatch('header_refreash');
            }
        }
    }
}

