<template>
  <div class="docu-text">
    <span>{{text}}</span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
      default: ''
    },
  },
};
</script>

<style scoped>

.docu-text{
  width: calc(100% - 20px);
  margin: 10px;
  padding: 10px;
  overflow: hidden;
  border-radius: 15px;
  background: #3cb2b210;
}

.docu-text > span{
  color: #888888;
  font-weight: 500;
  font-family: Noto Sans KR;
}

</style>