<template>

  <div v-if="icon_1" class="flex-row">
    <div class="col-10 input_box flex-row flex-wrap" style="height: 45px; padding-left: 20px; padding-right: 20px;border-radius: 15px">
      <input :type="type"
             class="transparent-input active-font-14 col-11"
             :placeholder="placeholder"
             :value="formattedValue"
             @input="updateValue($event.target.value)">
    </div>
    <div class="grow-center col-2">
      <img v-if="icon_1" src="@/assets/img/svg/search.svg" style="padding-left: 10px" @click="searchbtn" />
    </div>
  </div>

  <div v-else class="input_box grow-left" style="height: 45px; padding-left: 20px; padding-right: 20px;">
    <input :type="inputType"
           class="transparent-input active-font-14 col-11"
           :placeholder="placeholder"
           :value="formattedValue"
           @input="updateValue($event.target.value)"
           @keydown="handleKeyDown"
           :maxlength="maxlength"
    >
  </div>
</template>
<script>
//v-modal을 추가하지않으면 부모에서, x표시안뜸 연동도안됨
//import { debounce } from 'lodash-es';

export default {
  props: {
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },modelValue: { // v-model을 위한 prop
      type: [String, Number],
      required: false,
      default: ''
    },icon_1: {
      type: Boolean,
      required: false,
      default: false
    },maxlength: {
      type: String,
      required: false,
      default: "300"
    },



  },
  emits: ['update:modelValue','searchkey'], // v-model 값 업데이트를 위한 이벤트
  data() {
    return {
      inputType: 'text', // type을 text로 고정
      formattedValue: '', // 포맷된 값을 저장할 데이터
      backspacePressed: false, // 백스페이스가 눌렸는지 추적할 플래그
      backspace:false
    };
  },
  watch: {
    // modelValue가 변경될 때마다 formattedValue를 업데이트합니다.
    modelValue: {
      immediate: true,
      handler(newValue) {
        this.formattedValue = this.formatPhone(newValue);
      }
    }
  },
  methods: {
    formatPhone(value) {
      if (this.type !== 'phone' && this.type !== 'company') return value; // 타입이 phone이 아니면 변경하지 않음

      if (this.type === 'company') {
        if (this.backspacePressed) {
          this.backspacePressed = false; // 플래그 초기화
          if (value.length === 5 || value.length === 8) {
            return value.slice(0, -1); // 마지막 하이픈 제거
          }
        }
        if (this.backspace) {
          this.backspace = false;
          return value.slice(0, -1);
        }


        let numbers = value.replace(/[^0-9]/g, '');
        if (numbers.length > 3 && numbers.length <= 5) {
          numbers = `${numbers.slice(0, 3)}-${numbers.slice(3)}`;
        } else if (numbers.length > 5) {
          numbers = `${numbers.slice(0, 3)}-${numbers.slice(3, 5)}-${numbers.slice(5)}`;
        }
        return numbers.slice(0, 12);
      }else if (this.type === 'phone') {
        if (this.backspacePressed) {
          this.backspacePressed = false; // 플래그 초기화
          if (value.length === 5 || value.length === 9) {
            return value.slice(0, -1); // 마지막 하이픈 제거
          }
        }
        // 숫자만 추출
        let numbers = value.replace(/[^0-9]/g, '');


        if (numbers.length > 3 && numbers.length <= 7) {
          numbers = `${numbers.slice(0, 3)}-${numbers.slice(3)}`;
        } else if (numbers.length > 7) {
          numbers = `${numbers.slice(0, 3)}-${numbers.slice(3, 7)}-${numbers.slice(7)}`;
        }
        return numbers.slice(0, 13);
      }
    },

    // 키 입력을 처리하는 메서드입니다.
    handleKeyDown(event) {
      // 숫자, 백스페이스, 탭 이외의 키 입력을 방지합니다.
      if (this.type == 'phone' || this.type == 'company') {
        if (!event.key.match(/[0-9]|Backspace|Tab/)) {
          this.backspace = true;
          event.preventDefault();
        }
      // 백스페이스를 눌렀을 때의 로직입니다.
        if (event.key === 'Backspace') {
          const value = event.target.value;
          if (value.length === 4 || value.length === 9) { // 하이픈 바로 뒤에서 백스페이스를 눌렀을 경우
            this.backspacePressed = true; // 하이픈을 삭제하기 위한 플래그 설정
          }
        }
      }
    },
    // 입력 값이 변경될 때마다 호출됩니다.
    updateValue(value) {
      this.formattedValue = this.formatPhone(value);
      this.$emit('update:modelValue', this.formattedValue);
    },
    // 입력값을 지우는 메서드입니다.
    clearInput() {
      this.formattedValue = '';
      this.$emit('update:modelValue', this.formattedValue);
    },
    searchbtn(){
      this.$emit('searchkey', this.modelValue); // 부모 컴포넌트로 값을 방출하여 업데이트
    },
    /*
        updateValue: debounce(function(value) {
      this.formattedValue = this.formatPhone(value);
      console.log(this.formattedValue);
      this.$emit('update:modelValue', this.formattedValue);
    }, 50),
    clearInput() {
      this.$emit('update:modelValue', ''); // 부모 컴포넌트로 빈 값을 방출
    },
    updateValue: debounce(function(value) {
      console.log(value);
      this.$emit('update:modelValue', value); // 부모 컴포넌트로 값을 방출하여 업데이트
    }, 100) // 300 밀리초 지연 */
  }
};
</script>

<style>
.clear-button {
  /* 'X' 버튼의 스타일을 여기에 추가합니다. */
  background: none;
  border: none;
  cursor: pointer;
  /* 필요에 따라 추가 스타일 지정 */
}
.input_box{
  border: 1px solid var(--inactive-color2);
  border-radius: 6px;
}
.transparent-input {
  background: transparent; /* 배경색을 투명하게 설정 */
  border: none; /* 테두리 제거 */
  outline: none; /* 입력 테두리 제거 (선택 시 나타나는 테두리) */
  width: 100%; /* 너비를 100%로 설정하여 부모 요소에 맞게 확장 */
  height: 40px; /* 원하는 높이로 설정 */
  padding: 0; /* 내부 여백 제거 */
  font-size: 14px; /* 원하는 글꼴 크기 설정 */
}
</style>
