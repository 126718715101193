<template>
  <div id="app">
    <!-- 'home' 경로가 아닌 경우에만 AppHeader를 렌더링합니다. -->
    <AppHeader v-if="shouldShowHeader"/>
    <router-view/>
    <AppFooter v-if="shouldShowFooter"/>
  </div>
</template>

<script>

import AppHeader from './components/Header.vue'
import AppFooter from './components/Footer.vue'
import {mapState} from "vuex";

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter
  },
  computed: {
    ...mapState({
      shouldShowHeader: state => state.showheader,
      shouldShowFooter: state => state.showfooter,
    })

  }
}
</script>

<style>


:root {
  --prime-color-pt: #E9FBFB;
  --prime-color: #3CB2B2;
  --prime-color-serv: #2E8A8A;
  --second-color:#2196F3;
  --prime-font-active: #2A2A2A;
  --prime-font-inactive: #B4B4B4;
  --active-button-color: #F45957;
  --active-color: #e14754;
  --warning-color: #FFA200;
  --active-bottom-text-color: #000000;
  --inactive-color: #b4b4b4; /* 비활성화 색상 */
  --inactive-color2: #d3d3d3; /* 비활성화 색상 */
}

.active-btn-color{
  color: var(--active-button-color) !important;
}
.inactive-font-color{
  color: var(--prime-font-inactive) !important;
}
.active-font-color{
  color: var(--prime-color-serv) !important;
}
.warning-font-color{
  color: var(--warning-color) !important;
}
.inactive-tab-color{
  background-color: #fff !important;
}
.active-tab-color{
  background-color: #E9FBFB !important;
}



.flex-wrap{
  display: flex;
  flex-wrap: wrap;

}
.flex-col{
  display: flex;
  flex-direction: column; /* 자식 요소들을 수직 방향으로 정렬 */

}
.flex-row{
  display: flex;
  flex-direction: row; /* 자식 요소들을 수직 방향으로 정렬 */
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
  box-sizing: border-box;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
  box-sizing: border-box;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
  box-sizing: border-box;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  box-sizing: border-box;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
  box-sizing: border-box;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
  box-sizing: border-box;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
  box-sizing: border-box;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  box-sizing: border-box;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
  box-sizing: border-box;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
  box-sizing: border-box;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
  box-sizing: border-box;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.active-font-18-b{
  font-size: 18px;
  font-family: 'Noto Sans CJK KR Bold';
  color: var(--prime-font-active);
}
.active-font-18{
  font-size: 18px;
  font-family: 'Noto Sans CJK KR';
  color: var(--prime-font-active);
}
.active-font-16-b{
  font-size: 16px;
  font-family: 'Noto Sans CJK KR Bold';
  color: var(--prime-font-active);
}
.active-font-16{
  font-size: 16px;
  font-family: 'Noto Sans CJK KR';
  color: var(--prime-font-active);
}
.active-font-14{
  font-size: 14px;
  font-family: 'Noto Sans CJK KR';
  color: var(--prime-font-active);
}
.active-font-14-b{
  font-size: 14px;
  font-family: 'Noto Sans CJK KR Bold';
  color: var(--prime-font-active);
}
.active-font-12{
  font-size: 12px;
  font-family: 'Noto Sans CJK KR';
  color: var(--prime-font-active);
}
.active-font-12-b{
  font-size: 12px;
  font-family: 'Noto Sans CJK KR Bold';
  color: var(--prime-font-active);
}
.inactive-font-16-b{
  font-size: 16px;
  font-family: 'Noto Sans CJK KR Bold';
  color: var(--prime-font-inactive);
}
.inactive-font-16{
  font-size: 16px;
  font-family: 'Noto Sans CJK KR';
  color: var(--prime-font-inactive);
}
.inactive-font-14{
  font-size: 14px;
  font-family: 'Noto Sans CJK KR';
  color: var(--prime-font-inactive);
}
.inactive-font-14-b{
  font-size: 14px;
  font-family: 'Noto Sans CJK KR Bold';
  color: var(--prime-font-inactive);
}
.inactive-font-12{
  font-size: 12px;
  font-family: 'Noto Sans CJK KR';
  color: var(--prime-font-inactive);
}
.inactive-font-12-b{
  font-size: 12px;
  font-family: 'Noto Sans CJK KR Bold';
  color: var(--prime-font-inactive);
}

.active-btn{
  border: 1px solid var(--prime-color);
  border-radius: 6px;
  background-color: var(--prime-color-pt);
  font-size: 14px;
  font-family: 'Noto Sans CJK KR';
  color: var(--prime-color);
}

.inactive-btn{
  border: 1px solid var(--inactive-color);
  border-radius: 6px;
  font-size: 14px;
  font-family: 'Noto Sans CJK KR';
  color: var(--inactive-color);
}


.grow { display: flex; }
.grow1 { flex-grow: 1; }
.grow2 { flex-grow: 2; }
.grow3 { flex-grow: 3; }
.grow4 { flex-grow: 4; }
.grow5 { flex-grow: 5; }
.grow-left{
  display: flex;
  justify-content: flex-start; /* 수평 축에서 오른쪽 정렬 */
  align-items: center;       /* 수직 축에서 중앙 정렬 */
}
.grow-right{
  display: flex;
  justify-content: flex-end; /* 수평 축에서 오른쪽 정렬 */
  align-items: center;       /* 수직 축에서 중앙 정렬 */
}
.grow-center{
  display: flex;
  justify-content: center; /* 수평 축에서 오른쪽 정렬 */
  align-items: center;       /* 수직 축에서 중앙 정렬 */
}
.padding-l-30{
  padding: 0px 0px 0px 1.5em;
}
.padding-r-30{
  padding: 0px 1.5em 0px 0px;
}
.h-margin{
  margin-left: 20px;
  margin-right: 20px;
}
.v-margin{
  margin-top: 10px;
  margin-bottom: 10px;
}
.h-margin2{
  margin-left: 40px;
  margin-right: 40px;
}
.v-margin2{
  margin-top: 20px;
  margin-bottom: 20px;
}
@font-face {
  font-family: 'Noto Sans CJK KR';
  font-style: normal;
  font-weight: 400;
  src: local('Noto Sans CJK KR'),
  url('@/assets/webfonts/NotoSansKR-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Noto Sans CJK KR Bold';
  font-style: normal;
  font-weight: bold;
  src: local('Noto Sans CJK KR'),
  url('@/assets/webfonts/NotoSansKR-Bold.otf') format('opentype');
}

.impstart {
  vertical-align: super; /* 슈퍼스크립트로 설정 */
  font-size: x-large; /* 글꼴 크기를 작게 설정 (선택 사항) */
  color: var(--active-button-color);
}

html,
body {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}

.loading_gif {
  width: 100px;
  height: 100px;
}
.loading-div{
  display: flex;
  height: calc(100vh - 180px);
  justify-content: center;
  align-items: center; /* 세로 방향으로 중앙 정렬 */
}

.nodata{
  display: flex;
  flex-direction: column; /* 자식 요소들을 수직 방향으로 정렬 */
  justify-content: center; /* 수직 방향(행)에서 중앙 정렬 */
  align-items: center; /* 수평 방향(열)에서 중앙 정렬 */
  height: 100%; /* 부모 요소의 전체 높이를 차지하도록 설정 */
  text-align: center; /* 텍스트를 중앙 정렬 */
  margin-top: 25vh;
}
.no_list{
  max-width: 100%; /* 이미지가 컨테이너 너비를 초과하지 않도록 */
  height: auto; /* 이미지의 비율을 유지하면서 너비에 맞춰 높이 조정 */
}
.no_list_text{
  color: var(--prime-font-inactive);
  margin-top: 20px; /* 이미지와 텍스트 사이의 간격 조정 */
}
.band-now{
  background-color: #A9F3F2;
  border-radius: 0.2rem;
  color: #30888A;
  display: inline-block;
  line-height: 1.1;
  padding: 4px 10px 4px 10px;
}

.band-btn{
  background-color: #E9FBFB;
  border-radius: 0.2rem;
  color: var(--prime-color);
  display: inline-block;
  line-height: 1.1;
  padding: 8px 20px 8px 20px;
  font-size: 12px;
  font-family: 'Noto Sans CJK KR';
  margin-right: 20px;
}
</style>
