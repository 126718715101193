<!-- MapKakao -->
<template>
  <div id="map">
    <button @click="movenowgps" class="move-btn">현재 위치로 이동</button>
  </div>
</template>

<script>
export default {
  name:"MapKakao",
  props: {
    latitude: {
      type: Number,
      required: true,
    },
    longitude: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      map: null,
      marker: null,
      customOverlay: null,
    }
  },
  mounted() {
    if (window.kakao && window.kakao.maps) {
      this.loadMap();
    }  else {
      this.loadScript();
    }
  },
  emits: ['returnadr'], // 커스텀 이벤트 명시
  methods:{
    loadScript() {
      const script = document.createElement("script");
      script.src = "https://dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=6fb4e19e0b596afb36c29bf48e636210";
      script.onload = () => window.kakao.maps.load(this.loadMap); // initMap은 지도를 초기화하는 메서드입니다.
      document.head.appendChild(script);
    },
    loadMap() {
      const container = document.getElementById("map");
      const options = {
        center: new window.kakao.maps.LatLng(37.5665, 126.9780),
        level: 4
      };

      this.map = new window.kakao.maps.Map(container, options);
      this.addMarkerAndOverlay();
      this.addEventListeners();
    },
    addMarkerAndOverlay() {
      const markerPosition = this.map.getCenter();
      this.get_xy_local(markerPosition.getLat(), markerPosition.getLng());
      this.marker = new window.kakao.maps.Marker({
        position: markerPosition
      });


      const content = '<div style="padding:5px; background:white;border: 2px solid #f5c521;border-radius: 15px;">지도를 움직여서 위치를 지정해주세요.</div>';
      this.customOverlay = new window.kakao.maps.CustomOverlay({
        map: this.map,
        position: this.marker.getPosition(),
        content: content,
        yAnchor: 2.5
      });
      this.marker.setMap(this.map);
    },
    addEventListeners() {
      window.kakao.maps.event.addListener(this.map, 'drag', this.handleDrag);
      window.kakao.maps.event.addListener(this.map, 'dragend', this.handleDragEnd);
    },
    handleDrag() {
      const center = this.map.getCenter();
      this.marker.setPosition(center);
      this.customOverlay.setPosition(center);
    },
    handleDragEnd() {
      const center = this.map.getCenter();
      this.get_xy_local(center.getLat(), center.getLng());
    },
    async get_xy_local(y,x) {
      const url = "https://dapi.kakao.com/v2/local/geo/coord2address.json?x="+ x +"&y=" + y;
      const userHeader = {
        headers: {
          "Content-type": "application/json",
          "Accept": "application/json",
          "Authorization": "KakaoAK 407e7648adf9101b8361bbff9d2f7cad"
        }
      };

      try {

        const response = await this.$axios.get(url, userHeader);
        const address = response.data.documents[0].address.address_name;
        let roadAddress = response.data.documents[0].road_address;
        roadAddress = roadAddress ? roadAddress.address_name : "";

        this.$emit('returnadr', address, roadAddress);

      } catch (error) {
        this.$emit('returnadr', "", "");
        console.error(error);
      }
    },
    movenowgps(){
      if (window.askgps && typeof window.askgps.postMessage === 'function') {
        window.askgps.postMessage('');
      }
    },
    moveToSpecifiedLocation() {
      const specifiedLatLng = new window.kakao.maps.LatLng(this.latitude, this.longitude);
      this.map.setCenter(specifiedLatLng);
      this.marker.setPosition(specifiedLatLng);
      this.customOverlay.setPosition(specifiedLatLng);

        const center = this.map.getCenter();
        this.get_xy_local(center.getLat(), center.getLng());

    }
  },
  watch: {
    // 'longitude'나 'latitude' prop이 변경될 때 호출될 핸들러입니다.
    longitude: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.moveToSpecifiedLocation();
      }
    },
    latitude: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.moveToSpecifiedLocation();
      }
    },
  },
};
</script>

<style>
#map {
  width: 100%;
  height: 50vh;
  position: relative; /* 지도 내에서 절대 위치를 지정하기 위해 필요 */
}
.move-btn {
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 10px;
  background-color: #fff;
  border: 2px solid #e38808;
  border-radius: 5px;
  cursor: pointer;
  z-index: 3000;
}

</style>
