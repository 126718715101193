<template>
  <div v-if="isLoading" class="loading-div">
    <img class="loading_gif" src="@/assets/img/loading.gif" alt="로딩 중..." />
  </div>
  <div  v-else class="product-list" style="padding: 10px 20px 10px 20px;">
    <div v-for="product in products" :key="product.idx" style="margin-bottom: 15px" @click="push_move(product.weburl)">
      <div class="flex-row" style="border: 2px solid #e0e0e0;border-radius: 15px;margin-top: 5px;">
      <div class="col-6 grow-center" style="padding-left: 5px">
        <img style="margin:5px;object-fit: cover;width:100%;border-radius: 15px;" :src="product.push_img" />
      </div>
      <div class="col-5 flex-col grow-center" style="padding: 0px 5px 0px 5px;">
          <span v-if="product.push_title != ''">{{product.push_title}}</span>
          <span class="active-font-14">{{ truncateText(product.push_m,40)}}</span>
      </div>
      </div>
      <div class="flex-row">
        <span class="col-12 grow-right inactive-font-12">{{product.push_time}}</span>
      </div>
    </div>
  </div>
</template>

<script>




export default {
  created() {
    this.fetchProducts();
    this.$store.dispatch('Showheaderfooter', {
      Title: '알람 리스트',
      showBack: true,
      showAlarm: false,
      showCart: false,
      showsheader: true,
      showFooter: false,
      enableScrollHeader: false,
    });
  },
  data() {
    return {
      products: [],
      isLoading:true,
    }
  },
  methods: {
    push_move(url){
      if (url != ''){
        console.log("무브 전단지");
      }
    },
    fetchProducts() {
      let formData = new FormData();
      formData.append('mode', 'get_push_list');
      formData.append('martid', this.$store.state.martid);
      formData.append('token', this.$store.state.token);
      this.$axios.post(this.$store.state.apiEndpoint, formData)
          .then(response => {
            this.products = response.data.data;
            this.isLoading = false;
          })
          .catch(error => {
            this.$modal.errorToast(error);
          });
    },
    truncateText(text, length) {
      if (text.length <= length) {
        return text;
      } else {
        return text.slice(0, length) + '...';
      }
    }
  }
}
</script>

<style scoped>

</style>
