<template>
  <div v-if="isLoading" class="loading-div">
    <img class="loading_gif" src="@/assets/img/loading.gif" alt="로딩 중..." />
  </div>
  <div v-else>
      <div v-if="products.b2b_cart_list.length == 0 && products.mart_cart_list.length == 0" class="nodata">
        <img class="no_list" src="@/assets/img/svg/big_cart.svg" />
        <span class="no_list_text">장바구니에 담긴 상품이 없습니다.</span>
      </div>
      <div v-else>
        <div class="flex-row" style="margin-top: 8px;padding:10px 0 10px 0;background-color:#D8F6F6;">
          <div class="col-12 grow-left"><ui_check :isActive="isAllChecked" style="padding-left: 10px;" @click="toggleAllCheck_click" @change="toggleAllCheck" /><span style="padding-left: 10px" class="active-font-16-b">마트 상품</span></div>
        </div>
        <div class="product-list">
          <div v-for="(product, index) in products.mart_cart_list" :key="product.idx" style="margin-bottom: 10px;margin-top: 10px">
            <div class="flex-row">
            <div class="col-3" style="position: relative"><ui_check :isActive="product.checked"  @click="toggleProductCheck(product)" style="position: absolute;top:5px;left:10px;z-index:5;"/>
              <img style="width: 100%;height: auto;padding: 8px" :src="product.it_img1" @error="handleImageError(product)" /></div>
            <div class="col-9 flex-col" style="padding-left: 14px;padding-top: 10px;">
              <div class="flex-row ">
                <div class="col-8 inactive-font-14 grow-left">{{product.mart_name}}</div>
                <div class="col-4 grow-right"><img @click="cartitdel(product.idx)" style="width: 28px;margin-right: 10px;" src="@/assets/img/svg/x.svg" /></div>
              </div>
                <div class="active-font-16" style="padding-top: 0px;padding-bottom: 6px;">{{product.it_name}}</div>
              <div class="flex-row" style="padding-top: 6px;">
                <div v-if="product.ct_out_price == 0" class="col-8 active-font-18-b">{{$formatPrice(product.ct_price)}}원</div>
                <div v-else class="col-8 active-font-18-b"><span class="inactive-font-12" style="text-decoration:line-through;margin-right: 4px">{{$formatPrice(product.ct_out_price)}}원</span>{{$formatPrice(product.ct_price)}}원</div>
                <div class="col-4 flex-row grow-right">
                  <div class="grow-center"><img style="width: 28px;" src="@/assets/img/svg/minus_outline.svg" @click="minusitem(product)" /></div>
                  <div class="grow-center active-font-18-b " style="margin-left: 10px;margin-right: 10px;">{{product.ct_qty}}</div>
                  <div class="grow-center" style="margin-right: 10px"><img style="width: 28px" src="@/assets/img/svg/plus_outline.svg" @click="plusitem(product)" /></div>
                </div>
              </div>
            </div>
            </div>
            <div v-if="products.mart_cart_list.length-1 > index" style="width: calc(100% - 20px);height: 2px;background-color: #F6F6F6;margin-left: 10px;" class="v-margin2">
            </div>
          </div>
          <div style="padding: 20px">
            <div v-if="add_text_div" class="add_text_btn grow-center active-font-14" style="color: var(--prime-color);height: 40px;" @click="toggleaddtext">
              <img src="@/assets/img/svg/plus_memo.svg" style="padding: 6px;"/>위 품목 외 문자로 추가하기
            </div>
            <div v-else class="add_text_btn grow-center" style="color: var(--prime-color);">
                <textarea v-model="additionalOrder" class="active-font-14" style="width: calc(100% - 40px);min-height: 1em;resize: none;overflow-y: hidden;  border: none;outline: none;background: transparent;" rows="5" placeholder="추가적으로 주문하실 상품을 입력해주세요. (ex. 양파3개 1묶음)"></textarea>
            </div>
          </div>
        </div>
        <div style="width: 100%;height: 10px;background-color: #F6F6F6;">
        </div>
        <div class="flex-row">
          <div class="col-6 active-font-16-b" style="padding-left: 20px;padding-top: 30px;">총 상품금액</div>
          <div class="col-6 grow-right active-font-16-b" style="padding-right: 20px;padding-top: 30px;">{{$formatPrice(total_prd)}}원</div>
        </div>
        <div class="flex-row">
          <div class="col-6 active-font-16-b" style="padding-left: 20px;padding-top: 20px;">총 배송비</div>
          <div class="col-6 grow-right active-font-16-b" style="padding-right: 20px;padding-top: 20px;">{{$formatPrice(total_deliver)}}원</div>
        </div>
          <div class="flex-row">
          <div class="col-10" style="padding-left: 20px;padding-top: 10px;">
            <div v-if="this.products.mart_deliver_type == '조건부무료배송' && parseInt(this.products.mart_deliver_jo) > 0 && parseInt(this.products.mart_deliver_p) > 0" class="inactive-font-12 grow-right">
              조건부 무료배송 {{$formatPrice(this.products.mart_deliver_jo)}}원 이상 구매시 무료 배송
            </div>
            <div v-if="this.products.mart_deliver_type == '유료배송' && parseInt(this.products.mart_deliver_jo) > 0 && parseInt(this.products.mart_deliver_p) > 0" class="inactive-font-12 grow-right">
              유료 배송 {{$formatPrice(this.products.mart_deliver_jo)}}원
            </div>
            <div v-if="this.products.mart_deliver_type == '무료배송'" class="inactive-font-12 grow-right">
              무료 배송
            </div>
          </div>
        </div>
        <div style="width: calc(100% - 20px);height: 2px;background-color: #F6F6F6;margin-left: 10px;" class="v-margin2">
        </div>
        <div class="flex-row">
          <div class="col-6 active-font-18" style="padding-left: 20px;padding-bottom: 20px;color:var(--prime-color)">총 결제금액</div>
          <div class="col-6 grow-right active-font-18-b" style="padding-right: 20px;padding-bottom: 20px;color:var(--prime-color)">{{$formatPrice(parseInt(total_prd) + parseInt(total_deliver))}}원</div>
        </div>
        <div style="width: 100%;height: 110px;background-color: #F6F6F6;">
        </div>
      </div>
  </div>
  <div class="bottom_btn" @click="submitOrder">
    <div v-if="mart_prd_count > 0" style="padding-top: 4px;padding-bottom: 4px;background-color: #fff;border-top: 1px solid #eaeaea;">
      <span class="active-font-14 grow-left" style="color: var(--prime-font-active);padding-left: 10px;">선택상품 {{mart_prd_count}}개  {{$formatPrice(total_prd)}}원 + 배송비 {{$formatPrice(total_deliver)}}원 = {{$formatPrice(total_prd + total_deliver)}}원</span>
    </div>
    <div style="padding-top: 20px;padding-bottom: 20px;background-color: var(--prime-color);">
      <span v-if="mart_prd_count == 0" class="active-font-16-b" style="color: #fff !important;">장바구니가 비어있습니다.</span>
      <span v-else class="active-font-16-b" style="color: #fff !important;">{{$formatPrice(parseInt(total_prd) + parseInt(total_deliver))}}원 주문하기</span>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {
      products: {},
      mart_info: {},
      allcheck:false,
      isLoading:true,
      isAllChecked: true,
      total_prd:0,
      total_deliver:0,
      mart_prd_count:0,
      add_text_div:true,
      additionalOrder: '',
      able_time_s:"",
      able_time_e:"",
    }
  },
  created() {
    this.$store.dispatch('Showheaderfooter', {
      Title: '장바구니',
      showBack: true,
      showAlarm: false,
      showCart: false,
      showsheader: true,
      showFooter: false,
      enableScrollHeader: true,
    });
  },
  mounted() {
    this.additionalOrder = this.$store.state.cart_add_order;
    if (this.additionalOrder != ""){
      this.add_text_div = false;
    }
    this.fetchProducts();
  },
  methods: {
    submitOrder() {
      const now = new Date();


      const startTime = this.getTimeFromDate(now, this.able_time_s || '08:30'); //
      const endTime = this.getTimeFromDate(now, this.able_time_e  || '16:30');
      if (now < startTime || now > endTime) {
        this.$modal.errorToast("현재 마트에서 주문을 접수할수 없는 시간입니다.");
        return;
      }
      if (this.$store.state.login_flag == 0){
        this.$modal.loginPrompt("주문을 하기위해선 로그인이 필요합니다.").then((result) => {
          if (result.isConfirmed) {
            this.$router.push('/login');
          }
        });
      }else {
        let martid = '';
        this.products.mart_cart_list.forEach((product) => {
          if (product.checked == true) {
            martid = martid + product.idx + ",";
          }
        });
        const adddtext = this.additionalOrder || '';
        this.$store.state.cart_order_list = martid;
        this.$store.state.cart_add_order = adddtext;
        this.$store.state.cart_addr_idx = "";
        this.$router.push({name: 'Order_Page'});
      }
    },
    getTimeFromDate(currentDate, timeString) {
      const [hours, minutes] = timeString.split(':');
      return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), parseInt(hours), parseInt(minutes));
    },
    recalc(){
      this.total_prd = 0;
      this.mart_prd_count = 0;
      //
      this.products.mart_cart_list.forEach((product) => {
        if (product.checked == true){
          this.mart_prd_count = this.mart_prd_count +1;
          this.total_prd = this.total_prd + parseInt(product.ct_price)*parseInt(product.ct_qty);
        }

      });


      if (this.products.mart_deliver_type == "조건부무료배송"){
        if (parseInt(this.total_prd) > parseInt(this.products.mart_deliver_jo)){
          this.total_deliver = 0;
        }else{
          this.total_deliver = parseInt(this.products.mart_deliver_p);
        }

      }else if(this.products.mart_deliver_type == "유료배송"){
        this.total_deliver = parseInt(this.products.mart_deliver_p);
      }else if(this.products.mart_deliver_type == "무료배송") {
        this.total_deliver = 0;
      }
    },
    toggleaddtext(){
      this.add_text_div = !this.add_text_div;
    },
    toggleProductCheck(product) {
      product.checked = !product.checked;
      this.isAllChecked = this.products.mart_cart_list.every((p) => p.checked);
      this.recalc();
    },
    toggleAllCheck_click(){
      const newCheckStatus = !this.isAllChecked;
      this.products.mart_cart_list.forEach((product) => {
        product.checked = newCheckStatus;
      });
      this.isAllChecked = !this.isAllChecked;
      this.recalc();
    },
    toggleAllCheck() {
      const newCheckStatus = !this.isAllChecked;
      this.products.mart_cart_list.forEach((product) => {
        product.checked = newCheckStatus;
      });
    },
    plusitem(product){
      product.ct_qty++;
      this.updateQtyOnServer(product,product.ct_qty);
    },
    minusitem(product) {
      if (product.ct_qty > 1) {
        product.ct_qty--;
        this.updateQtyOnServer(product, product.ct_qty);
      }
    },
    updateQtyOnServer(product,ct_qty) {

      if (!this.$store.state.login_flag) {

        localStorage.setItem('leaf_cart', JSON.stringify(this.products.mart_cart_list));
        this.recalc();
      }else {


        let formData = new FormData();
        formData.append('mode', 'add_qty');
        formData.append('martid', this.$store.state.martid);
        formData.append('token', this.$store.state.token);
        formData.append('idx', product.idx);
        formData.append('count', ct_qty);
        formData.append('w', "mart");
        this.$axios.post(this.$store.state.apiEndpoint, formData)
            .then(response => {
              console.log(response);
              this.recalc();
            })
            .catch(error => {
              this.$modal.errorToast(error);
            });
      }
    },
    cartitdel(cpidx){
      if (!this.$store.state.login_flag) {
        if (this.products.mart_cart_list && Array.isArray(this.products.mart_cart_list)) {
          const idx = this.products.mart_cart_list.findIndex(item => item.idx === cpidx);
          if (idx !== -1) {
            this.products.mart_cart_list.splice(idx, 1);
            // 필요한 경우 로컬 스토리지에 업데이트된 데이터를 다시 저장할 수 있습니다.
            localStorage.setItem('leaf_cart', JSON.stringify(this.products.mart_cart_list));
          }
        }
        this.recalc();
      }else {
        let formData = new FormData();
        formData.append('mode', 'del_item');
        formData.append('martid', this.$store.state.martid);
        formData.append('token', this.$store.state.token);
        formData.append('idx', cpidx);
        formData.append('w', "mart");
        this.$axios.post(this.$store.state.apiEndpoint, formData)
            .then(response => {
              this.fetchProducts();
              console.log(response);
            })
            .catch(error => {
              this.$modal.errorToast(error);
            });
      }
    },
    fetchProducts() {

        let formData = new FormData();
        formData.append('mode', 'app_cart');
        formData.append('martid', this.$store.state.martid);
        formData.append('token', this.$store.state.token);
        formData.append('lf', this.$store.state.lf);
        this.$axios.post(this.$store.state.apiEndpoint, formData)
            .then(response => {

              this.products = response.data.data;
              this.able_time_s = response.data.data.able_time_s;
              this.able_time_e = response.data.data.able_time_e;
              if (!this.$store.state.login_flag) {
                let cartData = JSON.parse(localStorage.getItem('leaf_cart')) || [];
                this.products.mart_cart_list = cartData;
              }
              if (response.data.toast_msg != ""){
                this.$modal.midToast(response.data.toast_msg);
              }
              this.mart_info = response.data.mart_info;
              this.recalc();
            })
            .catch(error => {
              this.$modal.errorToast(error);
            }).finally(() => {
          this.isLoading = false; // 요청이 끝나면 로딩 상태를 false로 설정
        });

    },
    handleImageError(product) {
      product.it_img1 = require('@/assets/img/no_img.gif');
    },
  }
}
</script>
<style scoped>

.add_text_btn{

  border: 1px solid var(--prime-color);
  border-radius: 6px;
}

.bottom_btn{

  position: fixed; /* 절대 위치 */
  bottom: 0px; /* 하단 여백 조정 */
  left: 0;
  z-index: 10; /* 다른 요소 위에 표시되도록 설정 */
  width: 100%; /* 스티커 너비 조정 */
  text-align: center; /* 텍스트 중앙 정렬 */
}




</style>
