// src/store/index.js
import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
    state() {
        return {
            apiEndpoint:"https://mart.my/web_app/app_func.php",
            headerVisible: true,
            lastScroll: 0,
            enableScrollHeader: false, // 스크롤 헤더 기능 활성화 상태
            headerTitle: '기본 헤더',
            headeralarm: true,
            headercoupon: false,
            headercart: true,
            headeredit: false,
            backbutton:false,
            showheader:true,
            showfooter:true,
            Alarm_Quantity: 0,
            Cart_Quantity: 0,
            Coupon_Quantity: 0,
            martid:localStorage.getItem('martid'),
            lf:"",
            mart_name:"마트",
            user_name:"",
            login_flag:0,
            token:"",
            Cart_total: 0,
            cart_order_list:"",
            cart_add_order:"",
            cart_addr_idx:"",
        };
    },
    mutations: {
        setHeaderVisibility(state, visibility) {
            state.headerVisible = visibility;
        },
        setLastScroll(state, position) {
            state.lastScroll = position;
        },
        setEnableScrollHeader(state, enable) {
            state.enableScrollHeader = enable;
        },
        SET_ALARM_QUANTITY(state, payload) {
            state.Alarm_Quantity = payload;
        },
        SET_Cart_QUANTITY(state, payload) {
            state.Cart_Quantity = payload;
        },
        SET_Coupon_QUANTITY(state, payload) {
            state.Coupon_Quantity = payload;
        },
        setHeaderTitle(state, title) {
            state.headerTitle = title;
        },
        setHeaderAlarm(state, bool) {
            state.headeralarm = bool;
        },
        setHeaderCoupon(state, bool) {
            state.headercoupon = bool;
        },
        setHeaderCart(state, bool) {
            state.headercart = bool;
        },
        setHeaderedit(state, bool) {
            state.headeredit = bool;
        },
        setHeaderback(state, bool) {
            state.backbutton = bool;
        },
        setShowHeader(state, bool) {
            state.showheader = bool;
        }
        ,
        setShowFooter(state, bool) {
            state.showfooter = bool;
        },
        SET_LOGIN_FLAG(state, flag) {
            state.login_flag = flag;
        },
        SET_USER_NAME(state, name) {
            state.user_name = name;
        },
        SET_LOGIN_TOKEN(state, token) {
            state.token = token;
        },
    },
    actions: {
        handleScroll({ state, commit }) {
            if (!state.enableScrollHeader) return; // 스크롤 헤더 기능이 비활성화된 경우 동작하지 않음
            const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
            const headerVisible = !(currentScroll > state.lastScroll && currentScroll > 50);
            commit('setHeaderVisibility', headerVisible);
            commit('setLastScroll', currentScroll);
        },
        setAlarmQuantity({ commit }, payload) {
            commit('SET_ALARM_QUANTITY', payload);
        },
        setCartQuantity({ commit }, payload) {
            commit('SET_Cart_QUANTITY', payload);
        },
        setCouponQuantity({ commit }, payload) {
            commit('SET_Coupon_QUANTITY', payload);
        },
        Showheaderfooter({ commit }, payload) {
            commit('setHeaderTitle', payload.Title);
            commit('setHeaderback', payload.showBack);
            commit('setHeaderAlarm', payload.showAlarm);
            commit('setHeaderCoupon', payload.showcoupon);
            commit('setHeaderCart', payload.showCart);
            commit('setHeaderedit', payload.showedit);
            commit('setShowHeader', payload.showsheader);
            commit('setShowFooter', payload.showFooter);
            commit('setEnableScrollHeader', payload.enableScrollHeader);
            /*
            if (Object.prototype.hasOwnProperty.call(payload, 'enableScrollHeader')) {
                commit('setEnableScrollHeader', payload.enableScrollHeader);
            }*/
        },
        header_refreash({ commit, state }) {
            return new Promise((resolve, reject) => {
            let token = localStorage.getItem('token');
            commit('SET_LOGIN_TOKEN', token);
            let formData = new FormData();
            formData.append('mode', 'get_header_info');
            formData.append('martid', this.state.martid);
            formData.append('token', token);
            formData.append('lf', this.state.lf);
            axios.post(state.apiEndpoint, formData)
                .then(response => {
                    if (response.data.login_flag == 1) {
                        commit('SET_USER_NAME', response.data.user_name);
                        commit('SET_LOGIN_FLAG', 1);
                        commit('SET_LOGIN_TOKEN', token);
                    }else{
                        commit('SET_USER_NAME', "");
                        commit('SET_LOGIN_FLAG', 0);
                        localStorage.removeItem('token');
                    }

                    // 가져온 제품 데이터를 스토어에 커밋하거나 다른 상태 업데이트 로직 실행
                    commit('SET_ALARM_QUANTITY', response.data.alarm_count);
                    if (this.state.login_flag == 0){
                        let t_count = 0;
                        let leaf_cart = JSON.parse(localStorage.getItem('leaf_cart')) || [];
                        let cate_cart = JSON.parse(localStorage.getItem('cate_cart')) || [];
                        let b2b_cart = JSON.parse(localStorage.getItem('b2b_cart')) || [];
                        t_count = leaf_cart.length + cate_cart.length + b2b_cart.length;
                        commit('SET_Cart_QUANTITY', t_count);




                    }else{
                        commit('SET_Cart_QUANTITY', response.data.cart_count);
                    }

                    commit('SET_Coupon_QUANTITY', response.data.coupon_count);
                    commit('setHeaderVisibility', true);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
            });
        },
    },
    getters: {
        getAlarmQuantity(state) {
            return state.Alarm_Quantity;
        },
        getCartQuantity(state) {
            return state.Cart_Quantity;
        },
    },

});
