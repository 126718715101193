<template>
  <div v-if="isLoading" class="loading-div">
    <img class="loading_gif" src="@/assets/img/loading.gif" alt="로딩 중..." />
  </div>
  <div v-else>

    <div style="width: 100%;height: 10px;background-color: #F6F6F6;margin-bottom: 10px;border-top: 1px solid #d9d9d9;">
    </div>



    <div v-for="(product, index) in products" :key="product.idx" class="flex-col" style="padding-top: 10px;">
      <div class="flex-row">
        <div class="col-2" style="padding: 16px 0px 16px 20px"><ui_adr_home v-if="product.subject =='기본배송지'"/><ui_adr_etc v-else/></div>
        <div class="col-10 flex-col grow-left" style="padding-left: 10px;padding-right: 20px;">
          <div class="flex-row" style="width: 100%;padding-bottom: 5px">
              <div class="col-6 active-font-18-b grow-left">{{product.subject}}</div>
            <div v-if="product.default == 1" class="col-6 grow-right"><span class="band-now active-font-12">현재 설정 주소</span></div>
          </div>
          <div style="width: 100%"><span class="inactive-font-14 grow-left">{{product.addr1}} {{product.addr2}}</span></div>
        </div>
      </div>
      <div class="flex-row" style="margin-top: 10px">
        <div class="col-2">
        </div>
        <div class="col-10">
        <span class="band-btn" @click="edit_adr(product.idx)">변경</span>
          <span class="band-btn" @click="del_adr(product.idx)">삭제</span>
        </div>
      </div>
      <div style="padding:5px 20px 5px 20px">
        <div v-if="products.length-1 > index" style="width: 100%;height: 1px;background-color: #F6F6F6;" class="v-margin"></div>
      </div>
    </div>

    <div style="width: 100%;height: 10px;background-color: #F6F6F6;border-top: 1px solid #d9d9d9;" class="v-margin">
    </div>

  </div>
</template>

<script>


export default {
  data() {
    return {
      products: [],
      products_last: [],
      isLoading:true,
    }
  },
  created() {
    this.fetchProducts();
    this.$store.dispatch('Showheaderfooter', {
      Title: '주소 관리',
      showBack: true,
      showAlarm: false,
      showCart: false,
      showedit: false,
      showsheader: true,
      showFooter: false,
    });
  },

  methods: {
    edit_adr(idx){
      this.$router.push({ name: 'adr_detail_m', params: { idx: idx }});
    },
    del_adr(idx){
      let formData = new FormData();
      formData.append('mode', 'adr_del');
      formData.append('martid', this.$store.state.martid);
      formData.append('token', this.$store.state.token);
      formData.append('idx', idx);
      this.$axios.post(this.$store.state.apiEndpoint, formData)
          .then(response => {
            this.$modal.successToast(response.data.message);
            this.fetchProducts();
          })
          .catch(error => {
            this.$modal.errorToast(error);
          }).finally(() => {
        this.isLoading = false; // 요청이 끝나면 로딩 상태를 false로 설정
      });
    },
    fetchProducts() {
      let formData = new FormData();
      formData.append('mode', 'get_useradr_list');
      formData.append('martid', this.$store.state.martid);
      formData.append('token', this.$store.state.token);
      this.$axios.post(this.$store.state.apiEndpoint, formData)
          .then(response => {
            this.products = response.data.data;
            this.products_last = response.data.last;
            console.log(this.products);
          })
          .catch(error => {
            this.$modal.errorToast(error);
          }).finally(() => {
        this.isLoading = false; // 요청이 끝나면 로딩 상태를 false로 설정
      });
    },
  }
}
</script>

<style scoped>




.input_box{
  border: 1px solid #E9E9E9;
  border-radius: 6px;
}
.transparent-input {
  background: transparent; /* 배경색을 투명하게 설정 */
  border: none; /* 테두리 제거 */
  outline: none; /* 입력 테두리 제거 (선택 시 나타나는 테두리) */
  width: 100%; /* 너비를 100%로 설정하여 부모 요소에 맞게 확장 */
  height: 40px; /* 원하는 높이로 설정 */
  padding: 0; /* 내부 여백 제거 */
  font-size: 14px; /* 원하는 글꼴 크기 설정 */
}
</style>
