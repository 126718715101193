<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="그룹_24" data-name="그룹 24" transform="translate(2 0)">
        <path id="패스_22" data-name="패스 22" d="M17.348,1.705h-.93V.545a.532.532,0,1,0-1.063,0V1.711H4.624V.545a.532.532,0,1,0-1.063,0V1.711h-.9A2.692,2.692,0,0,0,0,4.435V21.276A2.692,2.692,0,0,0,2.657,24H17.343A2.692,2.692,0,0,0,20,21.276V4.429a2.691,2.691,0,0,0-2.652-2.724M18.942,21.27A1.619,1.619,0,0,1,17.348,22.9H2.657A1.619,1.619,0,0,1,1.063,21.27V4.429A1.619,1.619,0,0,1,2.657,2.8h.9V3.64a.532.532,0,1,0,1.063,0V2.8H15.355V3.64a.532.532,0,1,0,1.063,0V2.8h.93a1.619,1.619,0,0,1,1.594,1.634Z" :fill="currentColor" :stroke="currentColor" stroke-width="0.2"/>
        <path id="패스_23" data-name="패스 23" d="M59.98,209.056l-1.961,1.918-.9-.926a.521.521,0,0,0-.75,0,.553.553,0,0,0,0,.768l1.27,1.3a.515.515,0,0,0,.377.158.508.508,0,0,0,.367-.153l2.333-2.288a.55.55,0,0,0,.15-.522.536.536,0,0,0-.369-.392.519.519,0,0,0-.515.135" transform="translate(-53.22 -197.505)" :fill="currentColor" :stroke="currentColor" stroke-width="0.2"/>
        <path id="패스_24" data-name="패스 24" d="M191.632,229.938h-6.112a.545.545,0,0,0,0,1.09h6.112a.545.545,0,0,0,0-1.09" transform="translate(-175.145 -217.396)" :fill="currentColor" :stroke="currentColor" :stroke-width="0.2"/>
        <path id="패스_25" data-name="패스 25" d="M59.98,109.169l-1.961,1.918-.9-.926a.521.521,0,0,0-.75,0,.553.553,0,0,0,0,.768l1.27,1.3a.515.515,0,0,0,.377.158.507.507,0,0,0,.367-.153l2.333-2.288a.55.55,0,0,0,.15-.522.536.536,0,0,0-.369-.392.519.519,0,0,0-.515.135" transform="translate(-53.22 -103.067)" :fill="currentColor" :stroke="currentColor" stroke-width="0.2"/>
        <path id="패스_26" data-name="패스 26" d="M191.632,130.051h-6.112a.545.545,0,0,0,0,1.09h6.112a.545.545,0,0,0,0-1.09" transform="translate(-175.145 -122.957)" :fill="currentColor" :stroke="currentColor" :stroke-width="0.2"/>
        <path id="패스_27" data-name="패스 27" d="M59.98,308.943l-1.961,1.918-.9-.926a.521.521,0,0,0-.75,0,.553.553,0,0,0,0,.768l1.27,1.3a.516.516,0,0,0,.377.158.508.508,0,0,0,.367-.153l2.333-2.288a.55.55,0,0,0,.15-.522.536.536,0,0,0-.369-.392.519.519,0,0,0-.515.135" transform="translate(-53.22 -291.944)" :fill="currentColor" :stroke="currentColor" stroke-width="0.2"/>
        <path id="패스_28" data-name="패스 28" d="M191.632,329.824h-6.112a.545.545,0,0,0,0,1.09h6.112a.545.545,0,0,0,0-1.09" transform="translate(-175.145 -311.834)" :fill="currentColor" :stroke="currentColor" stroke-width="0.2"/>
      </g>
    </svg>
</template>

<script>
export default {
  props: ['isActive'],
  computed: {
    currentColor() {
      return this.isActive ? 'var(--active-button-color)' : 'var(--inactive-color)';
    },
  }
};
</script>
