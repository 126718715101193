<template>
  <div ref="observerElement"></div>
</template>

<script>
export default {
  data() {
    return {
      observer: null,
      observerOptions: {
        rootMargin: '0px',
        threshold: 1.0
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      const observerElement = this.$refs.observerElement;
      if (observerElement instanceof Element) { // 요소가 DOM 요소인지 확인
        this.observer = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting) {
            // 관찰 대상이 교차되었을 때 intersect 이벤트를 호출합니다.
            this.$emit('show');
          } else {
            this.$emit('hidden');
          }
        }, this.observerOptions);

        const observerElement = this.$refs.observerElement;
        this.observer.observe(observerElement);
      }
    });
  }
};
</script>