<template>
  <div class="flex-col">
    <div class="flex-row flex-wrap" style="margin-top: 30px">
      <span class="active-font-16" style="width: 100%;background-color: var(--inactive-color2);padding: 5px 10px 5px 10px">마트 알람 설정</span>
    </div>
    <div class="flex-row flex-wrap">
      <div class="col-9">
        <div class="flex-col" style="padding: 20px">
        <span class="active-font-18-b">마케팅 정보 수신</span>
        <span class="active-font-16" style="color:var(--prime-font-inactive); font-size:10pt;">오전 행사 소식 및 쿠폰 수신</span>
        </div>
      </div>
      <div class="col-3 grow-center">
        <ui_switch
            v-model="pushAgreeValue"
            :disabled="isSwitchDisabled"
            @change="handleSwitchChange" />
      </div>
    </div>
    <!--
    <div class="flex-row flex-wrap" style="margin-top: 30px">
      <span class="active-font-16" style="width: 100%;background-color: var(--inactive-color2);padding: 5px 10px 5px 10px">포인트몰 알람 설정</span>
    </div>
    <div class="flex-row flex-wrap">
      <div class="col-9">
        <div class="flex-col" style="padding: 20px">
          <span class="active-font-18-b">마케팅 정보 수신</span>
          <span class="active-font-16" style="color:var(--prime-font-inactive); font-size:10pt;">포인트몰 신상품 및 쿠폰 수신</span>
        </div>
      </div>
      <div class="col-3 grow-center">
        <ui_switch
            v-model="pointpushAgreeValue"
            :disabled="isSwitchDisabled"
            @change="handleSwitchChange_2" />
      </div>
    </div>
    -->
    <div class="flex-row flex-wrap" style="margin-top: 40px">
      <div style="width: 100%" class="grow-center">
        <span @click="askalarmsettingk" class="active-btn" style="padding: 10px 30px 10px 30px">알람 설정</span>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      isLoading:false,
      pushAgreeValue: false, // 스위치의 기본 상태
      isSwitchDisabled: false, // 스위치 비활성화 여부
      pointpushAgreeValue: false, // 스위치의 기본 상태
      resvei:"",
    }
  },
  created() {
    this.fetchProducts();
    this.$store.dispatch('Showheaderfooter', {
      Title: '알람 설정',
      showBack: true,
      showAlarm: false,
      showCart: false,
      showedit: false,
      showsheader: true,
      showFooter: false,
    });
  },

  methods: {
    askalarmk() {
      if (window.askalarm && typeof window.askalarm.postMessage === 'function') {
        window.askalarm.postMessage('');
      }
    },
    openaskalarm(){
      this.$modal.alarmPrompt().then((result) => {
        if (result.isConfirmed) {
          this.askalarmsettingk();
        }
      });
    },
    handleSwitchChange(push_agree) {
      console.log(push_agree);
      if (push_agree == false){
        this.askalarmk();
      }
      let ppsu = "";
      if (push_agree == true){
        ppsu = "YES";
      }else{
        ppsu = "NO";
      }

      let formData = new FormData();
      formData.append('mode', 'change_state_push');
      formData.append('martid', this.$store.state.martid);
      formData.append('token', this.$store.state.token);
      formData.append('push_agree', ppsu);
      this.$axios.post(this.$store.state.apiEndpoint, formData)
          .then(response => {
            this.$modal.successToast(response.data.message);

            //웹앱쪽으로 통신을 넣어서 알람이 막혀있으면 창을 띠우고 설정으로 넘어가도록하게한다.
          })
          .catch(error => {
            this.$modal.errorToast(error);
          }).finally(() => {
      });

    },
    handleSwitchChange_2(push_agree) {
      let formData = new FormData();
      formData.append('mode', 'change_state_push');
      formData.append('martid', this.$store.state.martid);
      formData.append('token', this.$store.state.token);
      formData.append('push_agree', push_agree);
      this.$axios.post(this.$store.state.apiEndpoint, formData)
          .then(response => {
            this.$modal.successToast(response.data.message);

            //웹앱쪽으로 통신을 넣어서 알람이 막혀있으면 창을 띠우고 설정으로 넘어가도록하게한다.
          })
          .catch(error => {
            this.$modal.errorToast(error);
          }).finally(() => {
      });
    },
    fetchProducts() {
      let formData = new FormData();
      formData.append('mode', 'get_push_setting');
      formData.append('martid', this.$store.state.martid);
      formData.append('token', this.$store.state.token);
      this.$axios.post(this.$store.state.apiEndpoint, formData)
          .then(response => {
            if (response.data.mart_push == "YES"){
              this.pushAgreeValue = true;
            }else{
              this.pushAgreeValue = false;
            }

            this.pointpushAgreeValue = response.data.mart_push;
          })
          .catch(error => {
            this.$modal.errorToast(error);
          }).finally(() => {
        this.isLoading = false;
      });
    },
    askalarmsettingk() {
      if (window.askalarmsetting && typeof window.askalarmsetting.postMessage === 'function') {
        window.askalarmsetting.postMessage('');
      }
    },
  },
  mounted() {
    window.openaskalarm = this.openaskalarm.bind(this);
  },
}
</script>


<style scoped>

</style>
