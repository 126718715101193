<template>

  <div v-if="isLoading" class="loading-div">
    <img class="loading_gif" src="@/assets/img/loading.gif" alt="로딩 중..." />
  </div>
  <div class="flex-row flex-wrap" >
    <div class="col-12" style="padding: 20px">
      <img style="width: 100%;height: auto;" @error="handleImageError(index)" :src="products.it_img1" />
    </div>
    <div class="col-12 grow-right" style="padding: 0 20px 0 20px"><span style="padding-left: 20px" class="active-font-18-b">{{products.it_name}}</span></div>

    <div class="col-12 grow-right" style="padding: 6px 20px 0 20px"><span style="padding-left: 20px" class="inactive-font-16">{{products.it_size}}</span></div>
    <div style="width:100%;height: 10px;background-color: #F6F6F6;margin: 20px 0px 0px 0px" >
    </div>
    <div class="col-12 grow-right" style="padding-right: 20px;padding-top: 20px"><span  class="inactive-font-14">{{products.ca_id}} / {{products.ca_id2}}</span></div>

    <div class="col-12 grow-right" v-if="products.out_price > 0" style="display:flex;align-items: center;padding-right: 20px;padding-top: 10px">
      <span style="text-decoration: line-through var(--active-button-color);" class="inactive-font-16">{{$formatPrice(products.out_price)}}원</span>
        <img src="@/assets/img/svg/coupon_arrow.svg" style="padding: 0px 5px 0px 5px"/>
        <span class="active-font-18-b">
        {{$formatPrice(products.sale_out_price)}}원
      </span>
    </div>
    <div class="col-12 grow-right" v-else style="padding-right: 20px;padding-top: 10px">
      <span class="active-font-18-b">{{$formatPrice(products.sale_out_price)}}원</span>
    </div>


      <div v-html="products.it_html"></div>
  </div>

  <div class="bottom_btn">
    <div v-if="openoption" class="flex-row flex-wrap" style="padding: 10px 0px 0px 0px;border-top: 1px solid #b4b4b4; border-right: 1px solid #b4b4b4; border-left: 1px solid #b4b4b4; border-top-left-radius: 20px;border-top-right-radius: 20px;background-color: #fff;z-index: 400">
      <div class="col-12" style="padding-bottom: 5px" @click="toggleoption"><img src="@/assets/img/svg/arrow-down.svg" /></div>
      <div class="col-12 flex-col" style="padding: 5px 20px 0px 20px;">
        <div style="background-color: #fbfbfb;padding: 15px 10px 15px 10px;border: 1px solid #dcdcdc;">
          <div class="grow-left active-font-18-b"><span style="text-align:left">{{products.it_name}}</span><span style="padding-left: 10px" class="inactive-font-16">{{products.it_size}}</span></div>
          <div class="flex-row" style="padding-top: 10px">
          <div class="col-6 grow-left">
            <div class="grow-center"><img style="width: 28px;" src="@/assets/img/svg/minus_outline.svg" @click="minusitem" /></div>
            <div class="grow-center active-font-18-b " style="margin-left: 10px;margin-right: 10px;">{{qty}}</div>
            <div class="grow-center" style="margin-right: 10px"><img style="width: 28px" src="@/assets/img/svg/plus_outline.svg" @click="plusitem" /></div>
          </div>
            <div class="col-6 grow-right active-font-18-b" >{{$formatPrice(products.sale_out_price)}}<span class="active-font-12">원</span></div>
          </div>
        </div>
      </div>
      <div class="col-12 flex-col" style="padding: 0px 20px 10px 20px;">
        <div class="flex-row" style="border-right: 1px solid #d2d2d2;border-left: 1px solid #d2d2d2;border-bottom: 1px solid #d2d2d2;padding: 15px 10px 15px 10px;">
          <div class="col-6 grow-left active-font-16-b">총 수량 <span class="active-font-16-b" style="color:var(--prime-color);padding-right: 4px;padding-left: 4px">{{qty}}</span> 개</div>
          <div class="col-6 grow-right active-font-16-b" >총 금액 <span class="active-font-16-b" style="color:var(--prime-color);padding-left: 4px">{{$formatPrice(products.sale_out_price * qty)}}</span><span class="active-font-12">원</span></div>
        </div>
      </div>

      <div v-if="false" class="col-12 flex-col" style="padding-top: 20px;background-color:#fbfbfb ">
        <div class="flex-row">
          <div class="col-4 grow-left active-font-16-b">배송방법</div>
          <div class="col-8">택배</div>
        </div>
        <div class="flex-row">
          <div class="col-4 grow-left active-font-16-b">배송비 </div>
          <div class="col-8">2,500원, 3만원 이상 구매시 무료배송</div>
          <div class="col-4"></div>
          <div class="col-8">제주 추가 3,000원, 제주 외 도서지역 추가 3,000원</div>
        </div>
      </div>
    </div>
    <div class="flex-row flex-wrap grow-center" :class="openoption ? 'disgrid' : 'ingrid'" style="padding: 10px 10px 10px 10px;background-color: #fff;height: 50px;">
      <div class="col-6 flex-row flex-wrap" style="padding-right: 5px;height: 50px;">
      <span class="inactive-btn-a col-12 grow-center" @click="addcart(idx,products.out_price,products.sale_out_price,products.it_img1,products.it_name)">장바구니</span>
      </div>
      <div class="col-6 flex-row flex-wrap" style="padding-left: 5px;height: 50px;">
        <span class="active-btn-a col-12 grow-center" @click="buydirect(idx,products.out_price,products.sale_out_price,products.it_img1,products.it_name)">바로구매</span>
      </div>
    </div>
  </div>

</template>


<script>

export default {
  created() {
    this.idx = this.$route.params.idx;
    this.ww = this.$route.params.w;
    this.fetchProducts(this.idx,this.ww);
    this.$store.dispatch('Showheaderfooter', {
      Title: '상품 보기',
      showBack: true,
      showAlarm: false,
      showCart: false,
      showsheader: true,
      showFooter: false,
      enableScrollHeader: true,
    });
  },
  data() {
    return {
      products: {},
      isLoading:true,
      idx:"",
      ww:"",
      openoption:false,
      qty:1,
      total_price:0,
    }
  },
  methods: {
    plusitem(){
      this.qty++;
    },
    minusitem() {
      if (this.qty > 1) {
        this.qty--;
      }
    },
    toggleoption(){
      this.openoption = !this.openoption;
    },
    addcart(idx,ct_out_price,ct_price,it_img1,it_name){
      if (this.openoption == false){
        this.openoption = true;
        return;
      }
      this.$addcart(idx,this.ww,ct_out_price,ct_price,it_img1,it_name,this.qty);
    },
    buydirect(idx,ct_out_price,ct_price,it_img1,it_name){
      if (this.openoption == false){
        this.openoption = true;
        return;
      }
      this.$addcart(idx,this.ww,ct_out_price,ct_price,it_img1,it_name,this.qty);
      this.$router.push('/cart');
    },
    fetchProducts(idx,w) {
      let formData = new FormData();
      formData.append('mode', 'prd_detail');
      formData.append('martid', this.$store.state.martid);
      formData.append('token', this.$store.state.token);
      formData.append('lf', this.$store.state.lf);
      formData.append('w', w);
      formData.append('idx', idx);

      this.$axios.post(this.$store.state.apiEndpoint, formData)
          .then(response => {
            this.products = response.data.data;
          })
          .catch(error => {
            this.$modal.errorToast(error);
          }).finally(() => {
        this.isLoading = false; // 요청이 끝나면 로딩 상태를 false로 설정
      });
    },
    handleImageError() {
      this.products.it_img1 = require('@/assets/img/no_img.gif');
    },
  },
}
</script>

<style scoped>

.ingrid{
  border-top: 1px solid #b4b4b4;
}
.disgrid{
  border-bottom: 1px solid #b4b4b4;
  border-left: 1px solid #b4b4b4;
  border-right: 1px solid #b4b4b4;
}

.active-btn-a{
  border: 1px solid var(--prime-color);
  border-radius: 6px;
  background-color: var(--prime-color-pt);
  font-size: 16px;
  font-family: 'Noto Sans CJK KR';
  color: var(--prime-color);
}

.inactive-btn-a{
  border: 1px solid var(--inactive-color);
  border-radius: 6px;
  font-size: 16px;
  font-family: 'Noto Sans CJK KR';
  color: var(--inactive-color);
}


.bottom_btn{

  position: fixed; /* 절대 위치 */
  bottom: 0px; /* 하단 여백 조정 */
  left: 0;
  z-index: 10; /* 다른 요소 위에 표시되도록 설정 */
  width: 100%; /* 스티커 너비 조정 */
  text-align: center; /* 텍스트 중앙 정렬 */
}
</style>
