<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 28">
    <g id="ico_tab_2" >
      <g id="그룹_70" data-name="그룹 70">
        <g id="그룹_69" data-name="그룹 69">
          <path id="패스_85" data-name="패스 85" d="M198.784,38.793h-1.2V38a2,2,0,1,0-3.991,0v.8h-1.2a.4.4,0,0,0-.4.4v5.587a1.2,1.2,0,0,0,1.2,1.2h4.789a1.2,1.2,0,0,0,1.2-1.2V39.192a.4.4,0,0,0-.4-.4m-4.39-.8a1.2,1.2,0,1,1,2.394,0v.8h-2.394Zm-.8,1.6v.8h.8v-.8h2.394v.8h.8v-.8h.8v3.991H192.8V39.592Zm4.39,5.587H193.2a.4.4,0,0,1-.4-.4v-.4h5.587v.4a.4.4,0,0,1-.4.4" transform="translate(-179.23 -34.577)" fill="#b4b4b4"/>
          <path id="패스_86" data-name="패스 86" d="M145.2,12a7.183,7.183,0,0,0-5.67,11.591l-.69,1.836a.4.4,0,0,0,.514.514l1.945-.73A7.183,7.183,0,1,0,145.2,12m0,13.568a6.347,6.347,0,0,1-3.622-1.13.4.4,0,0,0-.367-.045l-1.315.493.461-1.228a.4.4,0,0,0-.067-.4,6.385,6.385,0,1,1,4.91,2.305" transform="translate(-128.844 -12.173)" :fill="currentColor"/>
        </g>
      </g>
      <g id="그룹_72" data-name="그룹 72">
        <g id="그룹_71" data-name="그룹 71">
          <path id="패스_87" data-name="패스 87" d="M114.062,26.672a.4.4,0,0,0-.4-.4h-2.607a.4.4,0,0,0,0,.8h2.607a.4.4,0,0,0,.4-.4" :fill="currentColor"/>
          <path id="패스_88" data-name="패스 88" d="M39.869,16.013v7.8a.933.933,0,0,1-.932.932H27.73a.933.933,0,0,1-.932-.932V1.73A.933.933,0,0,1,27.73.8h8.3V0h-8.3A1.732,1.732,0,0,0,26,1.73V23.809a1.732,1.732,0,0,0,1.73,1.731H38.936a1.732,1.732,0,0,0,1.731-1.731v-7.8Z" transform="translate(-24.271)" :fill="currentColor"/>
          <path id="패스_89" data-name="패스 89" d="M117.42,324.25a1.342,1.342,0,1,0,1.342,1.342,1.344,1.344,0,0,0-1.342-1.342m0,1.886a.544.544,0,1,1,.544-.544.545.545,0,0,1-.544.544" transform="translate(-108.358 -302.684)" :fill="currentColor"/>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: ['isActive'],
  computed: {
    currentColor() {
      return this.isActive ? 'var(--active-button-color)' : 'var(--inactive-color)';
    },
  }
};
</script>