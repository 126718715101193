<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 28 28">
    <g id="ico_cart" transform="translate(-20 -30)">
      <g id="ico_cart-2" data-name="ico_cart" transform="translate(22 38.25)">
        <g id="그룹_14" data-name="그룹 14" transform="translate(0)">
          <g id="그룹_13" data-name="그룹 13" clip-path="url(#clip-path)">
            <path id="패스_12" data-name="패스 12" d="M97.938,322.152a2.142,2.142,0,1,0,2.142,2.142,2.142,2.142,0,0,0-2.142-2.142m0,3.5a1.355,1.355,0,1,1,1.354-1.355,1.355,1.355,0,0,1-1.354,1.355" transform="translate(-92.018 -309.445)" fill="#707070" stroke="#707070" stroke-width="0.4"/>
            <path id="패스_13" data-name="패스 13" d="M17.6,4.423H3.692L3.408,2.836A2.367,2.367,0,0,0,1.49.922L.463.741a.394.394,0,1,0-.138.776L1.356,1.7A1.575,1.575,0,0,1,2.632,2.974l1.394,7.864a1.965,1.965,0,0,0,1.937,1.626h8.269a1.964,1.964,0,0,0,1.886-1.4L17.97,4.931a.412.412,0,0,0-.059-.35.4.4,0,0,0-.315-.158m-2.229,6.411a1.173,1.173,0,0,1-1.13.839H5.968A1.175,1.175,0,0,1,4.806,10.7L3.833,5.207H17.068Z" transform="translate(0 -0.706)" fill="#707070" stroke="#707070" stroke-width="0.4"/>
          </g>
        </g>
        <path id="패스_14" data-name="패스 14" d="M307.59,322.152a2.142,2.142,0,1,0,2.142,2.142,2.142,2.142,0,0,0-2.142-2.142m0,3.5a1.355,1.355,0,1,1,1.354-1.355,1.355,1.355,0,0,1-1.354,1.355" transform="translate(-293.4 -309.445)" fill="#707070" stroke="#707070" stroke-width="0.4"/>
      </g>
    </g>
    <g v-if="cartQuantity > 0" transform="translate(12 0)">
      <circle cx="8" cy="8" r="8" fill="var(--second-color)" />
      <text x="8" y="12" font-family="Arial" font-size="12" text-anchor="middle" fill="#ffffff">{{ cartQuantity }}</text>
    </g>
  </svg>

</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      cartQuantity: state => state.Cart_Quantity
    })
  },
};
</script>