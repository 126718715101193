<template>
  <div v-if="isLoading" class="loading-div">
    <img class="loading_gif" src="@/assets/img/loading.gif" alt="로딩 중..." />
  </div>
  <div v-else style="height: calc(100vh - 70px);">

    <div class="flex-row" style="padding: 12px 20px 10px 20px;background-color: #fff;">
      <div class="input_box grow-left col-12" style="height: 45px; padding-left: 10px; padding-right: 10px;background-color: #E9E9E9;">
        <img src="@/assets/img/svg/search.svg" @click="search_icon" />
        <input type="search"
               class="transparent-input active-font-16" style="padding-left: 5px;"
               placeholder="도로명, 건물명으로 검색" @keyup.enter="search_icon" v-model="keyword" >
      </div>
    </div>
    <div class="flex-row grow-left" style="padding: 12px 20px 10px 20px;background-color: #fff;" @click="get_gps_local">
      <img src="@/assets/img/svg/gps.svg" />
      <span class="active-font-14-b" style="padding-left: 5px;color:var(--second-color);">
        현재 위치로 설정
      </span>
    </div>
    <div class="flex-row flex-wrap" style="background-color:#F9F9F9;width:100%;overflow:auto;height: calc(100vh - 179px);overflow-y: auto;">
      <div v-if="documents.length == 0" class="col-12" style="padding: 20px">
        <span>Tip<br>아래와 같은 조합으로 검색해주세요<br><br>도로명 + 건물번호<br>예) 판교역 235, 제주 첨단로 242<br><br>지역명 + 번지<br>예) 삼평동 681, 제주 영평동 2181<br><br>건물명, 아파트명<br>예) 분당 주공, 연수동 주공3차</span>
      </div>
      <div v-else class="col-12" style="padding: 20px">
        <div class="flex-row flex-wrap" v-for="(dc,index) in documents" :key="index" style="padding-top: 10px;padding-bottom: 10px;border-bottom: 1px solid #ececec;" >
          <div v-if="dc.road_address == null" class="flex-row flex-wrap col-12" @click="adrdetail(dc.address.address_name,dc.address.address_name)">
            <div class="col-12 active-font-16">
              {{dc.address.address_name}}
            </div>
            <div class="col-12 inactive-font-16">
              {{dc.address.address_name}}
            </div>
          </div>
          <div v-else class="flex-row flex-wrap col-12 " @click="adrdetail(dc.road_address.region_2depth_name + ' ' + dc.road_address.road_name,dc.address_name)">
            <div class="col-12 active-font-16">
              {{dc.road_address.region_2depth_name}} {{dc.road_address.road_name}}
            </div>
            <div class="col-12 inactive-font-16">
              <span class="band">도로명</span> {{dc.address_name}}
            </div>
          </div>
        </div>
      </div>
    </div>



  </div>
</template>

<script>


export default {
  data() {
    return {
      documents: [],
      meta: [],
      isLoading:true,
      keyword:"",
      page:1,
    }
  },
  created() {
    this.fetchProducts();
    this.$store.dispatch('Showheaderfooter', {
      Title: '내 주소 등록',
      showBack: true,
      showAlarm: false,
      showCart: false,
      showedit: false,
      showsheader: true,
      showFooter: false,
    });
  },
  methods: {
    get_gps_local(){
      this.$router.push('/user/adr/local');
    },
    adrdetail(adr1,adr2){
      this.$router.push({ name: 'adr_detail_n', params: {adr1: adr1, adr2: adr2}});
    },
    search_icon(){
      this.page = 1;
      this.searchAddress();
    },
    async searchAddress() {
      const url = "https://dapi.kakao.com/v2/local/search/address.json?page="+ this.page +"&size=20&analyze_type=similar&query=" + this.keyword;
      const userHeader = {
        headers: {
          "Content-type": "application/json",
          "Accept": "application/json",
          "Authorization": "KakaoAK 407e7648adf9101b8361bbff9d2f7cad"
        }
      };

      try {
        const response = await this.$axios.get(url, userHeader);
        this.documents = response.data.documents;
        this.meta = response.data.meta;
        console.log(this.documents);
        console.log(this.meta);
      } catch (error) {
        console.error(error);
      }
    },
    fetchProducts() {
      let formData = new FormData();
      formData.append('mode', 'get_useradr_list');
      formData.append('martid', this.$store.state.martid);
      formData.append('token', this.$store.state.token);
      this.$axios.post(this.$store.state.apiEndpoint, formData)
          .then(response => {
            this.products = response.data.data;
            this.products_last = response.data.last;
          })
          .catch(error => {
            this.$modal.errorToast(error);
          }).finally(() => {
        this.isLoading = false; // 요청이 끝나면 로딩 상태를 false로 설정
      });
    },
  }
}
</script>

<style scoped>
.band{
  background-color: #ababab;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  padding: 4px 4px;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}


.input_box{
  border: 1px solid #E9E9E9;
  border-radius: 6px;
}
.transparent-input {
  background: transparent; /* 배경색을 투명하게 설정 */
  border: none; /* 테두리 제거 */
  outline: none; /* 입력 테두리 제거 (선택 시 나타나는 테두리) */
  width: 100%; /* 너비를 100%로 설정하여 부모 요소에 맞게 확장 */
  height: 40px; /* 원하는 높이로 설정 */
  padding: 0; /* 내부 여백 제거 */
  font-size: 14px; /* 원하는 글꼴 크기 설정 */
}
</style>
