<template>
  <div class="flex-row flex-wrap" >
    <div class="col-12" style="padding: 20px 30px 5px 30px;">
      <span class="active-font-18-b">로그인&가입을 위해</span>
    </div>
    <div class="col-12" style="padding: 0 30px 5px 30px">
      <span class="active-font-18-b">휴대폰 인증이 필요합니다.</span>
    </div>
    <div class="col-12" style="padding: 0 30px 5px 30px">
      <span class="inactive-font-14">인증번호가 도착하지 않으면,<br> 070-4284-0251 번호 차단을 해제해주세요.</span>
    </div>

      <div class="col-12 flex-row" style="padding:20px 20px 20px 20px;">
        <div class="input_box flex-row" :class="{ 'col-10': sendActive, 'col-8': !sendActive }" style="height: 45px; padding-left: 20px; padding-right: 20px;border-radius: 15px">
          <input type="tel"
                 class="transparent-input col-11" :class="{ 'inactive-font-14': sendActive, ' active-font-14': !sendActive }"
                 placeholder="휴대폰번호를 입력해주세요."
                 v-model="phoneNumber" @input="checkLength" :disabled="sendActive">
          <button v-if="phoneNumber && !sendActive" class="clear-button col-1">X</button>
        </div>
        <div class="grow-center" :class="{ 'col-2': sendActive, 'col-4': !sendActive }" style="padding-left:10px;">
         <span v-if="!sendActive" class="grow-center" style="width: 100%;height: 100%" :class="{ 'active-btn': isButtonActive, 'inactive-btn': !isButtonActive }" c @click="activateButton">인증번호 받기</span>
          <svg v-else width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_iconCarrier"> <path d="M4 12.6111L8.92308 17.5L20 6.5" stroke="#3FB1B1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
        </div>
      </div>
    <div v-if="sendActive" class="col-12 flex-row flex-wrap" style="padding:0px 30px 0px 30px;">
      <div class="col-12">
        <span class="active-font-18" style="color:var(--second-color)">인증번호</span>
      </div>
    </div>
    <div v-if="sendActive" class="col-12 flex-row flex-wrap" style="padding:0px 20px 20px 20px;">
      <div class="col-12 flex-row flex-wrap " style="height: 45px; padding-left: 10px; padding-right: 10px;">
        <input type="number"
               class="transparent-input inactive-font-14 col-9 in_input_box"
               placeholder="발송된 인증번호 입력" v-model="smscode" @input="checksmscode">
        <div class="col-3 grow-center">
          <span style="color: #f84d4e;">{{ countdown }}초</span>
        </div>
      </div>
      <div v-if="errortext" class="col-12 flex-wrap inactive-font-12" style="padding:5px 10px 10px 10px;color: #f84d4e;">
        <span>{{errortext}}</span>
      </div>
      <div v-if="retrybtn" class="col-12">
        <span class="active-btn active-font-16-b grow-center" style="width: 100%;height: 40px;background-color: #fff;">인증번호 다시 받기</span>
      </div>
    </div>
    <div style="width: 100%;height: 10px;background-color: #F6F6F6;border-top: 1px solid #d9d9d9;">
    </div>
    <div class="col-12" style="padding: 30px 30px 5px 30px;">
      <span class="active-font-16-b">인증번호 입력 후 로그인,가입하시면</span>
    </div>
    <div class="col-12" style="padding: 0 30px 20px 30px">
      <span class="active-font-16-b">하단 약관에 동의한것으로 간주됩니다.</span>
    </div>
    <div class="col-12" style="padding: 0 30px 0px 30px;align-content: center;display: flex" @click="toggleAllPolicies()">
      <ui_policy_check :isActive="policy_all"  style="padding-right: 8px"/><span class="active-font-16-b">전체 동의</span>
    </div>
    <div class="col-12" style="padding: 10px 10px 10px 10px;align-content: center;display: flex">
      <div style="width: 100%;height: 1px;background-color: #F6F6F6;border-top: 1px solid #d9d9d9;">
      </div>
    </div>
    <div class="col-12" style="padding: 0 20px 10px 30px;align-content: center;display: flex;justify-content: space-between;">
      <div style="align-content: center;display: flex;" @click="togglePolicy(1)"><ui_policy_check :isActive="policy_1" style="padding-right: 8px;"/><span class="active-font-16">스마트마트 이용약관 동의 (필수)</span></div><img src="@/assets/img/svg/rightarrow.svg" @click="policy_push('이용약관')"/>
    </div>
    <div class="col-12" style="padding: 0 20px 10px 30px;align-content: center;display: flex;justify-content: space-between;">
      <div  style="align-content: center;display: flex;" @click="togglePolicy(2)"><ui_policy_check :isActive="policy_2" style="padding-right: 8px"/><span class="active-font-16">전자금융거래 이용약관 동의 (필수)</span></div><img src="@/assets/img/svg/rightarrow.svg" @click="policy_push('전자금융거래약관')"/>
    </div>
    <div class="col-12" style="padding: 0 20px 10px 30px;align-content: center;display: flex;justify-content: space-between;">
      <div  style="align-content: center;display: flex;" @click="togglePolicy(3)"><ui_policy_check :isActive="policy_3" style="padding-right: 8px"/><span class="active-font-16">개인정보 수입이용 동의 (필수)</span></div><img src="@/assets/img/svg/rightarrow.svg" @click="policy_push('개인정보')"/>
    </div>
    <div class="col-12" style="padding: 0 20px 10px 30px;align-content: center;display: flex;justify-content: space-between;">
      <div  style="align-content: center;display: flex;" @click="togglePolicy(4)"><ui_policy_check :isActive="policy_4" style="padding-right: 8px"/><span class="active-font-16">마케팅 정보 수신동의 (선택)</span></div>
    </div>



  </div>
</template>

<script>
import ui_policy_check from '@/components/ui_icon/ui_policy_check.vue';

export default {

  name: 'login_join',
  components:{
    ui_policy_check,
  },
  created() {
    this.$store.dispatch('Showheaderfooter', {
      Title: '로그인 & 가입',
      showBack: true,
      showAlarm: false,
      showCart: false,
      showsheader: true,
      showFooter: false,
      enableScrollHeader: true,
    });
  },
  data() {
    return {
      phoneNumber: '',
      smscode:'',
      isButtonActive: false,
      sendActive: false,
      countdown: 0,
      timer: null,
      policy_all:false,
      policy_1:false,
      policy_2:false,
      policy_3:false,
      policy_4:false,
      retrybtn:false,
      errortext:"",
      firetoken:""
    }
  },
  methods: {
    policy_push(policy){
      this.$router.push({name: 'policy', params: {policy: policy}});
    },
    togglePolicy(policyNumber) {
      if (this.sendActive == true){
        return;
      }
      this['policy_' + policyNumber] = !this['policy_' + policyNumber];
      if (policyNumber == 4 && this['policy_' + policyNumber] == true){
        this.askalarmk();
      }
      this.updatePolicyAllState();
    },
    toggleAllPolicies() {
      if (this.sendActive == true){
        return;
      }
      this.policy_all = !this.policy_all;

      if (this.policy_all) {
        this.policy_1 = true;
        this.policy_2 = true;
        this.policy_3 = true;
        this.policy_4 = true;
        this.askalarmk();
      } else {
        this.policy_1 = false;
        this.policy_2 = false;
        this.policy_3 = false;
        this.policy_4 = false;
      }
    },
    updatePolicyAllState() {
      // policy_1부터 policy_4까지 모두 true인 경우 policy_all을 true로 설정합니다.
      this.policy_all = this.policy_1 && this.policy_2 && this.policy_3 && this.policy_4;
    },
    activateButton() {
      if (this.isButtonActive && this.sendActive == false) {

        if (this.policy_1 == true && this.policy_2 == true && this.policy_3 == true){
          console.log("약관 승인");
        }else{
          this.$modal.midToast("하단 필수 약관에 동의해주세요.");
          return;
        }
        this.sendActive = true;
        this.sendsms();
        this.startCountdown();
      }else{
        return;
      }
    },
    startCountdown: function() {
      this.countdown = 180; // 시작 숫자를 설정할 수 있습니다.
      this.timer = setInterval(this.updateCountdown, 1000);
    },
    updateCountdown: function() {
      if (this.countdown > 0) {
        this.countdown--;
      } else if (this.countdown == 0) {
        clearInterval(this.timer);
        this.retrybtn = true;
      }
    },
    sendsms() {
      let formData = new FormData();
      formData.append('mode', 'sendsms');
      formData.append('martid', this.$store.state.martid);
      formData.append('send_to', this.phoneNumber);
      this.$axios.post(this.$store.state.apiEndpoint, formData)
          .then(response => {
            if(response.data.status == "OK"){
              this.$modal.midToast(response.data.message,'info');
            }else{
              this.$modal.midToast(response.data.message);
            }
          })
          .catch(error => {
            this.$modal.errorToast(error);
          }).finally(() => {
      });
    },
    nmok(authno) {
      let formData = new FormData();
      formData.append('mode', 'nmok');
      formData.append('martid', this.$store.state.martid);
      formData.append('send_to', this.phoneNumber);
      formData.append('authno', authno);
      formData.append('policy1', this.policy_1 ? 'YES' : 'NO');
      formData.append('policy2', this.policy_2 ? 'YES' : 'NO');
      formData.append('policy3', this.policy_3 ? 'YES' : 'NO');
      formData.append('policy4', this.policy_4 ? 'YES' : 'NO');
      formData.append('fire_token', this.firetoken);
      formData.append('app_type', "webapp"); //웹으로 들어올때랑 웹앱으로 들어올때랑 나눠서 처리해야됨

      this.$axios.post(this.$store.state.apiEndpoint, formData)
          .then(response => {
            if(response.data.status == "OK"){
              const newToken = response.data.token;
              localStorage.setItem('token', newToken);
              this.$store.commit('SET_LOGIN_FLAG', 1);
              this.$store.commit('SET_LOGIN_TOKEN', newToken);
              this.$nextTick(() => {
                let cartData = JSON.parse(localStorage.getItem('leaf_cart')) || [];
                let promises = cartData.map(item => {
                  return this.$addcart(item.idx, item.w, item.ct_out_price, item.ct_price, item.it_img1, item.it_name, item.ct_qty);
                });

                Promise.all(promises).then(() => {
                  localStorage.setItem('leaf_cart', JSON.stringify([]));
                  this.$router.go(-1);
                });
              });
            }else{
              this.errortext = response.data.message;
            }
          })
          .catch(error => {
            this.$modal.errorToast(error);
          }).finally(() => {
      });
    },
    askfiretokenfu() {
      if (window.askfiretoken && typeof window.askfiretoken.postMessage === 'function') {
        window.askfiretoken.postMessage('');
      }
    },
    askalarmk() {
      if (window.askalarm && typeof window.askalarm.postMessage === 'function') {
        window.askalarm.postMessage('');
      }
    },
    askalarmsettingk() {
      if (window.askalarmsetting && typeof window.askalarmsetting.postMessage === 'function') {
        window.askalarmsetting.postMessage('');
      }
    },
    returngetToken(token){
      this.firetoken = token;
    },
    openaskalarm(){
      this.$modal.alarmPrompt().then((result) => {
        if (result.isConfirmed) {
          this.askalarmsettingk();
        }
      });
    }
  },
  watch: {
    smscode(newVal) {
      const valueAsString = String(newVal);
      if (valueAsString.length > 3) {
        this.nmok(valueAsString);
        this.smscode = "";
      }else{
        this.errortext = "";
        this.smscode = valueAsString.replace(/[^0-9]+/g, '').slice(0, 4);
      }
    },
    phoneNumber(newVal) {
      // newVal을 문자열로 강제 변환합니다.
      const valueAsString = String(newVal);
      // 문자열에서 숫자가 아닌 값을 제거합니다.
      this.phoneNumber = valueAsString.replace(/[^0-9]+/g, '').slice(0, 11);

      if (this.phoneNumber.length > 9){
        this.isButtonActive = true;
      }else{
        this.isButtonActive = false;
      }
    },
  },
  mounted() {
    window.returngetToken = this.returngetToken.bind(this);
    window.openaskalarm = this.openaskalarm.bind(this);
    this.askfiretokenfu();
  },
}
</script>


<style scoped>
.iinactive-btn{
  border: 1px solid var(--inactive-color);
  border-radius: 6px;
  font-size: 14px;
  font-family: 'Noto Sans CJK KR Bold';
  color: var(--inactive-color);
}
.aactive-btn{
  border: 1px solid var(--prime-color);
  border-radius: 6px;
  background-color: var(--prime-color-pt);
  font-size: 14px;
  font-family: 'Noto Sans CJK KR';
  color: var(--prime-color);
}

.input_box{
  border: 1px solid #E9E9E9;
  border-radius: 6px;
}


.in_input_box{
  border-bottom: 1px solid #E9E9E9;
}
</style>
