<template>


  <div style="padding:10px 20px 20px 20px;">
    <ui_inputtext placeholder="검색어를 입력해주세요." :icon_1=true type="search" v-model="search_text" @searchkey="searchkey" @keyup.enter="searchkey"/>
  </div>

  <div v-if="products.length == 0 && isLoading == false" class="nodata">
      <img class="no_list" src="@/assets/img/svg/big_cart.svg" />
      <span class="no_list_text">검색된 상품이 없습니다.</span>
  </div>
  <div v-else v-for="(product, index) in products" :key="product.prd_idx">
    <div class="flex-row" @click="prd_result(product.prd_idx,product.w)">
      <div class="col-3" style="padding: 8px;"><img style="width: 100%;height: auto;" @error="handleImageError(index)" :src="product.prd_img" /></div>
      <div class="col-9" style=" display: flex;flex-direction: column;justify-content: center;">
        <div><span class="active-font-16" style="padding-left: 10px">{{product.prd_name}}</span></div>
        <div class="active-font-18-b" style="padding-left: 10px">{{$formatPrice(product.prd_price)}}원</div>
      </div>
    </div>
    <div v-if="products.length-1 > index" style="width: calc(100% - 20px);height: 1px;background-color: #F6F6F6;margin-left: 10px;">
    </div>
  </div>

  <div v-if="isLoading" class="loading-div">
    <img class="loading_gif" src="@/assets/img/loading.gif" alt="로딩 중..." />
  </div>
  <infi_scrolls @show="loadMoreItems"/>
  <div style="height: 100px"></div>
</template>


<script>

import infi_scrolls from "@/components/infi_scrolls";

export default {
  components:{
    infi_scrolls,
  },
  created() {
    this.search_text = this.$route.params.keyword;
    this.loadMoreItems();
    this.$store.dispatch('Showheaderfooter', {
      Title: '검색 결과',
      showBack: true,
      showAlarm: false,
      showCart: false,
      showsheader: true,
      showFooter: false,
      enableScrollHeader: false,
    });
  },
  data() {
    return {
      products: [],
      isLoading:true,
      search_text:"",
      item_start: 0,
      item_end: 1,
    }
  },
  methods: {
    searchkey() {
      if (this.search_text == ""){
        return;
      }
      // 로컬 스토리지에서 이전 데이터 가져오기
      let searchData = localStorage.getItem('SearchData');


      // 데이터가 없거나 타입이 배열이 아닌 경우 빈 배열로 초기화
      if (!searchData || !Array.isArray(JSON.parse(searchData))) {
        searchData = [];
      } else {
        searchData = JSON.parse(searchData);
      }


      const currentDate = new Date().toISOString().substr(0, 10); // 날짜를 포함한 ISO 형식

      // 새로운 검색어 데이터 추가
      searchData.unshift({
        date: currentDate, // 날짜 정보 추가
        keyword: this.search_text, // 검색어
      });

      // 로컬 스토리지에 업데이트된 데이터 저장
      localStorage.setItem('SearchData', JSON.stringify(searchData));

      this.local_search_key = searchData;
      this.products = [];
      this.loadMoreItems();
    },
    prd_result(idx,w){
      this.$router.push({ name: 'prddetail', params: { idx: idx ,w: w }});
    },
    loadMoreItems() {
      if (this.item_end <= this.item_start) {
        return;
      }
      this.isLoading = true;
      let formData = new FormData();
      formData.append('mode', 'get_search_list');
      formData.append('martid', this.$store.state.martid);
      formData.append('token', this.$store.state.token);
      formData.append('lf', this.$store.state.lf);
      formData.append('keyword', this.search_text);
      formData.append('item_start', this.item_start);
      this.$axios.post(this.$store.state.apiEndpoint, formData)
          .then(response => {
            this.products.push(...response.data.data);
            this.item_start = response.data.item_start;
            this.item_end = response.data.item_end;
          })
          .catch(error => {
            this.$modal.errorToast(error);
          }).finally(() => {
        this.isLoading = false; // 요청이 끝나면 로딩 상태를 false로 설정
      });
    },
    handleImageError(index) {
      // 이미지 로딩 오류가 발생했을 때 실행될 함수
      // 여기에서 대체 이미지를 설정하거나 다른 오류 처리를 수행할 수 있습니다.
      this.products[index].prd_img = require('@/assets/img/no_img.gif');
    },
  },
}
</script>

<style scoped>

</style>
