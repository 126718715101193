<script setup>
import { computed, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';

const store = useStore();

const handleVuexScroll = _.throttle(() => {
  store.dispatch('handleScroll');
}, 200);

onMounted(() => {
  window.addEventListener('scroll', handleVuexScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleVuexScroll);
});

const headerVisible = computed(() => store.state.headerVisible);
</script>

<template>
    <header class="header flex-row" :class="{ 'header-hidden': !headerVisible }">
      <div class="col-3 grow-left">
        <!--
        <button @click="toggleSidebar" class="transparent-button padding-l-30">
          <img src="@/assets/img/header_icon/line3.svg" alt="menu" />
        </button>
        -->
        <button v-if="showBackButton" @click="goBack" class="transparent-button" style="padding: 20px 0px 16px 20px;z-index: 500">
          <img src="@/assets/img/header_icon/back_icon.svg" alt="<" class="arrow-icon" />
        </button>
        <button v-if="showalarm" @click="goToAlarm" class="transparent-button" style="padding: 20px 0px 16px 20px;z-index: 500">
          <AlarmtIcon/>
        </button>
        <button v-if="showcoupon" @click="goTocoupon" class="transparent-button" style="padding: 15px 0px 14px 20px;z-index: 500">
          <CouponIcon/>
        </button>
      </div>
      <div class="col-6 grow-center">
        <span class="header-title" style="padding: 20px 0px 16px 0px;">{{ headerTitle }}</span>
      </div>
        <div class="col-3 grow-right">
          <span v-if="showedit" @click="goedit" class="transparent-button active-font-14-b" style="color:var(--second-color);padding: 20px 20px 16px 0;z-index: 500">
            편집
          </span>
          <button v-if="showcart" @click="goToCart" class="transparent-button " style="padding: 20px 20px 16px 0;z-index: 500">
            <CartIcon/>
          </button>

      </div>

    </header>
  <div style="height: 60px"></div>
</template>

<script>

import CartIcon from '@/components/header_svg/cart_icon.vue';
import AlarmtIcon from '@/components/header_svg/alarm_icon.vue';
import CouponIcon from '@/components/header_svg/coupon_icon.vue';
import {mapState} from "vuex";

export default {
  name: 'AppHeader',
  watch: {
    '$store.state.headercart': {
      immediate: true, // 컴포넌트가 렌더링될 때 바로 실행
      handler() {
        if (this.$store.state.headercart === true || this.$store.state.headeralarm === true || this.$store.state.headercoupon === true) {
          this.$store.dispatch('header_refreash');
        }
      }
    }
  },
  data() {
    return {
      showSidebar: false,
      scrollTimeout: null, // 스크롤 타임아웃
    };
  },
  methods: {
    goToAlarm() {
      this.$router.push('/alarm');
    },
    goTocoupon() {
      this.$router.push('/user/coupon');
    },
    goToCart() {
      this.$store.state.cart_add_order = "";
      this.$router.push('/cart');
    },
    goBack() {
      let pp = this.$route.path;
      if (pp === '/user/adr') {
        this.$router.push('/user');
        // eslint-disable-next-line no-dupe-else-if
      }else if (pp === '/cart/order/addr') {
        this.$router.replace('/cart/order');
      }else if (pp === '/cart/order/search/local') {
        this.$router.replace('/cart/order/search');
      }else if (pp === '/cart/order/search') {
        this.$router.replace('/cart/order');
      } else {
        this.$router.go(-1);
      }
    },
    goedit() {
      this.$router.push('/user/adr/search/edit');
    },
  },
  computed: {
    headerTitle() {
      return this.$store.state.headerTitle;
    },
    ...mapState({
      showBackButton: state => state.backbutton,
      showcart: state => state.headercart,
      showalarm: state => state.headeralarm,
      showcoupon: state => state.headercoupon,
      showedit: state => state.headeredit,
      headerVisible: state => state.headerVisible // 여기에 headerVisible 상태 추가
    })
  }
};
</script>

<style scoped>
.header-hidden {
  transform: translateY(-100%);
  transition: transform 0.3s;

}
.arrow-icon {
  height: 24px;
  width: auto;
}


.header {
  z-index: 1000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #ffffff; /* 원하는 배경색 설정 */
  border-bottom: 1px solid #ececec;
}


.header-title {
  color: #000;
  font-size: medium;
  font-family: 'Noto Sans CJK KR';
}


.transparent-button {
  background: none;
  border: none;
  outline: none;

  margin: 0;
  cursor: pointer;
}

</style>
