<template>
  <div class="container_footer">
    <div v-if="this.$store.state.Cart_total > 0 && bture" @click="goToCart" class="flex-row flex-wrap" style="height: 70px;background-color: rgba(12,215,207,0.9);  position: fixed;left: 0;bottom: 60px;width: 100%;align-content: center;">
      <span class="col-12" style="padding-left: 20px;">
        <span style="font-size: 16px;font-family: 'Noto Sans CJK KR';color:#FFF;"> 담은상품금액</span><span style="padding-left: 8px;font-size: 18px;font-family: 'Noto Sans CJK KR Bold';color:#FFF;"> {{$formatPrice(this.$store.state.Cart_total)}}원</span>
      </span>
      <span class="col-12" style="padding-left: 20px;font-size: 14px;font-family: 'Noto Sans CJK KR';color:#FFC35B;">
        지역별로 최소배송비용이 다릅니다.
      </span>
    </div>
    <footer class="fixed-footer">
      <div class="tab" v-for="(tab, index) in tabs" :key="index" @click="selectTab(tab)">
        <div class="icon-container">
          <component :is="tab.iconComponent" :is-active="tab.active" class="svg-icon"/>
        </div>
        <div class="label" :class="{ 'active-label': tab.active }">{{ tab.label }}</div>

      </div>
    </footer>
  </div>
</template>

<script>
import leaf_icon from '@/components/footer_svg/leaf_icon.vue';
import search_icon from '@/components/footer_svg/search_icon.vue';
import cate_icon from '@/components/footer_svg/cate_icon.vue';
import phone_icon from '@/components/footer_svg/phone_icon.vue';
import user_icon from '@/components/footer_svg/user_icon.vue';

export default {
  name: 'AppFooter', // multi-word 컴포넌트 이름
  components: {
    leaf_icon,
    search_icon,
    cate_icon,
    phone_icon,
    user_icon
    // 기타 필요한 아이콘 컴포넌트를 여기에 추가
  },
  created() {
    this.updateActiveTab();
  },
  mounted() {
    this.updateActiveTab();
  },
  watch: {
    // `$route`를 감시하여 라우트가 변경될 때마다 `updateActiveTab` 메서드를 호출합니다.
    '$route'() {
      this.updateActiveTab();
    }
  },
 data() {
    return {
      tabs: [
        { name: 'home', label: '전단지', active: true , iconComponent: 'leaf_icon', path: '/' },
        { name: 'search', label: '검색', active: false , iconComponent: 'search_icon' , path: '/search' },
        { name: 'category', label: '매장상품', active: false , iconComponent: 'cate_icon', path: '/cate' },
        { name: 'phone', label: '전화주문', active: false , iconComponent: 'phone_icon' },
        { name: 'user', label: '내 정보', active: false , iconComponent: 'user_icon', path: '/user' },
      ],
      bture:false,
    };
  },
  methods: {
    goToCart() {
      this.$router.push('/cart');
    },
    selectTab(selectedTab) {
      if (selectedTab.name === 'category' || selectedTab.name === 'home') {
        this.bture = true;
      }else{
        this.bture = false;
      }

      if (selectedTab.active && (selectedTab.name === 'category' || selectedTab.name === 'home')) {
        window.scrollTo(0, 0);
        return;
      }



      this.tabs.forEach(tab => { tab.active = false; });
      selectedTab.active = true;
      if (selectedTab.name === 'phone') {
        window.location.href = 'tel:01011111111';
      } else {
        this.$router.push(selectedTab.path);
      }
    },
    updateActiveTab() {
      // 현재 경로에 해당하는 탭을 찾아 `active` 상태를 true로 설정합니다.
      const currentPath = this.$route.path;
      this.tabs.forEach(tab => {
        tab.active = (tab.path === currentPath);
        if (tab.active == true &&(tab.name === 'category' || tab.name === 'home')){
          this.bture = true;
        }
      });

    },
  },
};
</script>

<style scoped>
.fixed-footer {
  border-top: 1px solid #ececec;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #ffffff; /* 원하는 배경색 설정 */
  color: white; /* 텍스트 색상 설정 */
  text-align: center;
  padding: 10px 0; /* 상하 패딩 설정 */
  display: flex; /* Flexbox를 사용합니다. */
  justify-content: space-around; /* 가운데 정렬 */
  align-items: center; /* 세로 방향으로 중앙 정렬 */
  gap: 10px; /* 탭 사이의 간격 설정 */
}

/* 페이지의 컨텐츠가 푸터에 가려지지 않도록 여백을 추가합니다. */
.container_footer {

  position: fixed;
  z-index: 1000;
  padding-bottom: 60px; /* 푸터의 높이보다 조금 더 크게 설정 */
}

.tab {
  /* 가로로 나란히 정렬되므로 text-align은 필요 없습니다. */
}

.icon-container {
  margin-bottom: 0px; /* 아이콘과 텍스트 사이 간격 */
}

.icon-container.active img {
  /* 활성화 상태일 때 스타일 */
}

.label {
  color: var(--inactive-color);
  font-size: small;
  font-family: 'Noto Sans CJK KR';
  /* 레이블 스타일 */
}
.active-label {
  color: var(--active-bottom-text-color);
}
</style>