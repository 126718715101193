// Modals.js
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';

const swalWithBootstrapButtons = Swal.mixin({
    /*
    customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
    },*/
    buttonsStyling: true
});

const errorToast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    /*
    didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
        toast.onclick = () => {
            Swal.resumeTimer(); // 토스트를 클릭하면 타이머를 재시작합니다.
        };
    }
    */
});

const successToast = Swal.mixin({
    toast: true,
    position: "bottom-start",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
});

const cartToast = Swal.mixin({
    toast: true,
    position: "top-right",
    showConfirmButton: false,
    timer: 1000,
});

const noticeToast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
});


const midToast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
});




export default {
    install: (app) => {
        app.config.globalProperties.$modal = {
            loginPrompt(text) {
                return swalWithBootstrapButtons.fire({
                    title: '로그인&가입',
                    text: text,
                    icon: 'info',
                    confirmButtonColor: "var(--prime-color)",
                    showCancelButton: true,
                    confirmButtonText: '로그인하기',
                    cancelButtonText: '닫기',
                    reverseButtons: true
                })
            },
            alarmPrompt() {
                return swalWithBootstrapButtons.fire({
                    title: '알람 설정',
                    text: "알람이 차단되어있습니다. 알람설정을 하시겠습니까?",
                    icon: 'info',
                    confirmButtonColor: "var(--prime-color)",
                    showCancelButton: true,
                    confirmButtonText: '설정으로',
                    cancelButtonText: '닫기',
                    reverseButtons: true
                })
            },
            couponModal() {
                return swalWithBootstrapButtons.fire({
                    text: "사용하기 버튼을 누른뒤에는 다시 사용할수없습니다.",
                    confirmButtonColor: "#E6592F",
                    showCancelButton: true,
                    confirmButtonText: '사용하기',
                    cancelButtonText: '닫기',
                    reverseButtons: true,
                })
            },
            // 두 번째 모달 메소드
            confirmUsageModal() {
                return swalWithBootstrapButtons.fire({
                    title:'매장에서만 사용하세요',
                    text: '사용하기 버튼을 누르면 더이상 사용할 수 없습니다. 쿠폰을 사용하시겠습니까?',
                    confirmButtonColor: "var(--prime-color)",
                    showCancelButton: true,
                    confirmButtonText: '사용하기',
                    cancelButtonText: '취소',
                    reverseButtons: true
                });
            },
            ordercencelModal(){
                return swalWithBootstrapButtons.fire({
                    title:'주문취소',
                    html: '주문을 취소하시겠습니까?<br>이미 매장에서 접수한경우 취소되지않을수있습니다.',
                    icon: 'warning',
                    confirmButtonColor: "var(--active-button-color)",
                    showCancelButton: true,
                    confirmButtonText: '취소하기',
                    cancelButtonText: ' 닫기 ',
                    reverseButtons: true
                });
            },
            orderdelModal(){
                return swalWithBootstrapButtons.fire({
                    title:'주문삭제',
                    html: '주문내역을 삭제하시겠습니까?',
                    icon: 'warning',
                    confirmButtonColor: "var(--active-button-color)",
                    showCancelButton: true,
                    confirmButtonText: '삭제하기',
                    cancelButtonText: '취소',
                    reverseButtons: true
                });
            },
            cartdelModal(){
                return swalWithBootstrapButtons.fire({
                    title:'상품삭제',
                    html: '상품을 카트에서 삭제하시겠습니까?',
                    icon: 'warning',
                    confirmButtonColor: "var(--active-button-color)",
                    showCancelButton: true,
                    confirmButtonText: '삭제하기',
                    cancelButtonText: '취소',
                    reverseButtons: true
                });
            },
            logoutModal(){
                return swalWithBootstrapButtons.fire({
                    title:'로그아웃',
                    html: '로그아웃 하시겠습니까?',
                    icon: 'warning',
                    confirmButtonColor: "var(--active-button-color)",
                    showCancelButton: true,
                    confirmButtonText: '로그아웃',
                    cancelButtonText: '닫기',
                });
            },
            errorToast(message) {
                return errorToast.fire({
                    icon: "error",
                    title: message || "오류가 발생했습니다"
                });
            },
            successToast(message) {
                return successToast.fire({
                    icon: "success",
                    title: message || "완료"
                });
            },
            cartToast() {
                return cartToast.fire({
                    icon: "success",
                    title: "상품이 카트에 담겼습니다."
                });
            },
            noticeToast(message) {
                return noticeToast.fire({
                    icon: "info",
                    title: message || "알려드립니다."
                });
            },
            midToast(message,icon='error') {
                return midToast.fire({
                    icon: icon,
                    title: message
                });
            }

            // 여기에 더 많은 모달 함수를 추가할 수 있습니다.
        }
    }
}
