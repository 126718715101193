<template>
  <div class="flex-row flex-wrap" >
    <div class="col-12" style="position: relative;text-align: left;padding-top: 40px">
      <img src="@/assets/img/cate_title.png" style="width: 100%;height: auto" />
    </div>

    <div class="category-scroll-container">
      <div class="category-item"  v-for="(product,index) in category_data" :key="index"
           @click="selectCategory(index,product)">
        <span :class="{'cate-active': activeIndex === index, 'cate-inactive': activeIndex !== index}">{{product}}</span>
      </div>
    </div>

  <div v-for="(product,index) in products_data" :key="product.idx1" class="col-6" style="display: flex;align-items: stretch;">
    <cate_item
              :img="product.it_img1"
              :title="product.it_name1"
              :size="product.it_size1"
              :desc="product.it_explan1"
              :nor_price="product.out_price1"
              :price="product.sale_out_price1"
              :per="product.per1"
              :idx="product.idx1"
              :count="index"
              @addToCart="addCart_cate"
    />
  </div>
  </div>
  <div v-if="isLoading" class="loading-div">
    <img class="loading_gif" src="@/assets/img/loading.gif" alt="로딩 중..." />
  </div>
  <infi_scrolls @show="loadMoreItems"/>
  <div style="height: 100px"></div>
</template>

<script>
import cate_item from '@/components/item_ui/cate_item.vue';
import infi_scrolls from "@/components/infi_scrolls";

export default {
  name: 'cate_prd',
  components:{
    cate_item,
    infi_scrolls,
  },
  data() {
    return {
      products_data: [],
      category_data:[],
      activeIndex: 0,
      isLoading:true,
      item_start: 0,
      item_end: 1,
      cate:"전체"
    }
  },
  created() {
    this.$store.dispatch('Showheaderfooter', {
      Title: "매장상품",
      showBack: false,
      showAlarm: true,
      showcoupon: true,
      showCart: true,
      showsheader: true,
      showFooter: true,
      enableScrollHeader: true,
    });
  },
  mounted() {
    if (this.products_data.length === 0) {
      this.loadMoreItems();
    }
  },

  methods: {
    loadMoreItems() {
      if (this.item_end <= this.item_start) {
        return;
      }
      this.isLoading = true;
      // 예를 들어 API 요청 등으로 데이터를 가져오는 비동기 작업을 수행
      let formData = new FormData();
      formData.append('mode', 'app_cate_array');
      formData.append('martid', this.$store.state.martid);
      formData.append('token', this.$store.state.token);
      formData.append('lf', this.$store.state.lf);
      formData.append('item_start', this.item_start);
      formData.append('cate', this.cate);
      this.$axios.post(this.$store.state.apiEndpoint, formData)
          .then(response => {
            this.products_data.push(...response.data.data);
            this.category_data = response.data.category;
            this.item_start = response.data.item_start;
            this.item_end = response.data.item_end;
          })
          .catch(error => {
            this.$modal.errorToast(error);
          }).finally(() => {
        this.isLoading = false; // 요청이 끝나면 로딩 상태를 false로 설정
      });
    },
    selectCategory(index,cate) {
      if (this.activeIndex != index) {
        this.item_start = 0;
        this.products_data = []
        this.cate = cate;
        this.loadMoreItems();
        this.activeIndex = index;
      }
    },
    addCart_cate(idx,ct_out_price,ct_price,it_img1,it_name) {
      this.$addcart(idx,"cate",ct_out_price,ct_price,it_img1,it_name);
    },
  }
}
</script>

<style scoped>
.cate-inactive{
  padding-left: 16px;
  padding-right: 16px;
  color: var(--inactive-color);
  font-size: 16px;
  font-family: 'Noto Sans CJK KR';
}
.cate-active{
  padding-left: 16px;
  padding-right: 16px;
  border-bottom: 3px solid var(--second-color); /* 테두리 색상 변경 가능 */
  color: var(--second-color);
  font-size: 16px;
  font-family: 'Noto Sans CJK KR Bold';
}


.category-scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  padding: 20px 10px;
  -ms-overflow-style: none;  /* IE와 Edge를 위한 설정 */
  scrollbar-width: none;  /* Firefox를 위한 설정 */
}
.category-scroll-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera를 위한 설정 */
}
.category-item {
  display: inline-block;
  border-bottom: 1px solid #ddd; /* 테두리 색상 변경 가능 */
}

/* 스크롤바 스타일링 (옵션) */
.category-scroll-container::-webkit-scrollbar {
  height: 5px;
}

.category-scroll-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.category-scroll-container::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}
</style>
