<template>
  <div class="flex-col" style="padding: 15px;margin-bottom: 16px">
    <div v-if="products.mart_img != ''">
    <img src="{{products.mart_img}}" style="border-radius: 5px;">
    </div>
    <span class="active-font-18-b" style="font-size: 26px;">{{products.mart_title}}</span>
    <span class="active-font-18" style="margin-bottom: 20px">{{this.$formatPhone(products.mart_tel)}}</span>
    <div v-if="products.mart_adr != ''" style="margin-bottom: 20px">
      <span class="active-font-18 intitle">매장 위치</span>
      <span class="active-font-16">{{products.mart_adr}}</span>
    </div>
    <div v-if="products.mart_work_day != ''" style="margin-bottom: 20px">
        <span class="active-font-18 intitle">영업 시간</span>
        <span class="active-font-16" style="display:block;">{{products.mart_work_day}}</span>
        <span class="active-font-16" style="display:block;">{{products.mart_rest_day}}</span>
    </div>
      <div v-if="products.msg != ''" style="margin-bottom: 20px">
          <span class="active-font-18 intitle">매장 소개</span>
          <span class="active-font-16" style="display:block;">{{products.msg}}</span>
      </div>
        <div v-if="products.able_time_s != ''" style="margin-bottom: 20px">
          <span class="active-font-18 intitle" >주문 가능 시간</span>
          <span class="active-font-16" style="display:block;">{{products.able_time_s}} ~ {{products.able_time_e}}</span>
        </div>
        <div v-if="products.delivery_info != ''" style="margin-bottom: 20px">
            <span class="active-font-18 intitle">배송 안내</span>
            <span class="active-font-16" style="display:block;">{{products.delivery_info}}</span>
          </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      products: [],
      isLoading:true,
    }
  },
  created() {
    this.fetchProducts();
    this.$store.dispatch('Showheaderfooter', {
      Title: '마트,배송 안내',
      showBack: true,
      showAlarm: false,
      showCart: false,
      showsheader: true,
      showFooter: false,
      enableScrollHeader: true,
    });
  },
  methods: {
    fetchProducts() {
      let formData = new FormData();
      formData.append('mode', 'get_mart_info');
      formData.append('martid', this.$store.state.martid);
      formData.append('token', this.$store.state.token);
      this.$axios.post(this.$store.state.apiEndpoint, formData)
          .then(response => {
            this.products = response.data.data;
            console.log(this.products);
          })
          .catch(error => {
            this.$modal.errorToast(error);
          }).finally(() => {
        this.isLoading = false; // 요청이 끝나면 로딩 상태를 false로 설정
      });
    }
  }
}
</script>


<style scoped>
.intitle{
  color: var(--prime-color);
  padding-top: 10px;
  display:block;
  padding-bottom: 8px;
}
</style>