<template>
  <div class="flex-row highlight">
    <div class="img">
      <img :src="img" @error="noImage">
      <span class="cart-btn" @click="handleAddToCart"><img src="@/assets/img/svg/cart.svg" /></span>
    </div>
    <span class="prd-name col-12">{{title}}</span>
    <span class="prd-price col-12">{{price}}</span>
    <span class="prd-desc col-12">{{desc}}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: '상품명'
    },
    nor_price: {
      type: String,
      required: true,
      default: '정상가'
    },
    price: {
      type: String,
      required: true,
      default: '정상가'
    },
    desc: {
      type: String,
      required: true,
      default: '상품설명'
    },
    img: {
      type: String,
      required: true,
      default: ''
    },
    idx: {
      type: Number,
      required: true,
      default: null
    },
  },
  emits: ['addToCart'], // 이벤트 방출을 위해 emits 옵션 정의
  methods: {
    noImage(event) {
      event.target.onerror = null;
      event.target.src = require('@/assets/img/no_img.gif');
    },
    handleAddToCart() {
      this.$emit('addToCart', this.idx,0,this.price,this.img,this.title);
    }
  }
};
</script>

<style scoped>
.cart-btn{
  position: absolute;
  display: inline-block;
  background: #fff;
  width: 8vw;
  height: 8vw;
  font-size: 8vw;
  padding: 10px;
  border-radius: 50%;
  color: var(--prime-color);
  box-shadow: 1px 1px 5px #606060;
  bottom: 18px;
  right: 18px;
}
.highlight{
  overflow: hidden;
  flex-wrap: wrap;
  width: calc(100% - 20px);
  margin: 10px;
}

.highlight > .img{
  max-height: 100vw;
  border-radius: 15px;
  overflow: hidden;
  width: 100%;
  position: relative;
}

.highlight > .img > img{
  object-fit: cover;
  height: 100%;
  width: 100%;
}


.highlight > .prd-name{
  font-size: 20px;
  font-weight: 900;
  font-family: Noto Sans KR;
  width: 100%;
  text-align: right;
}

.highlight > .prd-norprice:before{
  content: '정상가 ';
}

.highlight > .prd-norprice{
  font-size: 16px;
  font-weight: 900;
  font-family: Noto Sans KR;
  width: 100%;
  color: var(--prime-color);
  text-decoration: line-through var(--active-button-color);
  text-align: right;
}

.highlight > .prd-norprice + .prd-price:before{
  content: '할인가 ';
}

.highlight > .prd-norprice + .prd-price{
  color: var(--active-button-color);
}

.highlight > .prd-price{
  font-size: 20px;
  font-weight: 900;
  font-family: Noto Sans KR;
  width: 100%;
  color: var(--prime-color);
  text-align: right;
}

.highlight > .prd-price:after{
  content: '원';
  font-size: 15px;
}

.highlight > .prd-desc{
  font-size: 14px;
  font-weight: 700;
  font-family: Noto Sans KR;
  width: 100%;
  text-align: right;
  color: var(--prime-font-inactive);
}

</style>