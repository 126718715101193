<template>
  <div v-if="isLoading" class="loading-div">
    <img class="loading_gif" src="@/assets/img/loading.gif" alt="로딩 중..." />
  </div>
  <div v-else class="page-container">
    <MapKakao :latitude="start_lat" :longitude="start_long"
              @returnadr="returnadr"
    />
    <div style="width: 100%;height: 10px;background-color: #F6F6F6;margin-bottom: 10px;border-top: 1px solid #d9d9d9;">
    </div>
    <div style="padding:10px 20px 10px 20px;">
      <span class="active-font-18-b">{{addr1}}</span>
    </div>
    <div style="padding:0px 20px 10px 20px;">
      <span class="band">도로명</span> <span class="active-font-16">{{addr2}}</span>
    </div>

    <div style="width: 100%;height: 100%;background-color: #F6F6F6;border-top: 1px solid #d9d9d9;flex-grow:1;" class="v-margin">
    </div>
    <div style="position: fixed;bottom: 0;left:0;background-color: #fff;width: 100%">
      <div @click="backandadr" class="complite-btn grow-center">
        이 위치로 주소 설정
      </div>
    </div>
  </div>
</template>



<script>
import MapKakao from '@/components/ui_kakao_map.vue';

export default {
  components:{
    MapKakao
  },
  data() {
    return {
      products: {},
      isLoading:false,
      idx:"",
      subject:"",
      addr1:"",
      addr2:"",
      home_etc:0,
      etc_name:"",
      start_lat:37.39843974939604,
      start_long:127.10972941510465,
    }
  },
  created() {
    this.idx = this.$route.params.idx;
    this.$store.dispatch('Showheaderfooter', {
      Title: '현재 위치 주소',
      showBack: true,
      showAlarm: false,
      showCart: false,
      showedit: false,
      showsheader: true,
      showFooter: false,
    });
  },
  mounted() {
    window.getgps = this.getgps.bind(this);
    this.askgpsk();
  },
  methods: {
    getgps(lat,long){
      this.start_lat = parseFloat(lat);
      this.start_long = parseFloat(long);
    },
    askgpsk() {
      if (window.askgps && typeof window.askgps.postMessage === 'function') {
        window.askgps.postMessage('');
      }
    },
    backandadr(){
      const orderData = localStorage.getItem('order_b_data');
      const parsedData = JSON.parse(orderData);
      parsedData.baddr1 = this.addr1;
      localStorage.setItem('order_b_data', JSON.stringify(parsedData));

      this.$router.replace({
        name: 'Order_Page'
      });
    },
    returnadr(addr1,addr2){
      this.addr1 = addr1;
      this.addr2 = addr2;
    },
    fetchProducts() {
      let formData = new FormData();
      formData.append('mode', 'get_useradr_list');
      formData.append('martid', this.$store.state.martid);
      formData.append('token', this.$store.state.token);
      formData.append('idx', this.idx);
      this.$axios.post(this.$store.state.apiEndpoint, formData)
          .then(response => {
            this.products = response.data.data;
            this.addr1 = this.products.addr1;
            this.addr2 = this.products.addr2;
            this.subject = this.products.subject;
            if (this.subject == "기본배송지"){
              this.home_etc = 0;
            }else{
              this.home_etc = 1;
              this.etc_name = this.subject;
            }
          })
          .catch(error => {
            this.$modal.errorToast(error);
          }).finally(() => {
        this.isLoading = false; // 요청이 끝나면 로딩 상태를 false로 설정
      });
    },
  }
}
</script>

<style scoped>

.band{
  background-color: #ababab;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  padding: 4px 4px;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}


.page-container {
  height: calc(100vh - 65px);
  overflow-y:hidden;
}

.complite-btn{
  margin: 10px;
  height: 50px;
  border: 1px solid var(--prime-color);
  border-radius: 6px;
  background-color: var(--prime-color);
  font-size: 18px;
  font-family: 'Noto Sans CJK KR Bold';
  color: #fff;

}

.band-btn{
  background-color: #E9FBFB;
  border-radius: 0.2rem;
  color: var(--prime-color);
  display: inline-block;
  line-height: 1.1;
  padding: 8px 20px 8px 20px;
  font-size: 12px;
  font-family: 'Noto Sans CJK KR';
  margin-right: 20px;
}

</style>
