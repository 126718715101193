<template>
  <div class="docu-img" v-if="showImage">
    <img :src="src" @error="hideImage">
  </div>
</template>



<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
      default: 'emety' // 노 이미지 넣어두자
    },
  },
  data() {
    return {
      showImage: true // 이미지 보이기/숨기기를 제어하는 데이터 속성
    };
  },
  methods: {
    hideImage() {
      this.showImage = false; // 이미지 로드에 실패하면 숨깁니다.
    }
  }
};
</script>

<style scoped>
.docu-img{
  width: calc(100% - 20px);
  margin: 10px;
  overflow: hidden;
  border-radius: 15px;
}

.docu-img > img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>