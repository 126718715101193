<template>
  <div class="col-6 flex-wrap flex-row" style="padding: 20px 5px 20px 10px">
    <div class="col-12" style="position: relative;">
      <img :src="img" @error="noImage" style="width: 100%;height: auto">
      <span class="cart-btn" style="position: absolute;bottom:12px;right: 10px;height: 28px;width: 28px;display: flex;align-content: center;justify-content: center;" @click="handleAddToCart"><img src="@/assets/img/svg/cart.svg" style="width: 30px;"/></span>
    </div>
    <span class="col-12 grow-right title">{{title}}</span>
    <span class="col-12 grow-right price">{{$formatPrice(price)}}원</span>
    <span class="col-12 grow-right decs">{{desc}}</span>
  </div>
    <div class="col-6 flex-wrap flex-row" style="padding: 20px 10px 20px 5px">
    <div class="col-12" style="position: relative;" v-if="title2 != ''">
      <img :src="img2" @error="noImage" style="width: 100%;height: auto">
      <span class="cart-btn" style="position: absolute;bottom:14px;right: 14px;height: 28px;width: 28px;display: flex;align-content: center;justify-content: center;" @click="handleAddToCart2"><img src="@/assets/img/svg/cart.svg" style="width: 30px;"/></span>
    </div>
    <span class="col-12 grow-right title" v-if="title2 != ''">{{title2}}</span>
    <span class="col-12 grow-right price" v-if="title2 != ''">{{$formatPrice(price2)}}원</span>
    <span class="col-12 grow-right decs" v-if="title2 != ''">{{desc2}}</span>
    </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: '상품명'
    },
    nor_price: {
      type: String,
      required: true,
      default: '정상가'
    },
    price: {
      type: String,
      required: true,
      default: '정상가'
    },
    desc: {
      type: String,
      required: true,
      default: '상품설명'
    },
    img: {
      type: String,
      required: true,
      default: '' // 기본값
    },
    idx: {
      type: Number,
      required: true,
      default: null
    },

    title2: {
      type: String,
      required: true,
      default: '상품명'
    },
    nor_price2: {
      type: String,
      required: true,
      default: '정상가'
    },
    price2: {
      type: String,
      required: true,
      default: '정상가'
    },
    desc2: {
      type: String,
      required: true,
      default: '상품설명'
    },
    img2: {
      type: String,
      required: true,
      default: '' // 기본값
    },
    idx2: {
      type: Number,
      required: true,
      default: null
    },
  },
  emits: ['addToCart'], // 커스텀 이벤트 명시
  methods: {
    noImage(event) {
      event.target.onerror = null;
      event.target.src = require('@/assets/img/no_img.gif');
    },
    handleAddToCart() {
      this.$emit('addToCart', this.idx,0,this.price,this.img,this.title);
    },
    handleAddToCart2() {
      this.$emit('addToCart', this.idx2,0,this.price2,this.img2,this.title2);
    }
  }
};
</script>

<style scoped>
.cart-btn{
  background: #fff;
  padding: 10px;
  border-radius: 50%;
  color: var(--prime-color);
  box-shadow: 1px 1px 5px #606060;
}
.title{
  text-align: right;
  font-size: 18px;
  font-family: 'Noto Sans CJK KR Bold';
  color: var(--prime-font-active);
}
.price{
  text-align: right;
  font-size: 18px;
  font-family: 'Noto Sans CJK KR Bold';
  color: var(--prime-color);
}
.decs{
  text-align: right;
  font-size: 16px;
  font-family: 'Noto Sans CJK KR';
  color: var(--inactive-color);
}
</style>