<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" >
    <g id="btn_gnb_search" transform="translate(-20 -36.25)">
      <rect id="사각형_61" data-name="사각형 61" width="24" height="24" transform="translate(20 34.25)" fill="none"/>
      <g id="타원_1" data-name="타원 1" transform="translate(23 37.25)" fill="none" :stroke="currentColor" stroke-width="1">
        <circle cx="8" cy="8" r="8" stroke="none"/>
        <circle cx="8" cy="8" r="7.5" fill="none"/>
      </g>
      <path id="패스_296" data-name="패스 296" d="M298.313,55.563l3.287,3.276,1.494,1.489" transform="translate(-261.844 -4.828)" :fill="currentColor" :stroke="currentColor" stroke-linecap="round" stroke-width="1"/>
    </g>
  </svg>


</template>

<script>
export default {
  props: ['isActive'],
  computed: {
    currentColor() {
      return this.isActive ? 'var(--active-button-color)' : 'var(--inactive-color)';
    },
  }
};
</script>