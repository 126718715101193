<template>
  <svg id="radio_btn" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g id="타원_15" data-name="타원 15" :fill="currentColor_v" :stroke="currentColor" stroke-width="1">
      <circle cx="8" cy="8" r="8" stroke="none"/>
      <circle cx="8" cy="8" r="7.5" fill="none"/>
    </g>
    <circle id="타원_49" data-name="타원 49" cx="3" cy="3" r="3" transform="translate(5 5)" :fill="currentColor_f"/>
  </svg>
</template>

<script>
export default {
  props: ['isActive'],
  computed: {
    currentColor() {
      return this.isActive ? 'var(--prime-color)' : 'var(--inactive-color)';
    },
    currentColor_v() {
      return this.isActive ? 'var(--prime-color)' : 'none';
    },
    currentColor_f() {
      return this.isActive ? '#fff' : '#e3e5eb';
    },
  }
};
</script>
