import { createApp } from 'vue'
import App from './App.vue'
import router from './router' // Vue Router 인스턴스를 import합니다.
import store from './store';
import axios from 'axios';
import VueSweetalert2 from 'vue-sweetalert2';
import addcart from './addcart';

import ui_star from '@/components/ui_icon/ui_star.vue';
import ui_check from '@/components/ui_icon/ui_check.vue';
import ui_updnarrow from '@/components/ui_icon/ui_updnarrow.vue';
import ui_switch from '@/components/ui_icon/ui_switch.vue';
import ui_circle from '@/components/ui_icon/ui_circle.vue';
import ui_inputtext from '@/components/ui_icon/ui_inputtext.vue';
import ui_adr_home from '@/components/ui_icon/ui_adr_home.vue';
import ui_adr_etc from '@/components/ui_icon/ui_adr_etc.vue';



import Modals from './Modal'

const app = createApp(App);




app.config.errorHandler = (err, instance, info) => {
    const errorReport = async () => {
        try {

            let formData = new FormData();
            formData.append('mode', 'error_report');
            formData.append('martid', store.state.martid);
            formData.append('token', store.state.token);
            formData.append('lf', store.state.lf);
            formData.append('message', err.message);
            formData.append('stack', err.stack);
            formData.append('component', instance ? instance.$options.name : undefined);
            formData.append('info', info);
            axios.post(store.state.apiEndpoint, formData)
                .then(response => {
                    console.log(response,"오류보고완료");
                });
        } catch (reportErr) {
            console.error('오류 보고 중에 오류 발생:', reportErr);
        }
    };
    errorReport();
    console.error(err);
};

app.config.globalProperties.$formatPrice = value => {
    if (value === 0) return '0'; // 0일 때 공백이 아닌 '0'을 반환
    if (!value) return '';
    return new Intl.NumberFormat('ko-KR').format(value);
};


app.config.globalProperties.$formatPhone = phone => {
    if (!phone) return '';

    // 숫자만 있는 문자열로 변환합니다.
    const digits = phone.replace(/\D/g, '');

    // 전화번호의 형식에 따라 변환합니다.
    const phoneNumberLength = digits.length;

    // 한국의 전화번호 형식에 맞춰서 변환합니다.
    if (phoneNumberLength < 8) {
        return digits;
    } else if (phoneNumberLength < 10) {
        return digits.replace(/(\d{3,4})(\d{4})$/, '$1-$2');
    } else if (phoneNumberLength === 10) {
        // 지역번호가 있는 번호 (02-XXXX-XXXX) 또는 없는 번호 (031-XXX-XXXX or 010-XXXX-XXXX)
        return digits.replace(/(\d{2,3})(\d{3,4})(\d{4})$/, '$1-$2-$3');
    } else if (phoneNumberLength === 11) {
        // 휴대폰 번호 (010-XXXX-XXXX)
        return digits.replace(/(\d{3})(\d{4})(\d{4})$/, '$1-$2-$3');
    }

    // 그 이상의 길이를 가진 번호 처리
    return digits;
};

/*
// API 엔드포인트를 글로벌 프로퍼티로 설정
app.provide('apiEndpoint', '/api/products');
// axios 인스턴스를 글로벌 프로퍼티로 설정
app.provide('axios', axios);
*/
app.config.globalProperties.$axios = axios;
// 전역 컴포넌트로 아이콘을 등록합니다.

app.component('ui_star', ui_star);
app.component('ui_check', ui_check);
app.component('ui_updnarrow', ui_updnarrow);
app.component('ui_circle', ui_circle);
app.component('ui_inputtext', ui_inputtext);
app.component('ui_adr_home', ui_adr_home);
app.component('ui_adr_etc', ui_adr_etc);
app.component('ui_switch', ui_switch);

app.use(router);
app.use(store);



app.use(VueSweetalert2);
app.use(Modals);
app.use(addcart);

app.mount('#app');
