<template>
  <div v-if="isLoading" class="loading-div">
    <img class="loading_gif" src="@/assets/img/loading.gif" alt="로딩 중..." />
  </div>
  <div v-else>
      <div>
        <div class="flex-row" style="padding-top: 30px;">
          <div class="col-12 grow-left"><span style="padding-left: 20px;" class="active-font-16">배송지 정보</span><ui_star/></div>
        </div>
        <div style="padding:10px 20px 15px 20px;">
        <div class="add_text_btn grow-center active-font-14" style="color: var(--prime-color);height: 40px;" @click="selectaddr">
          등록된 내 주소 선택하기
        </div>
        </div>
        <div style="width: 100%;height: 10px;background-color: #F6F6F6;">
        </div>
        <div class="flex-row" style="margin-top: 20px;">
          <div class="col-12 grow-left"><span style="padding-left: 20px" class="active-font-16">받으실 분</span><ui_star/></div>
        </div>
        <div ref="bname" style="padding:10px 20px 10px 20px;">
          <ui_inputtext  placeholder="받으실분 성함 입력" v-model="bname" />
        </div>
        <div class="flex-row" style="margin-top: 20px;">
          <div class="col-12 grow-left"><span style="padding-left: 20px" class="active-font-16">연락처</span><ui_star/></div>
        </div>
        <div ref="btel" style="padding:10px 20px 10px 20px;">
          <ui_inputtext type="phone" placeholder="받으실분 연락처 입력" v-model="btel" maxlength="13"/>
        </div>
        <div class="flex-row" style="margin-top: 20px;">
          <div class="col-12 grow-left"><span style="padding-left: 20px" class="active-font-16">배송지 주소</span><ui_star/></div>
        </div>
        <div ref="baddr1" style="padding:10px 20px 10px 20px;">
          <div class="flex-row">
            <ui_inputtext placeholder="주소" class="col-8" v-model="baddr1" />
            <div class="col-4 grow-center" style="padding-left: 5px;">
              <span class="grow-center" style="background-color: var(--prime-color);height:100%;width: 100%;border-radius: 5px;color: #fff" @click="searchaddr">주소 검색</span>
            </div>
          </div>
          <div ref="baddr2" style="height: 10px"></div>
          <ui_inputtext placeholder="상세 주소" v-model="baddr2" />
        </div>
        <div class="flex-row" style="margin-top: 20px;">
          <div class="col-12 grow-left"><span style="padding-left: 20px" class="active-font-16">배송 메모</span></div>
        </div>
        <div style="padding:10px 20px 10px 20px;">
          <ui_inputtext placeholder="배송 메모를 작성해주세요." v-model="bmemo" />
        </div>
        <div style="padding: 0 20px 20px 20px">
        <div class="warning_info  active-font-14-b flex-col" style="background-color: #FFF3F3;">
          <div class="grow-left" style="padding: 10px 20px 2px 20px">
            <span class="active-font-14-b grow-center" style="color:#FD7B83">
              <img src="@/assets/img/svg/ar.svg" style="padding-right: 6px"/>확인해주세요!
            </span>
          </div>
          <div class="grow-left" style="padding: 0px 20px 10px 20px">
            <span class="active-font-12">
              비밀번호가 정확하지 않은 경우, 부득이하게 1층 공동현관 앞 또는 경비실 앞에 배송되거나 미배송될수있습니다.
            </span>
          </div>
        </div>
        </div>
        <div style="width: 100%;height: 10px;background-color: #F6F6F6;">
        </div>

        <div class="flex-row">
          <div class="col-12 grow-left" style=""><span style="padding: 20px 16px 20px 16px;" class="active-font-18-b">주문상품 정보</span></div>
        </div>
        <div class="flex-row" @click="toggle_mart_prd_listbtn" style="background-color: var(--prime-color-pt);">
          <div class="col-8 grow-left" style="padding: 20px 0px 20px 14px" ><span class="active-font-16-b">마트 배송 ({{mart_prd_count}}개)</span></div>
          <div class="col-4 grow-right" style="padding: 20px 22px 20px 0px"><ui_updnarrow :isActive="toggle_mart_prd_list"/></div>
        </div>


        <div v-show="toggle_mart_prd_list" class="product-list">
          <div v-for="(product, index) in products.mart_cart_list" :key="product.idx">
            <div class="flex-row">
            <div class="col-3" style="padding: 8px;"><img style="width: 100%;height: auto;" :src="product.it_img1" @error="handleImageError(product)"/></div>
            <div class="col-9" style=" display: flex;flex-direction: column;justify-content: center;">
              <div><span class="active-font-16" style="padding-left: 10px">{{product.it_name}}</span></div>
              <div class="active-font-18-b" style="padding-left: 10px">{{$formatPrice(product.ct_price)}}원 | {{product.ct_qty}} 개</div>
            </div>
            </div>
            <div v-if="products.mart_cart_list.length-1 > index" style="width: calc(100% - 20px);height: 1px;background-color: #F6F6F6;margin-left: 10px;">
            </div>
          </div>
          <div style="width: 100%;height: 2px;background-color: #F6F6F6;">
          </div>
          <div v-if="additionalOrder" style="padding: 20px 20px 0px 20px;">
            <div class="grow-center" style="color: var(--prime-color);border: 2px solid var(--prime-color);border-radius: 6px;padding-top: 5px">
              <textarea disabled v-model="additionalOrder" class="active-font-14" style="width: calc(100% - 40px);overflow-y: hidden;  border: none;outline: none;background: transparent;" rows="5"></textarea>
            </div>
          </div>
          <div class="flex-row">
            <div class="col-6 active-font-16-b" style="padding-left: 20px;padding-top: 30px;">총 상품금액</div>
            <div class="col-6 grow-right active-font-16-b" style="padding-right: 20px;padding-top: 30px;">{{$formatPrice(total_prd)}}원</div>
          </div>
          <div class="flex-row flex-wrap">
            <div v-if="!jotext" class="col-4 active-font-16-b" style="padding-left: 20px;padding-top: 20px;">총 배송비</div>
            <div v-if="!jotext" class="col-8 grow-right active-font-16-b" style="padding-right: 20px;padding-top: 20px;">{{$formatPrice(total_deliver)}}원</div>
            <div v-if="jotext" class="col-12 grow-center" style="padding-left: 20px;padding-right: 20px;padding-top: 20px;"><span class="active-font-16-b">총 배송비</span><span class="inactive-font-14" style="padding-left: 10px;">추가주문으로 배송비가 계산되지않습니다.</span></div>
          </div>
          <div class="flex-row">
            <div class="col-10" style="padding-left: 20px;padding-top: 10px;">
              <div v-if="this.products.mart_deliver_type == '조건부무료배송' && parseInt(this.products.mart_deliver_jo) > 0 && parseInt(this.products.mart_deliver_p) > 0" class="inactive-font-12 grow-right">
                조건부 무료배송 {{$formatPrice(this.products.mart_deliver_jo)}}원 이상 구매시 무료 배송
              </div>
              <div v-if="this.products.mart_deliver_type == '유료배송' && parseInt(this.products.mart_deliver_jo) > 0 && parseInt(this.products.mart_deliver_p) > 0" class="inactive-font-12 grow-right">
                유료 배송 {{$formatPrice(this.products.mart_deliver_jo)}}원
              </div>
              <div v-if="this.products.mart_deliver_type == '무료배송'" class="inactive-font-12 grow-right">
                무료 배송
              </div>
            </div>
          </div>
          <div style="width: calc(100% - 20px);height: 2px;background-color: #F6F6F6;margin-left: 10px;" class="v-margin2">
          </div>
          <div class="flex-row">
            <div class="col-6 active-font-18" style="padding-left: 20px;padding-bottom: 20px;color:var(--prime-color)">총 결제금액</div>
            <div class="col-6 grow-right active-font-18-b" style="padding-right: 20px;padding-bottom: 20px;color:var(--prime-color)">{{$formatPrice(parseInt(total_prd) + parseInt(total_deliver))}}원</div>
          </div>
          <div style="width: 100%;height: 10px;background-color: #F6F6F6;">
          </div>
          <div class="flex-row">
            <div class="col-12 grow-left" style="padding-top: 20px"><span style="padding-left: 20px;" class="active-font-16">결제 방법</span><ui_star/></div>
          </div>
          <div style="padding:10px 20px 15px 20px;" class="flex-row">
            <div class="col-6">
            <div class="grow-center" style="height: 40px;margin-right: 5px;" @click="cash_type_o(1)" :class="{'active-btn': cash_type_o_btn === 1, 'inactive-btn': cash_type_o_btn !== 1}">
              만나서 결제
            </div>
            </div>
            <div class="col-6" v-if="mart_account != ''">
            <div class="grow-center" style="height: 40px;margin-left: 5px;" @click="cash_type_o(2)" :class="{'active-btn': cash_type_o_btn === 2, 'inactive-btn': cash_type_o_btn !== 2}">
              무통장 입금
            </div>
          </div>
          </div>
          <div class="flex-col">
            <div class="grow-left"><span style="padding-left: 20px;padding-top: 10px" class="active-font-16 active-font-color">친절하게 문앞까지!</span></div>
            <div class="grow-left" style="padding-top: 5px"><span style="padding-left: 20px" class="active-font-18-b">결제수단</span><span class="active-font-18">을 선택해주세요.</span></div>
            <div style="padding:15px 20px 15px 20px;" class="flex-col">
              <div v-if="cash_type_o_btn === 1">
                <div class="grow-left" style="height: 40px;" @click="cash_type_t(1)" :class="{'active-btn': cash_type_t_btn === 1, 'inactive-btn': cash_type_t_btn !== 1}">
                  <ui_circle :isActive="cash_type_t_btn === 1" style="padding-left: 10px;padding-right: 10px;"/>만나서 카드 결제
                </div>
                <div style="padding-top: 10px">
                  <div class="grow-left" style="height: 40px;" @click="cash_type_t(2)" :class="{'active-btn': cash_type_t_btn === 2, 'inactive-btn': cash_type_t_btn !== 2}">
                    <ui_circle :isActive="cash_type_t_btn === 2" style="padding-left: 10px;padding-right: 10px;"/>만나서 현금 결제
                  </div>
                </div>
              </div>
              <div v-if="cash_type_o_btn === 2">
                <div>
                  <div class="add_text_btn grow-left" style="height: 40px;">
                    {{mart_account}}
                  </div>
                </div>
              </div>
            </div>
            <div class="grow-left"><span style="padding-left: 20px;padding-top: 10px" class="active-font-16">영수증 신청</span></div>
            <div style="padding:10px 20px 5px 20px;" class="flex-row">
              <div class="col-6">
                <div class="grow-center" style="height: 40px;margin-right: 5px;" @click="cash_type_tr(1)" :class="{'active-btn': cash_type_tr_btn === 1, 'inactive-btn': cash_type_tr_btn !== 1}">
                  신청 안함
                </div>
              </div>
              <div class="col-6">
                <div class="grow-center" style="height: 40px;margin-left: 5px;" @click="cash_type_tr(2)" :class="{'active-btn': cash_type_tr_btn === 2, 'inactive-btn': cash_type_tr_btn !== 2}">
                  소득공제용(개인)
                </div>
              </div>
            </div>
            <div style="padding:5px 20px 15px 20px;" class="flex-row">
              <div class="col-6">
                <div class="grow-center" style="height: 40px;margin-right: 5px;" @click="cash_type_tr(3)" :class="{'active-btn': cash_type_tr_btn === 3, 'inactive-btn': cash_type_tr_btn !== 3}">
                  사업자 지출증빙용
                </div>
              </div>
            </div>
          </div>
          <div v-if="cash_type_tr_btn === 2">
            <div class="flex-row" style="margin-top: 20px;">
              <div class="col-12 grow-left"><span style="padding-left: 20px" class="active-font-16">현금 영수증(개인) 휴대폰번호</span><ui_star/></div>
            </div>
            <div ref="recipe_tel" style="padding:10px 20px 10px 20px;">
              <ui_inputtext type="phone" v-model="recipe_tel" placeholder="휴대폰 번호를 입력하세요." maxlength="13" />
            </div>
          </div>
          <div v-if="cash_type_tr_btn === 3">
            <div class="flex-row" style="margin-top: 20px;">
              <div class="col-12 grow-left"><span style="padding-left: 20px" class="active-font-16">지출증빙용 사업자번호</span><ui_star/></div>
            </div>
            <div ref="recipe_com" style="padding:10px 20px 10px 20px;">
                <ui_inputtext type="company" placeholder="사업자번호를 입력하세요." v-model="recipe_com" maxlength="12"/>
            </div>
          </div>
          <div style="width: 100%;height: 110px;background-color: #ffffff;">
          </div>
        </div>
        <div style="height: 100px;"></div>
      </div>
  </div>
  <div class="bottom_btn" @click="submitOrder">
    <div style="padding-top: 20px;padding-bottom: 20px;background-color: var(--prime-color);">
      <span v-if="mart_prd_count == 0" class="active-font-16-b" style="color: #fff !important;">장바구니가 비어있습니다.</span>
      <span v-else class="active-font-16-b" style="color: #fff !important;" >{{$formatPrice(parseInt(total_prd) + parseInt(total_deliver))}}원 주문하기</span>
    </div>
  </div>
</template>

<script>



export default {
  data() {
    return {
      isLoading:true,
      mart_prd_count:0,
      total_prd:0,
      total_deliver:0,
      toggle_mart_prd_list:true,
      cash_type_o_btn: 1,
      cash_type_t_btn: 1,
      cash_type_tr_btn: 1,
      bname:"",
      btel:"",
      baddr1:"",
      baddr2:"",
      bmemo:"",
      mart_account:"",
      additionalOrder:"",
      martidx:"",
      jotext:false,
      recipe_tel:"",
      recipe_com:"",
      order_id:"",
      oneclick:false,
    }
  },
  created() {
    this.martidx = this.$store.state.cart_order_list;
    if (this.martidx == ""){
      this.$router.go(-1);
    }
    this.additionalOrder = this.$store.state.cart_add_order;
    this.fetchProducts(this.martidx,this.additionalOrder);
    this.$store.dispatch('Showheaderfooter', {
      Title: '주문/결제하기',
      showBack: true,
      showAlarm: false,
      showCart: false,
      showsheader: true,
      showFooter: false,
      enableScrollHeader: true,
    });
  },
  mounted() {
    this.loadOrderData();
  },
  methods: {
    handleFieldValidation(field) {
      const fieldNames = {
        bname: '받으시는 분',
        btel: '연락처',
        baddr1: '배송지 주소',
        baddr2: '상세 주소',
        recipe_tel: '현금영수증 휴대폰번호',
        recipe_com: '지출증빙 사업자번호'
      };
      const fieldElement = this.$refs[field]; // ref를 통한 DOM 요소 접근
      if (fieldElement) {
        fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        // 스크롤 이동 후 토스트 메시지를 지연시키기 위해 setTimeout 사용
        setTimeout(() => {
          this.$modal.errorToast(`${fieldNames[field]}을(를) 입력해주세요.`);
        }, 1000); // 스크롤 이벤트가 충분히 완료된 후에 토스트 메시지 표시
      }
    },

    submitOrder() {
      if (this.mart_prd_count == 0 || this.oneclick){
        return;
      }
      const fields = ['bname', 'btel', 'baddr1', 'baddr2', 'recipe_tel', 'recipe_com']; // 검사할 필드의 ref 이름
      for (const field of fields) {
        const isRecipeTelConditionMet = field === "recipe_tel" && this.cash_type_tr_btn === 2;
        const isRecipeComConditionMet = field === "recipe_com" && this.cash_type_tr_btn === 3;
        const isOtherField = field !== "recipe_com" && field !== "recipe_tel";

        if (!this[field] && (isRecipeTelConditionMet || isRecipeComConditionMet || isOtherField)) {
          this.handleFieldValidation(field);
          return;
        }
      }
      //const additionalOrderText = this.additionalOrder;
      this.oneclick = true;
      let formData = new FormData();
      formData.append('mode', 'order_complite');
      formData.append('martid', this.$store.state.martid);
      formData.append('token', this.$store.state.token);
      formData.append('lf', this.$store.state.lf);
      formData.append('adr_idx', this.$store.state.cart_addr_idx);
      formData.append('order_id', this.order_id);
      formData.append('b_name',this.bname);
      formData.append('b_tel', this.btel);
      formData.append('b_adr1', this.baddr1);
      formData.append('b_adr2', this.baddr2);
      formData.append('b_memo', this.bmemo);
      formData.append('b_cash_type1', this.cash_type_o_btn);
      formData.append('b_cash_type2', this.cash_type_t_btn);
      formData.append('b_recipe_type', this.cash_type_tr_btn);
      formData.append('b_recipe_tel', this.recipe_tel);
      formData.append('b_recipe_company', this.recipe_com);
      formData.append('add_order_text', this.additionalOrder);
      this.$axios.post(this.$store.state.apiEndpoint, formData)
          .then(response => {
                this.$router.replace({
                  name: 'order_complite'
                    , params: { idx: response.data.order_idx }
                  });
          })
          .catch(error => {
            this.$modal.errorToast(error);
            this.oneclick = false;
          }).finally(() => {
        this.oneclick = false;
      });
    },
    selectaddr(){
      this.$router.replace({
        name: 'Orderaddr', // 이동할 페이지의 라우터 이름
      });
    },
    searchaddr(){
      this.$router.replace({
        name: 'order_addr_search', // 이동할 페이지의 라우터 이름
      });
    },
    cash_type_o(buttonNumber) {
      this.cash_type_o_btn = buttonNumber;
    },
    cash_type_t(buttonNumber) {
      this.cash_type_t_btn = buttonNumber;
    },
    cash_type_tr(buttonNumber) {
      this.cash_type_tr_btn = buttonNumber;
    },
    toggle_mart_prd_listbtn(){
      this.toggle_mart_prd_list = !this.toggle_mart_prd_list;
    },
    fetchProducts(mart_order,add_text) {
      let formData = new FormData();
      formData.append('mode', 'order_page');
      formData.append('martid', this.$store.state.martid);
      formData.append('token', this.$store.state.token);
      formData.append('lf', this.$store.state.lf);
      formData.append('mart_order', mart_order);
      formData.append('b2b_order', "");
      formData.append('add_order_text', add_text);
      formData.append('addr_idx', this.$store.state.cart_addr_idx);
      this.$axios.post(this.$store.state.apiEndpoint, formData)
          .then(response => {
            this.products = response.data.data;

            this.order_id = this.products.order_id;

            const orderData = localStorage.getItem('order_b_data');
            const parsedData = JSON.parse(orderData);

            if ((!this.bname || this.$store.state.cart_addr_idx) && (parsedData && (parsedData.bname === undefined || parsedData.bname === ''))) this.bname = this.products.user_data.user_name;
            if ((!this.btel || this.$store.state.cart_addr_idx) && (parsedData && (parsedData.btel === undefined || parsedData.btel === ''))) this.btel = this.products.user_data.user_phone;
            if ((!this.baddr1 || this.$store.state.cart_addr_idx) && (parsedData && (parsedData.baddr1 === undefined || parsedData.baddr1 === ''))) this.baddr1 = this.products.user_data.user_adr1;
            if ((!this.baddr2 || this.$store.state.cart_addr_idx) && (parsedData && (parsedData.baddr2 === undefined || parsedData.baddr2 === ''))) this.baddr2 = this.products.user_data.user_adr2;
            if ((!this.bmemo || this.$store.state.cart_addr_idx) && (parsedData && (parsedData.bmemo === undefined || parsedData.bmemo === ''))) this.bmemo = this.products.user_data.user_memo;
            this.mart_account = this.products.mart_account;
            this.mart_prd_count = 0;
            this.total_prd = this.products.mart_cart_list.reduce((total, item) => {
                               this.mart_prd_count = this.mart_prd_count + 1;
                              const ctPrice = parseInt(item.ct_price, 10);
                              const ctQty = parseInt(item.ct_qty, 10);
                              if (!isNaN(ctPrice) && !isNaN(ctQty)) {
                                return total + ctPrice * ctQty;
                              }
                              return total;
                            }, 0);
            this.total_deliver = this.products.mart_deliver_p;
            if (this.products.mart_deliver_type == "조건부무료배송"){
                if(this.total_prd >= this.products.mart_deliver_jo){
                  this.total_deliver = 0;
                }
                if (this.additionalOrder != ""){
                  this.jotext = true;
                  this.total_deliver = 0;
                }
            }else if (this.products.mart_deliver_type == "무료배송"){
                this.total_deliver = 0;
            }


          })
          .catch(error => {
            this.$modal.errorToast(error);
          }).finally(() => {
        this.isLoading = false; // 요청이 끝나면 로딩 상태를 false로 설정
      });
    },
    handleImageError(product) {
      product.it_img1 = require('@/assets/img/no_img.gif');
      this.$forceUpdate();
    },
    loadOrderData() {
      const orderData = localStorage.getItem('order_b_data');
      if (orderData) {
        const parsedData = JSON.parse(orderData);
        this.cash_type_o_btn = parsedData.cash_type_o_btn || 1;
        this.cash_type_t_btn = parsedData.cash_type_t_btn || 1;
        this.cash_type_tr_btn = parsedData.cash_type_tr_btn || 1;
        this.bname = parsedData.bname || "";
        this.btel = parsedData.btel || "";
        this.baddr1 = parsedData.baddr1 || "";
        this.baddr2 = parsedData.baddr2 || "";
        this.bmemo = parsedData.bmemo || "";
        this.recipe_tel = parsedData.recipe_tel || "";
        this.recipe_com = parsedData.recipe_com || "";
      }
    },
    saveOrderData() {
      const orderData = {
        cash_type_o_btn: this.cash_type_o_btn,
        cash_type_t_btn: this.cash_type_t_btn,
        cash_type_tr_btn: this.cash_type_tr_btn,
        bname: this.bname,
        btel: this.btel,
        baddr1: this.baddr1,
        baddr2: this.baddr2,
        bmemo: this.bmemo,
        recipe_tel: this.recipe_tel,
        recipe_com: this.recipe_com,
      };
      localStorage.setItem('order_b_data', JSON.stringify(orderData));
    },
  },
  watch: {
    cash_type_o_btn: 'saveOrderData',
    cash_type_t_btn: 'saveOrderData',
    cash_type_tr_btn: 'saveOrderData',
    bname: 'saveOrderData',
    btel: 'saveOrderData',
    baddr1: 'saveOrderData',
    baddr2: 'saveOrderData',
    bmemo: 'saveOrderData',
    recipe_tel: 'saveOrderData',
    recipe_com: 'saveOrderData',
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.warning_info{
  border: 1px solid #F99EA3;
  border-radius: 6px;
}

.menu-content {
  transition: max-height 0.3s ease;
  overflow: hidden;
  max-height: 100%; /* 초기 상태 */
}
.menu-content-inactive {
  max-height: 0px; /* 확장될 때의 최대 높이 */
}




.add_text_btn{
  border: 1px solid var(--prime-color);
  border-radius: 6px;
}



.bottom_btn{

  position: fixed; /* 절대 위치 */
  bottom: 0px; /* 하단 여백 조정 */
  left: 0;
  z-index: 10; /* 다른 요소 위에 표시되도록 설정 */
  width: 100%; /* 스티커 너비 조정 */
  text-align: center; /* 텍스트 중앙 정렬 */
}


.nodata{
  display: flex;
  flex-direction: column; /* 자식 요소들을 수직 방향으로 정렬 */
  justify-content: center; /* 수직 방향(행)에서 중앙 정렬 */
  align-items: center; /* 수평 방향(열)에서 중앙 정렬 */
  height: 100%; /* 부모 요소의 전체 높이를 차지하도록 설정 */
  text-align: center; /* 텍스트를 중앙 정렬 */
  margin-top: 25vh;
}
.no_list{
  max-width: 100%; /* 이미지가 컨테이너 너비를 초과하지 않도록 */
  height: auto; /* 이미지의 비율을 유지하면서 너비에 맞춰 높이 조정 */
}
.no_list_text{
  color: var(--prime-font-inactive);
  margin-top: 20px; /* 이미지와 텍스트 사이의 간격 조정 */
}

</style>
