<template>
  <div class="youtube">
    <iframe width="100%" height="100%" src="{{url}}" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
      default: ''
    },
  },
};
</script>

<style scoped>
.youtube{
  width: calc(100% - 20px);
  height: 70vh;
  margin: 10px;
}
</style>