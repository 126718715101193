<template>
  <div class="user-page-container">
    <div class="profile-section">
      <div class="avatar-container">
        <img src="@/assets/img/userview/user.png" alt="User avatar" class="user-avatar">
      </div>
      <div v-if="this.$store.state.login_flag == 1" class="user-name">{{ this.$store.state.user_name }} 님</div>
      <div v-if="this.$store.state.login_flag == 0" class="user-name">안녕하세요 고객님</div>
      <div v-if="this.$store.state.login_flag == 1" class="user-greeting">{{ this.$store.state.mart_name }}에 오신것을 환영해요!</div>
      <div v-if="this.$store.state.login_flag == 0" class="user-greeting">로그인하시면 많은 혜택이 있습니다.</div>
    </div>
    <div class="top-bar">
      <div class="o-box-l">
        <span>포인트</span>
        <span class="point-count">{{ user_point }} P</span>
      </div>
      <div class="o-box-r" @click="goTocoupon">
        <span>쿠폰</span>
        <span class="point-count">{{ this.$store.state.Coupon_Quantity }} 장</span>
      </div>
    </div>
    <!-- 메뉴 옵션 리스트 -->
    <div class="menu-options">
      <div class="menu-option" style="border-top: 1px solid #eaeaea;" @click="goTooorder">
        <div>
          <img src="@/assets/img/userview/order.svg" />
          주문내역
        </div>
        <img src="@/assets/img/userview/next_arrow.svg" alt=">" class="arrow-icon" />
      </div>
      <div class="menu-option" @click="goToadr">
        <div>
          <img src="@/assets/img/userview/adr_icon.svg" />
          배송지 관리
        </div>
        <img src="@/assets/img/userview/next_arrow.svg" alt=">" class="arrow-icon" />
      </div>
      <div class="menu-option" @click="goTodeliverinfo">
        <div>
          <img src="@/assets/img/userview/deliver.svg" />
          마트,배송 안내
        </div>
        <img src="@/assets/img/userview/next_arrow.svg" alt=">" class="arrow-icon" />
      </div>
      <div class="menu-option" @click="goToalarm">
        <div>
          <img src="@/assets/img/userview/notifi.svg" />
          알림 설정
        </div>
        <img src="@/assets/img/userview/next_arrow.svg" alt=">" class="arrow-icon" />
      </div>
    </div>
  </div>


  <div class="bottom-bar">
      <span class="logout" @click="logout()">로그아웃</span>
  </div>


</template>


<script>
export default {
  name: 'UserView',
  created() {
    this.$store.dispatch('Showheaderfooter', {
      Title: '내 정보',
      showBack: false,
      showAlarm: false,
      showCart: false,
      showsheader: true,
      showFooter: true,
    });
  },
  data() {
    return {
      user_point: 0,
    }
  },
  mounted() {
    // 페이지가 로드될 때 데이터를 새로고침하는 메소드를 호출합니다.
    this.refreshData();
  },
  methods: {
    refreshData(){
      this.$store.dispatch('header_refreash').then(() => {
      }).catch(error => {
        console.error("header_refreash 처리 에러:", error);
      });
    },
    logout(){
        this.$modal.logoutModal().then((result) => {
          if (result.isConfirmed) {
            localStorage.removeItem('token');
            window.location.reload();
          }
        });
    },
    goTopoint() {
        this.$router.push('/user/point');
    },
    goTocoupon() {
      if (this.$store.state.login_flag == 0){
        this.$modal.loginPrompt("사용 가능한 쿠폰을 조회하려면 로그인이 필요합니다.").then((result) => {
          if (result.isConfirmed) {
            this.$router.push('/login');
          }
        });
      }else {
        this.$router.push('/user/coupon');
      }
    },
    goTooorder() {
      if (this.$store.state.login_flag == 0){
        this.$modal.loginPrompt("주문 내역을 확인하기위해선 로그인이 필요합니다.").then((result) => {
          if (result.isConfirmed) {
            this.$router.push('/login');
          }
        });
      }else {
        this.$router.push('/user/order');
      }
    },
    goToadr() {
        if (this.$store.state.login_flag == 0){
          this.$modal.loginPrompt("주소를 편집하기 위해선 로그인이 필요합니다.").then((result) => {
            if (result.isConfirmed) {
              this.$router.push('/login');
            }
          });
        }else {
          this.$router.push('/user/adr');
      }

    },
    goTodeliverinfo() {
          this.$router.push('/user/deliverinfo');
    },
    goToalarm() {
        if (this.$store.state.login_flag == 0){
          this.$modal.loginPrompt("알람 설정을 위해선 로그인이 필요합니다.").then((result) => {
            if (result.isConfirmed) {
              this.$router.push('/login');
            }
          });
        }else {
          this.$router.push('/user/alarm');
        }
    },
  },
}
</script>


<style scoped>

.point-count {
  display: block; /* 또는 기본값을 그대로 사용하세요 */
  margin-left: 8px; /* 위아래 여백을 제거하고 싶을 경우 */
}
.bottom-bar {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* 필요한 높이로 설정하세요 */
}
.logout{
  color: #888888;
  font-size: 12px;
  text-decoration: underline;
}
.user-page-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}


.profile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 0;
}

.avatar-container {
  width: 100px;
  padding: 0.5em;
  background-color: #ececec;
  border-radius: 50%;
  overflow: hidden; /* 이 부분 추가 */
  display: inline-block; /* 정렬 문제를 방지하기 위해 추가 */
  line-height: 0; /* 이미지 주변의 여백을 제거하기 위해 추가 */
}

.avatar-container img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}
.user-name {
  margin-top: 0.5em;
  font-weight: bold;
}

.user-greeting {
  color: #888;
}

.menu-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 1.2em 1em 1.2em;
  border-bottom: 1px solid #ececec;
  font-size: 16px;
  /* 여기에 기타 스타일링 속성 추가 */
}

.arrow-icon {
  /* 화살표 아이콘 스타일링 */
  height: 30px; /* 또는 원하는 크기 */
  width: auto;
  /* margin-left를 제거하고 flexbox에 의존하여 정렬 */
}


.top-bar {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #fff;
  margin-bottom: 20px;
}

.o-box-l {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #eaeaea;
  border-top-left-radius: 20px;     /* 왼쪽 위 모서리 */
  border-bottom-left-radius: 20px;  /* 왼쪽 아래 모서리 */
}
.o-box-r {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #eaeaea;
  border-top-right-radius: 20px;    /* 오른쪽 위 모서리 */
  border-bottom-right-radius: 20px; /* 오른쪽 아래 모서리 */
}
</style>
