<template>
  <!--
  <div class="radio_tab" style="margin-top: 14px">
    <div class="grow1 grow-center tab-left active-tab-color">
      <span class="active-font-color">마트</span>
    </div>
    <div class="grow1 grow-center tab-mid inactive-tab-color">
      <span class="inactive-font-color">b2b</span>
    </div>
  </div>
  -->
  <div v-if="isLoading" class="loading-div">
    <img class="loading_gif" src="@/assets/img/loading.gif" alt="로딩 중..." />
  </div>
  <div v-else>
  <div v-if="products.length == 0" class="nodata">
    <img class="no_list" src="@/assets/img/svg/no_list.svg" />
    <span class="no_list_text">주문내역이 없습니다.</span>
  </div>
  <div v-else class="product-list">
    <div v-for="product in products" :key="product.idx" class="flex-col">
      <div class="flex-row" style="margin-top: 2em;padding:0.6em 0em 0.6em 0em;background-color:#D8F6F6;">
        <div class="col-1"></div>
        <div class="col-4 active-font-16-b">마트배송</div>
        <div class="col-6 grow-right active-font-14" @click="orderdetail(product.idx)">상세 내역 보기</div>
      </div>
      <div class="flex-row" style="padding:0.6em 0em 0.6em 0em;">
        <div class="col-1"></div>
        <div class="col-7 grow-left"><span class="active-font-16" style="margin-left: 0.6em;">{{product.B2}}</span></div>
        <div class="col-3 grow-right">
          <span v-if="product.status =='주문'" class="band-order">주문</span>
          <span v-else-if="product.status =='취소'" class="band-cencel">취소</span>
          <span v-else-if="product.status =='준비'" class="band-ready">준비</span>
          <span v-else-if="product.status =='완료'" class="band-complite">완료</span>
        </div>
      </div>
      <div class="flex-row " style="padding:0.6em 0em 0.6em 0em;">
        <div class="col-1 "></div>
        <div class="col-3 "><img style="max-width: 100%;height: auto" :src="product.img" /></div>
        <div class="col-7 flex-col " style="margin-left:1em;margin-right:30px">
          <div class="active-font-16" style="margin-bottom:6px">{{product.title}} {{product.title_ad}}</div>
          <div class="inactive-font-14" style="margin-bottom:6px">주문번호 : {{product.idx}}</div>
          <div class="flex-row">
            <div class="col-6 grow-left active-font-16">
              결제금액
            </div>
            <div class="col-6 grow-right active-font-16-b">
              {{$formatPrice(product.total_price)}}원
            </div>
          </div>
        </div>
      </div>
      <div v-if="product.status =='주문'" class="order-cencel-btn grow-center active-font-16" @click="ordercencelmodal(product.idx)">
        주문 취소
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      products: [],
      allcheck:false,
      now_cnt:0,
      o_day:3,
      isLoading:true,
    }
  },
  created() {
    this.fetchProducts();
    this.$store.dispatch('Showheaderfooter', {
      Title: '주문내역',
      showBack: true,
      showAlarm: false,
      showCart: false,
      showsheader: true,
      showFooter: false,
      enableScrollHeader: true,
    });
  },

  methods: {
    orderdetail(cpidx){
      this.$router.push({ name: 'order_detail', params: { cpidx: cpidx }});
    },
    ordercencelmodal(cpidx){
      this.$modal.ordercencelModal().then((result) => {
        if (result.isConfirmed) {
          this.ordercencel(cpidx);
        }
      });
    },
    ordercencel(couponidx) {
      let formData = new FormData();
      formData.append('mode', 'order_list_func');
      formData.append('funcs', 'order_cencel');
      formData.append('martid', this.$store.state.martid);
      formData.append('token', this.$store.state.token);
      formData.append('idx', couponidx);
      formData.append('w', "mart");
      this.$axios.post(this.$store.state.apiEndpoint, formData)
          .then(response => {
            this.fetchProducts();
            console.log(response);
          })
          .catch(error => {
            this.$modal.errorToast(error);
          });
    },
    fetchProducts() {
      let formData = new FormData();
      formData.append('mode', 'app_order_list');
      formData.append('martid', this.$store.state.martid);
      formData.append('token', this.$store.state.token);
      formData.append('now', this.now_cnt);
      formData.append('day', this.o_day);
      this.$axios.post(this.$store.state.apiEndpoint, formData)
          .then(response => {
            this.products = response.data.data;
            console.log(this.products);
          })
          .catch(error => {
            this.$modal.errorToast(error);
          }).finally(() => {
        this.isLoading = false; // 요청이 끝나면 로딩 상태를 false로 설정
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.order-cencel-btn{
  margin: 0.2em 2em 0em 2em;
  padding: 0.8em;
  border: 2px solid #e0e0e0;
  border-radius: 0.6rem;
}
.band-order{
  background-color: #EAFBFB;
  border-radius: 0.2rem;
  color: #2E8A8A;
  display: inline-block;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  padding: 0.35em 0.65em;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}
.band-complite{
  background-color: #ffe6bf;
  border-radius: 0.2rem;
  color: #d78b11;
  display: inline-block;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  padding: 0.35em 0.65em;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}
.band-paycomplite{
  background-color: #EFEFEF;
  border-radius: 0.2rem;
  color: #13cccc;
  display: inline-block;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  padding: 0.35em 0.65em;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}
.band-cencel{
  background-color: #EFEFEF;
  border-radius: 0.2rem;
  color: #6A6A6A;
  display: inline-block;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  padding: 0.35em 0.65em;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}
.band-ready{
  background-color: #ffbfdc;
  border-radius: 0.2rem;
  color: #c2296d;
  display: inline-block;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  padding: 0.35em 0.65em;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}
.tab-left{
  margin-left: 20px;
  height: 40px;
  border: 1px solid var(--prime-color);
  border-top-left-radius: 8px;     /* 왼쪽 위 모서리 */
  border-bottom-left-radius: 8px;  /* 왼쪽 아래 모서리 */
  font-size: 16px;
  font-family: 'Noto Sans CJK KR';
}
.tab-mid{
  height: 40px;
  border-top: 1px solid var(--prime-color);
  border-bottom: 1px solid var(--prime-color);
  border-right: 1px solid var(--prime-color);
  font-size: 16px;
  font-family: 'Noto Sans CJK KR';
}
.tab-right{
  margin-right: 20px;
  height: 40px;
  border-top: 1px solid var(--prime-color);
  border-bottom: 1px solid var(--prime-color);
  border-right: 1px solid var(--prime-color);
  border-top-right-radius: 8px;     /* 왼쪽 위 모서리 */
  border-bottom-right-radius: 8px;  /* 왼쪽 아래 모서리 */
  font-size: 16px;
  font-family: 'Noto Sans CJK KR';
}
.del_btn{
  padding: 0.8em 1.5em 0.8em 1.5em;
  color: var(--prime-font-active);
  font-size: 14px;
  text-decoration: underline;
}
.ui_check_text{
  padding: 0em 0em 0em 0.5em;
  color: var(--prime-font-active);
  font-size: 16px;
  font-weight: 500;
}

.select_del_tab{
  width: 100%;
  display: flex;
  background-color: #F6F6F6;
}

.radio_tab{
  width: 100%;
  display: flex;
}
.nodata{
  display: flex;
  flex-direction: column; /* 자식 요소들을 수직 방향으로 정렬 */
  justify-content: center; /* 수직 방향(행)에서 중앙 정렬 */
  align-items: center; /* 수평 방향(열)에서 중앙 정렬 */
  height: 100%; /* 부모 요소의 전체 높이를 차지하도록 설정 */
  text-align: center; /* 텍스트를 중앙 정렬 */
  margin-top: 25vh;
}
.no_list{
  max-width: 100%; /* 이미지가 컨테이너 너비를 초과하지 않도록 */
  height: auto; /* 이미지의 비율을 유지하면서 너비에 맞춰 높이 조정 */
}
.no_list_text{
  color: var(--prime-font-inactive);
  margin-top: 20px; /* 이미지와 텍스트 사이의 간격 조정 */
}

</style>
