<template>
  <div class="switch-container">
    <input
        class="switch"
        type="checkbox"
        v-model="isChecked"
        :disabled="disabled"
    />
    <span
        class="switch switch-default"
        :style="switchStyle"
        @click="toggleCheck"
    >
      <small :style="smallStyle"></small>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: Boolean,
    disabled: Boolean
  },
  emits: ['update:modelValue', 'change'],
  computed: {
    isChecked: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },
    switchStyle() {
      return this.isChecked
          ? {
            backgroundColor: 'rgb(100, 189, 99)',
            borderColor: 'rgb(100, 189, 99)',
            boxShadow: 'rgb(100, 189, 99) 0px 0px 0px 15.5px inset',
            transition: 'border 0.4s ease, box-shadow 0.4s ease, background-color 1.2s ease',
          }
          : {
            backgroundColor: '#E0523B',
            borderColor: 'rgb(223, 223, 223)',
            boxShadow: 'rgb(223, 223, 223) 0px 0px 0px 0px inset',
            transition: 'border 0.4s ease, box-shadow 0.4s ease',
          };
    },
    smallStyle() {
      return {
        left: this.isChecked ? '20px' : '0px',
        transition: 'background-color 0.4s ease, left 0.2s ease',
        backgroundColor: 'rgb(255, 255, 255)',
        border: '1px solid var(--inactive-color2)',

      };
    }
  },
  methods: {
    toggleCheck() {
      if (!this.disabled) {
        this.isChecked = !this.isChecked;
        // 여기에서도 change 이벤트를 방출합니다.
        this.$emit('change', this.isChecked);
      }
    },
  }
};
</script>

<style scoped>
.switch-container {
  display: inline-block;
  position: relative;

}

.switch {
  display: none;
}

.switch-default {
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: 45px; /* 스위치의 너비 */
  height: 25px; /* 스위치의 높이 */
  vertical-align: middle;
  border-radius: 20px;
}

.switch-default small {
  position: absolute;
  width: 24px; /* 스위치 핸들의 너비 */
  height: 24px; /* 스위치 핸들의 높이 */
  border-radius: 50%;
  background: white;
  top: 0;
  left: 0;
}
</style>
