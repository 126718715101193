<template>
  <div style="padding: 30px">
    <div v-html="policy_text" style="line-height: 1.8;"></div>
  </div>
</template>


<script>
export default {
  name: 'p_text',
  data() {
    return {
      products: [],
      isLoading:true,
      policy:"",
      policy_text:"",
    }
  },
  created() {
    this.policy = this.$route.params.policy;
    this.fetchProducts();
    this.$store.dispatch('Showheaderfooter', {
      Title: this.policy,
      showBack: true,
      showAlarm: false,
      showCart: false,
      showsheader: true,
      showFooter: false,
      enableScrollHeader: false,
    });
  },
  methods: {
    fetchProducts() {
      let formData = new FormData();
      formData.append('mode', 'appset');
      formData.append('martid', this.$store.state.martid);
      formData.append('keys', this.policy);
      this.$axios.post(this.$store.state.apiEndpoint, formData)
          .then(response => {
            this.policy_text = response.data.data.replace(/\r\n/g, '</br>');
          })
          .catch(error => {
            this.$modal.errorToast(error);
          }).finally(() => {
        this.isLoading = false; // 요청이 끝나면 로딩 상태를 false로 설정
      });
    }
  }
}
</script>

<style scoped>

.point-count {
  display: block; /* 또는 기본값을 그대로 사용하세요 */
  margin-left: 8px; /* 위아래 여백을 제거하고 싶을 경우 */
}
.bottom-bar {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px; /* 필요한 높이로 설정하세요 */
}
.logout{
  color: #888888;
  font-size: 12px;
}
.user-page-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  background-color: #f8f8f8;
}

.profile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 0;
}

.avatar-container {
  width: 100px;
  padding: 0.5em;
  background-color: #ececec;
  border-radius: 50%;
  overflow: hidden; /* 이 부분 추가 */
  display: inline-block; /* 정렬 문제를 방지하기 위해 추가 */
  line-height: 0; /* 이미지 주변의 여백을 제거하기 위해 추가 */
}

.avatar-container img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}
.user-name {
  margin-top: 0.5em;
  font-weight: bold;
}

.user-greeting {
  color: #888;
}

.menu-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 1.2em 1em 1.2em;
  border-bottom: 1px solid #ececec;
  font-size: 16px;
  /* 여기에 기타 스타일링 속성 추가 */
}

.arrow-icon {
  /* 화살표 아이콘 스타일링 */
  height: 30px; /* 또는 원하는 크기 */
  width: auto;
  /* margin-left를 제거하고 flexbox에 의존하여 정렬 */
}


.top-bar {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #fff;
  border-bottom: 1px solid #eaeaea;
}

.o-box-l {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #eaeaea;
  border-top-left-radius: 20px;     /* 왼쪽 위 모서리 */
  border-bottom-left-radius: 20px;  /* 왼쪽 아래 모서리 */
}
.o-box-r {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #eaeaea;
  border-top-right-radius: 20px;    /* 오른쪽 위 모서리 */
  border-bottom-right-radius: 20px; /* 오른쪽 아래 모서리 */
}
</style>
