<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="ico_tab_3">
      <g id="그룹_30" data-name="그룹 30">
        <path id="패스_38" data-name="패스 38" d="M21.639.547a.721.721,0,0,0-.7-.547H2.166a.721.721,0,0,0-.7.547L.022,6.324A.706.706,0,0,0,0,6.5,3.662,3.662,0,0,0,1.444,9.431V19.495a.722.722,0,0,0,.722.722h7.22a.722.722,0,0,0,.722-.722V14.441H13v5.054a.722.722,0,0,0,.722.722h7.22a.722.722,0,0,0,.722-.722V9.431A3.662,3.662,0,0,0,23.1,6.5a.706.706,0,0,0-.022-.175Zm-18.908.9H20.376l1.285,5.138A1.991,1.991,0,1,1,17.69,6.5a.722.722,0,1,0-1.444,0A2.084,2.084,0,0,1,14.26,8.664,2.084,2.084,0,0,1,12.274,6.5a.722.722,0,0,0-1.444,0A2.084,2.084,0,0,1,8.845,8.664,2.084,2.084,0,0,1,6.859,6.5a.722.722,0,0,0-1.444,0A2.084,2.084,0,0,1,3.43,8.664,2.077,2.077,0,0,1,1.445,6.582ZM20.217,18.773H14.441V13.719A.722.722,0,0,0,13.719,13H9.386a.722.722,0,0,0-.722.722v5.054H2.888V10.051a3.2,3.2,0,0,0,.542.058,3.368,3.368,0,0,0,2.708-1.4,3.323,3.323,0,0,0,5.415,0,3.323,3.323,0,0,0,5.415,0,3.368,3.368,0,0,0,2.708,1.4,3.2,3.2,0,0,0,.542-.058Z" transform="translate(0.06)" :fill="currentColor" :stroke="currentColor" stroke-width="0.1"/>
      </g>
    </g>
  </svg>

</template>

<script>
export default {
  props: ['isActive'],
  computed: {
    currentColor() {
      return this.isActive ? 'var(--active-button-color)' : 'var(--inactive-color)';
    },
  }
};
</script>