<template>
  <div class="flex-row flex-wrap" :class="getClass(count)" style="align-content: baseline;">
    <div class="col-12" style="position: relative;">
      <img :src="img" @error="noImage" style="width: 100%;height: auto">
      <span class="cart-btn" style="position: absolute;bottom:14px;right: 14px;height: 28px;width: 28px;display: flex;align-content: center;justify-content: center;" @click="handleAddToCart">
        <img src="@/assets/img/svg/cart.svg" style="width: 30px;"/></span>
    </div>
    <span class="prd-name col-12">{{title}}</span>
    <div v-if="nor_price > 0"><span class="sale-text">할인가</span><span class="sale-per">{{per}}</span><span class="sale-per" style="text-decoration:line-through;">{{$formatPrice(nor_price)}}원</span></div>
    <span class="prd-price col-12">{{$formatPrice(price)}}원</span>
    <span class="prd-desc col-12">{{desc}}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: '상품명'
    },
    nor_price: {
      type: Number,
      required: true,
      default: 0
    },
    per: {
      type: String,
      required: true,
      default: '정상가'
    },
    price: {
      type: Number,
      required: true,
      default: 0
    },
    desc: {
      type: String,
      required: true,
      default: '상품설명'
    },
    img: {
      type: String,
      required: true,
      default: '' // 기본값
    },
    idx: {
      type: Number,
      required: true,
      default: null
    },
    count: {
      type: Number,
      required: true,
      default: null
    },
  },
  emits: ['addToCart'], // 커스텀 이벤트 명시
  methods: {
    noImage(event) {
      event.target.onerror = null;
      event.target.src = require('@/assets/img/no_img.gif');
    },
    handleAddToCart() {
      this.$emit('addToCart', this.idx,this.nor_price,this.price,this.img,this.title);
    },
    getClass(count) {
      return count % 2 === 0 ? 'left-border' : 'right-border';
    }
  }
};
</script>

<style scoped>
.left-border{
  border-bottom: 1px solid #ececec;
  border-right: 1px solid #ececec;
}
.right-border{
  border-bottom: 1px solid #ececec;
}

.prd-name{
  color:#222B34;
  text-align: left;
  font-size: 16px;
  font-family: 'Noto Sans CJK KR';
  padding: 10px 10px 5px 10px;
}

.prd-price{
  padding: 0px 10px 5px 10px;
  color:#ee311c;
  text-align: left;
  font-size: 18px;
  font-family: 'Noto Sans CJK KR Bold';
}
.prd-desc{
  padding: 0px 10px 5px 10px;
  color:#0D820D;
  text-align: left;
  font-size: 16px;
  font-family: 'Noto Sans CJK KR Bold';
}
.sale-text{
  padding: 0px 5px 5px 10px;
  color:#ee311c;
  text-align: left;
  font-size: 14px;
  font-family: 'Noto Sans CJK KR';
}
.sale-per{
  padding: 0px 5px 5px 2px;
  color:#7A8692;
  text-align: left;
  font-size: 14px;
  font-family: 'Noto Sans CJK KR';
}
.cart-btn{
  display: inline-block;
  background: #fff;
  padding: 10px;
  border-radius: 50%;
  color: var(--prime-color);
  box-shadow: 1px 1px 5px #606060;
}
</style>