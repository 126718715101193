<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 26 26">
    <g transform="translate(0 1.0)">
      <path id="패스_10" data-name="패스 10" d="M75.564,11.878a5.746,5.746,0,0,0,4.2-1.74,5.747,5.747,0,0,0,1.74-4.2,5.747,5.747,0,0,0-1.74-4.2,5.938,5.938,0,0,0-8.4,0,5.747,5.747,0,0,0-1.74,4.2,5.747,5.747,0,0,0,1.74,4.2,5.748,5.748,0,0,0,4.2,1.74M72.387,2.762a4.493,4.493,0,0,1,6.355,0,4.294,4.294,0,0,1,1.317,3.177,4.294,4.294,0,0,1-1.317,3.178,4.492,4.492,0,0,1-6.355,0A4.293,4.293,0,0,1,71.07,5.939a4.293,4.293,0,0,1,1.317-3.177" transform="translate(-65.433)" :fill="currentColor" :stroke="currentColor" stroke-width="0.1"/>
      <path id="패스_11" data-name="패스 11" d="M20.523,204.9a14.679,14.679,0,0,0-.2-1.558,12.263,12.263,0,0,0-.383-1.567,7.752,7.752,0,0,0-.644-1.461,5.5,5.5,0,0,0-.971-1.265,4.282,4.282,0,0,0-1.4-.876,4.82,4.82,0,0,0-1.781-.322,1.807,1.807,0,0,0-.965.409c-.289.189-.628.407-1.006.648a5.761,5.761,0,0,1-1.3.573,5.051,5.051,0,0,1-3.183,0,5.741,5.741,0,0,1-1.3-.573c-.374-.239-.713-.457-1.007-.649a1.808,1.808,0,0,0-.965-.409,4.815,4.815,0,0,0-1.781.323,4.277,4.277,0,0,0-1.4.877,5.51,5.51,0,0,0-.971,1.265,7.753,7.753,0,0,0-.644,1.461,12.248,12.248,0,0,0-.383,1.566,14.567,14.567,0,0,0-.2,1.559c-.033.471-.049.961-.049,1.456a4.1,4.1,0,0,0,1.216,3.1A4.378,4.378,0,0,0,4.35,210.6H16.223a4.379,4.379,0,0,0,3.134-1.143,4.093,4.093,0,0,0,1.216-3.1c0-.5-.017-.987-.05-1.457M18.36,208.41a2.947,2.947,0,0,1-2.137.745H4.35a2.946,2.946,0,0,1-2.137-.745,2.674,2.674,0,0,1-.768-2.051c0-.462.015-.918.046-1.356a13.125,13.125,0,0,1,.181-1.4,10.83,10.83,0,0,1,.337-1.381,6.3,6.3,0,0,1,.524-1.188,4.081,4.081,0,0,1,.714-.935,2.836,2.836,0,0,1,.928-.577,3.323,3.323,0,0,1,1.138-.219c.051.027.141.078.287.173.3.194.639.415,1.018.656a7.172,7.172,0,0,0,1.633.73,6.5,6.5,0,0,0,4.072,0,7.173,7.173,0,0,0,1.634-.73c.388-.248.72-.462,1.017-.656.146-.1.236-.147.287-.173a3.325,3.325,0,0,1,1.138.219,2.844,2.844,0,0,1,.928.577,4.071,4.071,0,0,1,.714.935,6.3,6.3,0,0,1,.524,1.188A10.833,10.833,0,0,1,18.9,203.6a13.193,13.193,0,0,1,.181,1.4h0c.031.436.046.892.046,1.356a2.674,2.674,0,0,1-.768,2.051" transform="translate(0 -185.941)" :fill="currentColor" :stroke="currentColor" stroke-width="0.1"/>
    </g>
  </svg>


</template>

<script>
export default {
  props: ['isActive'],
  computed: {
    currentColor() {
      return this.isActive ? 'var(--active-button-color)' : 'var(--inactive-color)';
    },
  }
};
</script>
