import { createRouter, createWebHistory } from 'vue-router';
import store from '../store'; // Vuex store를 가정

import Home from '@/pages/Home.vue';

import SearchView from '@/pages/Search_view.vue';
import SearchresultView from '@/pages/search_page/Search_result_page.vue';
import Prddetail from '@/pages/search_page/prd_detail.vue';


import CateView from '@/pages/Cate_view.vue';
import Userview from '@/pages/User_view.vue';

import Cartpage from '@/pages/order/Cart_Page.vue';
import Orderpage from '@/pages/order/order_Page.vue';
import orderaddr from '@/pages/order/order_addr.vue';
import orderaddrsearch from '@/pages/order/adr_search.vue';
import orderaddrlocal from '@/pages/order/adr_local.vue';
import order_complite from '@/pages/order/order_complite.vue';


import Alarmpage from '@/pages/header/Alarm_page.vue';

//이하 내 정보 하위 페이지
import Pointlist from '@/pages/user/pointlist_Page.vue';
import Couponlist from '@/pages/user/couponlist_Page.vue';
import use_coupon from '@/pages/header/coupon_use.vue';


import Orderlist from '@/pages/user/orderlist_Page.vue';
import OrderDetail from '@/pages/user/orderdetail_Page.vue';


//로그인 , 가입관련
import login_join from '@/pages/login/login.vue';
import policy from '@/pages/login/policy.vue';


import Adrlist from '@/pages/user/adrlist_Page.vue';
import Adrsearch from '@/pages/user/adr_search.vue';

import Adrlistedit from '@/pages/user/adrlist_edit_Page.vue';
import Adrdetailn from '@/pages/user/adrlist_detail_Page.vue';
import Adrdetailm from '@/pages/user/adrlist_detail_Page.vue';
import Adrlocal from '@/pages/user/adr_local_Page.vue';


import Deliverinfo from '@/pages/user/deliverinfo_Page.vue';
import Alarmsetting from '@/pages/user/alarmsetting_Page.vue';



// 라우트 정의
const routes = [
    {
        path: '/:martid?',
        name: 'Home',
        component: Home,
        meta: { requiresAuth: false }
    },
    {
        path: '/login',
        name: 'login_join',
        component: login_join,
        meta: { requiresAuth: false }
    },
    {
        path: '/policy/:policy',
        name: 'policy',
        component: policy,
        meta: { requiresAuth: false }
    },
    {
        path: '/cate', //
        name: 'Cate_view', //
        component: CateView,
        meta: { requiresAuth: false }
    },
    {
        path: '/search', //
        name: 'Search_view', //
        component: SearchView,
        meta: { requiresAuth: false }
    },
    {
        path: '/search/result/:keyword', //
        name: 'search_result', //
        component: SearchresultView,
        meta: { requiresAuth: false }
    },
    {
        path: '/search/prddetail/:w/:idx', //
        name: 'prddetail', //
        component: Prddetail,
        meta: { requiresAuth: false }
    },
    {
        path: '/user', // 내 정보
        name: 'User_view',
        component: Userview,
        meta: { requiresAuth: false }
    },
    {
        path: '/user/point', // 내 정보
        name: 'point',
        component: Pointlist,
        meta: { requiresAuth: true }
    },
    {
        path: '/user/coupon', // 내 정보
        name: 'coupon_list',
        component: Couponlist,
        meta: { requiresAuth: false }
    },
    {
        path: '/user/coupon/use/:idx', // 내 정보
        name: 'use_coupon',
        component: use_coupon,
        meta: { requiresAuth: true }
    },
    {
        path: '/user/order', // 내 정보
        name: 'order_list',
        component: Orderlist,
        meta: { requiresAuth: true }
    },
    {
        path: '/user/order/detail/:cpidx', // 내 정보
        name: 'order_detail',
        component: OrderDetail,
        meta: { requiresAuth: true }
    },
    {
        path: '/user/adr',
        name: 'adr_list',
        component: Adrlist,
        meta: { requiresAuth: true }
    },
    {
        path: '/user/adr/search',
        name: 'adr_search',
        component: Adrsearch,
        meta: { requiresAuth: true }
    },
    {
        path: '/user/adr/search/edit',
        name: 'adr_list_edit',
        component: Adrlistedit,
        meta: { requiresAuth: true }
    },
    {
        path: '/user/adr/search/edit/detail/:adr1/:adr2',
        name: 'adr_detail_n',
        component: Adrdetailn,
        meta: { requiresAuth: true }
    },
    {
        path: '/user/adr/search/edit/detail/:idx',
        name: 'adr_detail_m',
        component: Adrdetailm,
        meta: { requiresAuth: true }
    },
    {
        path: '/user/adr/local',
        name: 'adr_local',
        component: Adrlocal,
        meta: { requiresAuth: true }
    },
    {
        path: '/user/deliverinfo',
        name: 'deliver_info',
        component: Deliverinfo,
        meta: { requiresAuth: false }
    },
    {
        path: '/user/alarm',
        name: 'Alarm_setting',
        component: Alarmsetting,
        meta: { requiresAuth: true }
    },
    {
        path: '/cart', // 상품카트
        name: 'Cart_Page',
        component: Cartpage,
        meta: { requiresAuth: false }
    },
    {
        path: '/cart/order', //
        name: 'Order_Page',
        component: Orderpage,
        meta: { requiresAuth: true }
    },
    {
        path: '/cart/order/addr', //
        name: 'Orderaddr',
        component: orderaddr,
        meta: { requiresAuth: true }
    },
    {
        path: '/cart/order/search',
        name: 'order_addr_search',
        component: orderaddrsearch,
        meta: { requiresAuth: true }
    },
    {
        path: '/cart/order/search/local',
        name: 'order_addr_local',
        component: orderaddrlocal,
        meta: { requiresAuth: true }
    },
    {
        path: '/cart/order/order_complite/:idx',
        name: 'order_complite',
        component: order_complite,
        meta: { requiresAuth: true }
    },
    {
        path: '/alarm', // 알람 리스트
        name: 'alarm_Page',
        component: Alarmpage,
        meta: { requiresAuth: false }
    },
];


// 라우터 생성
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    // 예시로, `to` 라우트 객체의 메타 필드를 사용하여 헤더 타이틀을 설정
    const headerTitle = to.meta.headerTitle || '기본 헤더';
    store.commit('setHeaderTitle', headerTitle); // 상태를 변경하는 Vuex 뮤테이션 호출
    //console.log(`이동 from: ${from.fullPath}, to: ${to.fullPath}`);
    const access_token = localStorage.getItem('token');
    if (!access_token) {
        if (to.meta.requiresAuth) {
            console.log("로그인 정보가 없습니다.");
            next("/");
        } else {
            next();
        }
    } else {
        next();
    }

});



export default router;
