<template>
  <div v-if="isLoading" class="loading-div">
    <img class="loading_gif" src="@/assets/img/loading.gif" alt="로딩 중..." />
  </div>
  <div v-else>
    <div class="flex-row flex-wrap" style="padding: 20px">
      <div class="col-12">
        <span class="active-font-18-b" style="font-size: 22px">감사합니다!</span>
      </div>
      <div class="col-12">
        <span class="active-font-18-b" style="font-size: 22px">주문이 완료되었습니다.</span>
      </div>
      <div class="col-12" style="padding-top: 20px">
        <span class="active-font-16">주문하신 내역은 하단 <span style="color: var(--active-color)">내정보->주문내역</span> 에서 확인할수있습니다.</span>
      </div>
      <div class="col-12" style="padding-top: 20px;padding-bottom: 20px">
        <span class="active-font-16">주문번호 : 2023115454</span>
      </div>
      <div style="width:100%;height: 2px;background-color: #F6F6F6;">
      </div>
      <div class="col-12" style="padding-top: 14px">
        <span class="active-font-16">결제금액 : 2,700원</span>
      </div>
      <div class="col-12" style="padding-top: 14px">
        <span class="active-font-16">배송지 : 종로 서린동 99</span>
      </div>
      <div class="col-12" style="padding-top: 14px">
        <span class="active-font-16"><span style="color: var(--active-color)">입금 확인 및 배송준비대기 상태</span>일때는 주문 내역 상세 페이지에서 직접 주문 취소가 가능합니다.</span>
       </div>
      <div class="col-12" style="padding-top: 14px">
        <span class="active-font-16">주문/배송 및 기타 문의는 하단 <span style="color: var(--active-color)">전화문의</span> 부탁드립니다.</span>
      </div>
      <div style="width:100%;height: 2px;background-color: #F6F6F6;" class="v-margin2">
      </div>
    </div>

  </div>
  <div class="bottom_btn" @click="gohome">
    <div style="padding-top: 20px;padding-bottom: 20px;background-color: var(--prime-color);">
      <span class="active-font-16-b" style="color: #fff !important;">마트로 돌아가기</span>
    </div>
  </div>
</template>

<script>



export default {
  data() {
    return {
      isLoading:true,
      idx:0,
      products:{},
    }
  },
  created() {
    this.idx = this.$route.params.idx;
    this.fetchProducts(this.idx);
    this.$store.dispatch('Showheaderfooter', {
      Title: '주문 완료',
      showBack: false,
      showAlarm: false,
      showCart: false,
      showsheader: true,
      showFooter: false,
      enableScrollHeader: false,
    });
  },
  methods: {
    gohome(){
      this.$router.replace({
        name: 'Home'
      });
    },
    fetchProducts(idx) {
      let formData = new FormData();
      formData.append('mode', 'get_order_data');
      formData.append('martid', this.$store.state.martid);
      formData.append('token', this.$store.state.token);
      formData.append('lf', this.$store.state.lf);
      formData.append('idx', idx);
      this.$axios.post(this.$store.state.apiEndpoint, formData)
          .then(response => {
            this.products = response.data.data;
            console.log(this.products);
          })
          .catch(error => {
            this.$modal.errorToast(error);
          }).finally(() => {
        this.isLoading = false; // 요청이 끝나면 로딩 상태를 false로 설정
      });
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.warning_info{
  border: 1px solid #F99EA3;
  border-radius: 6px;
}

.menu-content {
  transition: max-height 0.3s ease;
  overflow: hidden;
  max-height: 100%; /* 초기 상태 */
}
.menu-content-inactive {
  max-height: 0px; /* 확장될 때의 최대 높이 */
}




.add_text_btn{
  border: 1px solid var(--prime-color);
  border-radius: 6px;
}



.bottom_btn{

  position: fixed; /* 절대 위치 */
  bottom: 0px; /* 하단 여백 조정 */
  left: 0;
  z-index: 10; /* 다른 요소 위에 표시되도록 설정 */
  width: 100%; /* 스티커 너비 조정 */
  text-align: center; /* 텍스트 중앙 정렬 */
}


.nodata{
  display: flex;
  flex-direction: column; /* 자식 요소들을 수직 방향으로 정렬 */
  justify-content: center; /* 수직 방향(행)에서 중앙 정렬 */
  align-items: center; /* 수평 방향(열)에서 중앙 정렬 */
  height: 100%; /* 부모 요소의 전체 높이를 차지하도록 설정 */
  text-align: center; /* 텍스트를 중앙 정렬 */
  margin-top: 25vh;
}
.no_list{
  max-width: 100%; /* 이미지가 컨테이너 너비를 초과하지 않도록 */
  height: auto; /* 이미지의 비율을 유지하면서 너비에 맞춰 높이 조정 */
}
.no_list_text{
  color: var(--prime-font-inactive);
  margin-top: 20px; /* 이미지와 텍스트 사이의 간격 조정 */
}

</style>
