<template>
  <div v-if="isLoading" class="loading-div">
    <img class="loading_gif" src="@/assets/img/loading.gif" alt="로딩 중..." />
  </div>
  <div v-else class="page-container">
    <div style="width: 100%;height: 10px;background-color: #F6F6F6;margin-bottom: 10px;border-top: 1px solid #d9d9d9;">
    </div>
    <div style="padding:10px 20px 10px 20px;">
      <span class="active-font-18-b">{{addr1}}</span>
    </div>
    <div style="padding:0px 20px 10px 20px;">
      <span class="band">도로명</span> <span class="active-font-16">{{addr2}}</span>
    </div>
    <div style="padding:0px 20px 10px 20px;">
      <ui_inputtext placeholder="상세 주소 입력" v-model="addr3" />
    </div>
    <div class="flex-row">
      <div class="col-6" style="padding: 10px 5px 10px 20px">
        <div class="flex-col grow-center" @click="change_adr(0)" style="padding: 10px 0px 10px 0px" :class="{'active-btn': home_etc === 0, 'inactive-btn': home_etc !== 0}">
        <div>
          <ui_adr_home :is-active="home_etc === 0"/>
        </div>
        <div>기본배송지</div>
        </div>
      </div>
      <div class="col-6" style="padding: 10px 20px 10px 5px">
        <div class="flex-col grow-center" @click="change_adr(1)" style="padding: 10px 0px 10px 0px" :class="{'active-btn': home_etc === 1, 'inactive-btn': home_etc !== 1}">
          <div>
            <ui_adr_etc :is-active="home_etc === 1"/>
          </div>
          <div>기타</div>
        </div>
      </div>
    </div>
    <div v-if="home_etc === 1" style="padding:10px 20px 10px 20px;">
      <ui_inputtext type="text" placeholder="주소 별명 입력" v-model="etc_name"/>
    </div>
    <div class="flex-row">
      <div class="col-10" style="padding: 16px 20px 10px 20px;align-items: center;display: flex" @click="get_gps_local">
        <div style="display: inline-block" class="grow-center"><svg fill="#000000" width="24px" height="24px" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg" class="cf-icon-svg"><g id="SVGRepo_iconCarrier" transform="translate(0 2)"><path d="M15.084 15.2H.916a.264.264 0 0 1-.254-.42l2.36-4.492a.865.865 0 0 1 .696-.42h.827a9.51 9.51 0 0 0 .943 1.108H3.912l-1.637 3.116h11.45l-1.637-3.116h-1.34a9.481 9.481 0 0 0 .943-1.109h.591a.866.866 0 0 1 .696.421l2.36 4.492a.264.264 0 0 1-.254.42zM11.4 7.189c0 2.64-2.176 2.888-3.103 5.46a.182.182 0 0 1-.356 0c-.928-2.572-3.104-2.82-3.104-5.46a3.282 3.282 0 0 1 6.563 0zm-1.86-.005a1.425 1.425 0 1 0-1.425 1.425A1.425 1.425 0 0 0 9.54 7.184z"></path></g></svg></div>
        <span class="active-font-16" style="padding-left: 10px">지도에서 위치 확인</span>
      </div>
      <div class="col-2" style="padding: 18px 0px 10px 0px">
        <img src="@/assets/img/userview/next_arrow.svg" alt=">" class="arrow-icon" />
      </div>
    </div>

    <div style="width: 100%;height: 100%;background-color: #F6F6F6;border-top: 1px solid #d9d9d9;flex-grow:1;" class="v-margin">
    </div>
    <div style="position: fixed;bottom: 0;left:0;background-color: #fff;width: 100%">
      <div @click="save_adr" class="complite-btn grow-center">
        완료
      </div>
    </div>
  </div>
</template>



<script>



export default {
  data() {
    return {
      products: {},
      isLoading:true,
      idx:"",
      subject:"",
      addr1:"",
      addr2:"",
      addr3:"",
      home_etc:0,
      etc_name:""
    }
  },
  created() {

    if (this.$route.params.idx){
      this.idx = this.$route.params.idx;
      this.fetchProducts(this.idx);
    }else{
      this.idx = "";
      this.addr1 = this.$route.params.adr1;
      this.addr2 = this.$route.params.adr2;
      this.isLoading = false; // 요청이 끝나면 로딩 상태를 false로 설정
    }


    this.$store.dispatch('Showheaderfooter', {
      Title: '상세 주소 입력',
      showBack: true,
      showAlarm: false,
      showCart: false,
      showedit: false,
      showsheader: true,
      showFooter: false,
    });
  },

  methods: {
    get_gps_local(){
      this.$router.replace({
        name: 'adr_local',
      });
    },
    change_adr(n){
      this.home_etc = n;
    },
    save_adr(){
      let formData = new FormData();
      formData.append('mode', 'save_useradr');
      formData.append('martid', this.$store.state.martid);
      formData.append('token', this.$store.state.token);
      formData.append('idx', this.idx);
      if (this.home_etc == 1){
        if(this.etc_name != ""){
          this.subject = this.etc_name;
        }else{
          this.subject = "기타";
        }
      }else{
        this.subject = "기본배송지";
      }
      formData.append('subject', this.subject);
      formData.append('addr1', this.addr1);
      formData.append('addr2', this.addr3);
      this.$axios.post(this.$store.state.apiEndpoint, formData)
          .then(response => {
            this.$modal.successToast(response.data.message);
            this.$router.replace({
              name: 'adr_list'
            });
          })
          .catch(error => {
            this.$modal.errorToast(error);
          }).finally(() => {
        this.isLoading = false; // 요청이 끝나면 로딩 상태를 false로 설정
      });
    },
    fetchProducts() {
      let formData = new FormData();
      formData.append('mode', 'get_useradr_list');
      formData.append('martid', this.$store.state.martid);
      formData.append('token', this.$store.state.token);
      formData.append('idx', this.idx);
      this.$axios.post(this.$store.state.apiEndpoint, formData)
          .then(response => {
            this.products = response.data.data;
            this.addr1 = this.products.addr1;
            this.addr3 = this.products.addr2;
            this.subject = this.products.subject;
            if (this.subject == "기본배송지"){
              this.home_etc = 0;
            }else{
              this.home_etc = 1;
              this.etc_name = this.subject;
            }
          })
          .catch(error => {
            this.$modal.errorToast(error);
          }).finally(() => {
        this.isLoading = false; // 요청이 끝나면 로딩 상태를 false로 설정
      });
    },
  }
}
</script>

<style scoped>

.band{
  background-color: #ababab;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  padding: 4px 4px;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}


.page-container {
  height: calc(100vh - 60px);
  overflow-y:hidden;
}

.complite-btn{
  margin: 10px;
  height: 50px;
  border: 1px solid var(--prime-color);
  border-radius: 6px;
  background-color: var(--prime-color);
  font-size: 18px;
  font-family: 'Noto Sans CJK KR Bold';
  color: #fff;

}

.band-btn{
  background-color: #E9FBFB;
  border-radius: 0.2rem;
  color: var(--prime-color);
  display: inline-block;
  line-height: 1.1;
  padding: 8px 20px 8px 20px;
  font-size: 12px;
  font-family: 'Noto Sans CJK KR';
  margin-right: 20px;
}

</style>
